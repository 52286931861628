export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: string; output: string; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: number; output: number; }
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: string; output: string; }
  UUID: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AbsenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  status: AbsenceStatusInput;
  typeId: Scalars['Int']['input'];
};

export type AbsenceStatusInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  statusTypeId: Scalars['Int']['input'];
};

/** Represents the access to a specific record */
export type AccessDto = {
  __typename?: 'AccessDto';
  canRead: Scalars['Boolean']['output'];
  canWrite: Scalars['Boolean']['output'];
  /** ID of the access record, this can be modified to change someones access */
  id: Scalars['Int']['output'];
};

export type AccessOptions = {
  __typename?: 'AccessOptions';
  departments: Array<OrganisationDepartment>;
  employees: Array<Employee>;
  offices: Array<OrganisationOffice>;
  organisations: Array<Organisation>;
  teams: Array<OrganisationTeam>;
};

export type Account = {
  __typename?: 'Account';
  about?: Maybe<Scalars['String']['output']>;
  accountPrimaryContacts: Array<AccountPrimaryContact>;
  accountPrimaryEmployees: Array<AccountPrimaryEmployee>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  email?: Maybe<AxosEmail>;
  establishedDate?: Maybe<Scalars['Date']['output']>;
  homePageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  industryId?: Maybe<Scalars['Int']['output']>;
  invoiceMethodId?: Maybe<Scalars['Int']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  legalName: Scalars['String']['output'];
  legalNo: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  paymentTermId?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<AxosPhoneNumber>;
  revenue?: Maybe<Scalars['Int']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type AccountFilterInput = {
  about?: InputMaybe<StringOperationFilterInput>;
  accountAddresses?: InputMaybe<ListFilterInputTypeOfAddressFilterInput>;
  accountPrimaryContacts?: InputMaybe<ListFilterInputTypeOfAccountPrimaryContactFilterInput>;
  accountPrimaryEmployees?: InputMaybe<ListFilterInputTypeOfAccountPrimaryEmployeeFilterInput>;
  accountSocialHandles?: InputMaybe<ListFilterInputTypeOfAccountSocialHandleFilterInput>;
  accountTypes?: InputMaybe<ListFilterInputTypeOfAccountTypeFilterInput>;
  and?: InputMaybe<Array<AccountFilterInput>>;
  contacts?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<AxosEmailFilterInput>;
  establishedDate?: InputMaybe<DateOperationFilterInput>;
  homePageUrl?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  industry?: InputMaybe<IndustryFilterInput>;
  industryId?: InputMaybe<IntOperationFilterInput>;
  invoiceMethod?: InputMaybe<InvoiceMethodFilterInput>;
  invoiceMethodId?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  legalName?: InputMaybe<StringOperationFilterInput>;
  legalNo?: InputMaybe<StringOperationFilterInput>;
  logo?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  numberOfEmployees?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AccountFilterInput>>;
  parent?: InputMaybe<AccountFilterInput>;
  parentId?: InputMaybe<IntOperationFilterInput>;
  paymentTerm?: InputMaybe<PaymentTermFilterInput>;
  paymentTermId?: InputMaybe<IntOperationFilterInput>;
  phone?: InputMaybe<AxosPhoneNumberFilterInput>;
  revenue?: InputMaybe<IntOperationFilterInput>;
  subAccounts?: InputMaybe<ListFilterInputTypeOfAccountFilterInput>;
  symbol?: InputMaybe<StringOperationFilterInput>;
};

export type AccountPrimaryContact = {
  __typename?: 'AccountPrimaryContact';
  accountId: Scalars['Int']['output'];
  contactId: Scalars['Int']['output'];
  organisationId: Scalars['Int']['output'];
};

export type AccountPrimaryContactFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AccountPrimaryContactFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AccountPrimaryContactFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
};

export type AccountPrimaryEmployee = {
  __typename?: 'AccountPrimaryEmployee';
  accountId: Scalars['Int']['output'];
  employeeId: Scalars['Int']['output'];
  organisationId: Scalars['Int']['output'];
};

export type AccountPrimaryEmployeeFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AccountPrimaryEmployeeFilterInput>>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AccountPrimaryEmployeeFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
};

export type AccountSocialHandleFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AccountSocialHandleFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  handle?: InputMaybe<StringOperationFilterInput>;
  handleTypeId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AccountSocialHandleFilterInput>>;
  socialHandleType?: InputMaybe<SocialHandleTypeFilterInput>;
};

export type AccountSortInput = {
  about?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  email?: InputMaybe<AxosEmailSortInput>;
  establishedDate?: InputMaybe<SortEnumType>;
  homePageUrl?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  industry?: InputMaybe<IndustrySortInput>;
  industryId?: InputMaybe<SortEnumType>;
  invoiceMethod?: InputMaybe<InvoiceMethodSortInput>;
  invoiceMethodId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  legalName?: InputMaybe<SortEnumType>;
  legalNo?: InputMaybe<SortEnumType>;
  logo?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  numberOfEmployees?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<AccountSortInput>;
  parentId?: InputMaybe<SortEnumType>;
  paymentTerm?: InputMaybe<PaymentTermSortInput>;
  paymentTermId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<AxosPhoneNumberSortInput>;
  revenue?: InputMaybe<SortEnumType>;
  symbol?: InputMaybe<SortEnumType>;
};

export type AccountTypeFilterInput = {
  and?: InputMaybe<Array<AccountTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

/** A segment of a collection. */
export type AccountsCollectionSegment = {
  __typename?: 'AccountsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Account>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivateEmployeeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type ActivateEmployeeInput = {
  employmentInput: EmploymentInput;
  id: Scalars['Int']['input'];
};

export type ActivateEmployeePayload = {
  __typename?: 'ActivateEmployeePayload';
  errors?: Maybe<Array<ActivateEmployeeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type AddDepartmentAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddDepartmentAccessToEntityRowInput = {
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
  departmentId: Scalars['Int']['input'];
  entityId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
};

export type AddDepartmentAccessToEntityRowPayload = {
  __typename?: 'AddDepartmentAccessToEntityRowPayload';
  errors?: Maybe<Array<AddDepartmentAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type AddEmployeeAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddEmployeeAccessToEntityRowInput = {
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
  employeeId: Scalars['Int']['input'];
  entityId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
};

export type AddEmployeeAccessToEntityRowPayload = {
  __typename?: 'AddEmployeeAccessToEntityRowPayload';
  errors?: Maybe<Array<AddEmployeeAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type AddEmployeeToBenefitError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddEmployeeToBenefitInput = {
  benefitId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
};

export type AddEmployeeToBenefitPayload = {
  __typename?: 'AddEmployeeToBenefitPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<AddEmployeeToBenefitError>>;
};

export type AddEmployeeToInsuranceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddEmployeeToInsuranceInput = {
  employeeId: Scalars['Int']['input'];
  insuranceId: Scalars['Int']['input'];
};

export type AddEmployeeToInsurancePayload = {
  __typename?: 'AddEmployeeToInsurancePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<AddEmployeeToInsuranceError>>;
};

export type AddEmployeeToOfficeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddEmployeeToOfficeInput = {
  employeeId: Scalars['Int']['input'];
  officeId: Scalars['Int']['input'];
};

export type AddEmployeeToOfficePayload = {
  __typename?: 'AddEmployeeToOfficePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<AddEmployeeToOfficeError>>;
};

export type AddEmployeeToTeamError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddEmployeeToTeamInput = {
  employeeId: Scalars['Int']['input'];
  teamId: Scalars['Int']['input'];
};

export type AddEmployeeToTeamPayload = {
  __typename?: 'AddEmployeeToTeamPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<AddEmployeeToTeamError>>;
};

export type AddOfficeAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddOfficeAccessToEntityRowInput = {
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
  entityId: Scalars['Int']['input'];
  officeId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
};

export type AddOfficeAccessToEntityRowPayload = {
  __typename?: 'AddOfficeAccessToEntityRowPayload';
  errors?: Maybe<Array<AddOfficeAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type AddOrganisationAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddOrganisationAccessToEntityRowInput = {
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
  entityId: Scalars['Int']['input'];
  organisationId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
};

export type AddOrganisationAccessToEntityRowPayload = {
  __typename?: 'AddOrganisationAccessToEntityRowPayload';
  errors?: Maybe<Array<AddOrganisationAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type AddTeamAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type AddTeamAccessToEntityRowInput = {
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
  entityId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
  teamId: Scalars['Int']['input'];
};

export type AddTeamAccessToEntityRowPayload = {
  __typename?: 'AddTeamAccessToEntityRowPayload';
  errors?: Maybe<Array<AddTeamAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type Address = {
  __typename?: 'Address';
  accountId?: Maybe<Scalars['Int']['output']>;
  addressType: AddressType;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['Int']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** @deprecated Deprecated, use Location instead */
  latitude?: Maybe<Scalars['Float']['output']>;
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated, use Location instead */
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organisationAddresses: Array<OrganisationAddress>;
  seededZipCode?: Maybe<ZipCodes>;
  typeId: Scalars['Int']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
  zipCodeId?: Maybe<Scalars['String']['output']>;
};

export type AddressFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<AddressFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  latitude?: InputMaybe<FloatOperationFilterInput>;
  line1?: InputMaybe<StringOperationFilterInput>;
  line2?: InputMaybe<StringOperationFilterInput>;
  longitude?: InputMaybe<FloatOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  organisationAddresses?: InputMaybe<ListFilterInputTypeOfOrganisationAddressFilterInput>;
  seededZipCode?: InputMaybe<ZipCodesFilterInput>;
  type?: InputMaybe<AddressTypeFilterInput>;
  typeId?: InputMaybe<IntOperationFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
  zipCodeId?: InputMaybe<StringOperationFilterInput>;
};

export type AddressInput = {
  addressTypeId: Scalars['Int']['input'];
  /** Set city freetext */
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  /** If Name is left empty it is set to the address type */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Set zipcode freetext */
  zipCode?: InputMaybe<Scalars['String']['input']>;
  /** Set zipcode from ID */
  zipCodeId?: InputMaybe<Scalars['String']['input']>;
};

export type AddressSortInput = {
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<CountrySortInput>;
  countryId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  line1?: InputMaybe<SortEnumType>;
  line2?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  seededZipCode?: InputMaybe<ZipCodesSortInput>;
  type?: InputMaybe<AddressTypeSortInput>;
  typeId?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
  zipCodeId?: InputMaybe<SortEnumType>;
};

export type AddressType = {
  __typename?: 'AddressType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type AddressTypeFilterInput = {
  addresses?: InputMaybe<ListFilterInputTypeOfAddressFilterInput>;
  and?: InputMaybe<Array<AddressTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type AddressTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AddressesCollectionSegment = {
  __typename?: 'AddressesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Address>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AdministrationFeeCalcRuleDropdown = {
  __typename?: 'AdministrationFeeCalcRuleDropdown';
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label: Scalars['String']['output'];
  /** Value that field should be bound to, Calc Rule Enum */
  value: AdministrationFeeCalculationRule;
};

export enum AdministrationFeeCalculationRule {
  No = 'NO',
  OverThreshold = 'OVER_THRESHOLD',
  Yes = 'YES'
}

export type AdministrationFeeCalculationRuleOperationFilterInput = {
  eq?: InputMaybe<AdministrationFeeCalculationRule>;
  in?: InputMaybe<Array<AdministrationFeeCalculationRule>>;
  neq?: InputMaybe<AdministrationFeeCalculationRule>;
  nin?: InputMaybe<Array<AdministrationFeeCalculationRule>>;
};

/** A segment of a collection. */
export type AllergiesCollectionSegment = {
  __typename?: 'AllergiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EmployeeAllergy>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AllergyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Int']['input'];
};

export type AllergyType = {
  __typename?: 'AllergyType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type AllergyTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type ApplyAbsenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  typeId: Scalars['Int']['input'];
};

export type ApplyForEmployeeAbsenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type ApplyForEmployeeAbsenceInput = {
  input: ApplyAbsenceInput;
};

export type ApplyForEmployeeAbsencePayload = {
  __typename?: 'ApplyForEmployeeAbsencePayload';
  errors?: Maybe<Array<ApplyForEmployeeAbsenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ApproveAllEmployeeAbsenceBalancesError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type ApproveAllEmployeeAbsenceBalancesInput = {
  departmentId?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type ApproveAllEmployeeAbsenceBalancesPayload = {
  __typename?: 'ApproveAllEmployeeAbsenceBalancesPayload';
  errors?: Maybe<Array<ApproveAllEmployeeAbsenceBalancesError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type AuthClientDto = {
  __typename?: 'AuthClientDto';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum AxosCapabilities {
  SeeAllergiesReport = 'SEE_ALLERGIES_REPORT'
}

export type AxosClient = {
  __typename?: 'AxosClient';
  axosAccountId: Scalars['Int']['output'];
  axosContactId: Scalars['Int']['output'];
  clientDatabase: Scalars['String']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subDomain: Scalars['String']['output'];
  users: Array<AxosClientUser>;
};

export type AxosClientUser = {
  __typename?: 'AxosClientUser';
  clientId: Scalars['Int']['output'];
  userId: Scalars['UUID']['output'];
};

export type AxosEmail = {
  __typename?: 'AxosEmail';
  address: Scalars['String']['output'];
};

export type AxosEmailFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AxosEmailFilterInput>>;
  or?: InputMaybe<Array<AxosEmailFilterInput>>;
};

export type AxosEmailSortInput = {
  address?: InputMaybe<SortEnumType>;
};

export enum AxosEntities {
  AccessControl = 'ACCESS_CONTROL',
  Account = 'ACCOUNT',
  AccountMeeting = 'ACCOUNT_MEETING',
  AccountMeetingTalkingPoint = 'ACCOUNT_MEETING_TALKING_POINT',
  AccountType = 'ACCOUNT_TYPE',
  Address = 'ADDRESS',
  AddressType = 'ADDRESS_TYPE',
  AllergyType = 'ALLERGY_TYPE',
  ApprovalStatusType = 'APPROVAL_STATUS_TYPE',
  ApprovalType = 'APPROVAL_TYPE',
  Benefit = 'BENEFIT',
  Capability = 'CAPABILITY',
  CertificateType = 'CERTIFICATE_TYPE',
  CompetenceLevel = 'COMPETENCE_LEVEL',
  CompetenceType = 'COMPETENCE_TYPE',
  Contact = 'CONTACT',
  ContactSocialHandle = 'CONTACT_SOCIAL_HANDLE',
  Core = 'CORE',
  Country = 'COUNTRY',
  CustomField = 'CUSTOM_FIELD',
  DeviceType = 'DEVICE_TYPE',
  EducationType = 'EDUCATION_TYPE',
  Email = 'EMAIL',
  Employee = 'EMPLOYEE',
  EmployeeAbsence = 'EMPLOYEE_ABSENCE',
  EmployeeAbsenceBalance = 'EMPLOYEE_ABSENCE_BALANCE',
  EmployeeAbsenceStatusType = 'EMPLOYEE_ABSENCE_STATUS_TYPE',
  EmployeeAbsenceType = 'EMPLOYEE_ABSENCE_TYPE',
  EmployeeAllergy = 'EMPLOYEE_ALLERGY',
  EmployeeCertificate = 'EMPLOYEE_CERTIFICATE',
  EmployeeCompetence = 'EMPLOYEE_COMPETENCE',
  EmployeeDevice = 'EMPLOYEE_DEVICE',
  EmployeeEducation = 'EMPLOYEE_EDUCATION',
  EmployeeEmployment = 'EMPLOYEE_EMPLOYMENT',
  EmployeeFamily = 'EMPLOYEE_FAMILY',
  EmployeeMeeting = 'EMPLOYEE_MEETING',
  EmployeeMeetingTalkingPoint = 'EMPLOYEE_MEETING_TALKING_POINT',
  EmployeeRequest = 'EMPLOYEE_REQUEST',
  EmployeeWageType = 'EMPLOYEE_WAGE_TYPE',
  EmploymentType = 'EMPLOYMENT_TYPE',
  EntityGrid = 'ENTITY_GRID',
  ExternalLink = 'EXTERNAL_LINK',
  FamilyRelationType = 'FAMILY_RELATION_TYPE',
  Gender = 'GENDER',
  Idea = 'IDEA',
  Industry = 'INDUSTRY',
  Insurance = 'INSURANCE',
  InvoiceMethodType = 'INVOICE_METHOD_TYPE',
  Job = 'JOB',
  JobCandidate = 'JOB_CANDIDATE',
  JobCandidateStageType = 'JOB_CANDIDATE_STAGE_TYPE',
  Language = 'LANGUAGE',
  Lead = 'LEAD',
  MaritalStatusType = 'MARITAL_STATUS_TYPE',
  Master = 'MASTER',
  MeetingTalkingPointType = 'MEETING_TALKING_POINT_TYPE',
  Module = 'MODULE',
  OkrKeyResultType = 'OKR_KEY_RESULT_TYPE',
  OkrObjective = 'OKR_OBJECTIVE',
  OkrObjectiveType = 'OKR_OBJECTIVE_TYPE',
  OkrStatusType = 'OKR_STATUS_TYPE',
  Organisation = 'ORGANISATION',
  OrganisationDepartment = 'ORGANISATION_DEPARTMENT',
  OrganisationOffice = 'ORGANISATION_OFFICE',
  OrganisationTeam = 'ORGANISATION_TEAM',
  PaymentTerm = 'PAYMENT_TERM',
  PhoneCall = 'PHONE_CALL',
  Position = 'POSITION',
  PowerGrid = 'POWER_GRID',
  Product = 'PRODUCT',
  Role = 'ROLE',
  Selection = 'SELECTION',
  SmsMessage = 'SMS_MESSAGE',
  SocialHandleType = 'SOCIAL_HANDLE_TYPE',
  SurveyQuestionType = 'SURVEY_QUESTION_TYPE',
  ToDoRecurringType = 'TO_DO_RECURRING_TYPE',
  ToDoStatusType = 'TO_DO_STATUS_TYPE',
  WorkflowStatusType = 'WORKFLOW_STATUS_TYPE',
  WorkflowStepStatusType = 'WORKFLOW_STEP_STATUS_TYPE',
  WorkflowTriggerType = 'WORKFLOW_TRIGGER_TYPE'
}

export interface AxosError {
  /** The error code. Used by the client to determine which error message to display. */
  code: Scalars['String']['output'];
  extensions: Array<KeyValuePairOfStringAndObject>;
  /** System description that should be logged */
  message?: Maybe<Scalars['String']['output']>;
  /** Which field of the entity caused the error. Used by the client to determine which field to highlight. */
  path: Array<Scalars['String']['output']>;
  /** Resource ID that the client will use to display an error message. */
  resourceId: Scalars['String']['output'];
}

export enum AxosModules {
  Account = 'ACCOUNT',
  AccountMeeting = 'ACCOUNT_MEETING',
  Ai = 'AI',
  Benefit = 'BENEFIT',
  CampaignMonitor = 'CAMPAIGN_MONITOR',
  Certificate = 'CERTIFICATE',
  Competence = 'COMPETENCE',
  Contact = 'CONTACT',
  Core = 'CORE',
  Crm = 'CRM',
  Device = 'DEVICE',
  Education = 'EDUCATION',
  Employee = 'EMPLOYEE',
  EmployeeAbsence = 'EMPLOYEE_ABSENCE',
  EmployeeAllergy = 'EMPLOYEE_ALLERGY',
  EmployeeFamily = 'EMPLOYEE_FAMILY',
  EmployeeMeeting = 'EMPLOYEE_MEETING',
  EmployeeRequest = 'EMPLOYEE_REQUEST',
  Hr = 'HR',
  Insurance = 'INSURANCE',
  OkrObjective = 'OKR_OBJECTIVE',
  Organisation = 'ORGANISATION',
  Sms = 'SMS',
  VacationCalendar = 'VACATION_CALENDAR'
}

export type AxosPhoneNumber = {
  __typename?: 'AxosPhoneNumber';
  country: Country;
  formattedNumber: Scalars['String']['output'];
  formattedNumberNational: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type AxosPhoneNumberFilterInput = {
  and?: InputMaybe<Array<AxosPhoneNumberFilterInput>>;
  country?: InputMaybe<CountryFilterInput>;
  formattedNumber?: InputMaybe<StringOperationFilterInput>;
  formattedNumberNational?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AxosPhoneNumberFilterInput>>;
};

export type AxosPhoneNumberSortInput = {
  country?: InputMaybe<CountrySortInput>;
  formattedNumber?: InputMaybe<SortEnumType>;
  formattedNumberNational?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrregCompanyAddress = {
  __typename?: 'BrregCompanyAddress';
  city?: Maybe<Scalars['String']['output']>;
  council?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type BrregCompanyDto = {
  __typename?: 'BrregCompanyDto';
  address: BrregCompanyAddress;
  legalName: Scalars['String']['output'];
  legalNo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type Capability = {
  __typename?: 'Capability';
  id: Scalars['Int']['output'];
  module?: Maybe<Module>;
  moduleId?: Maybe<Scalars['Int']['output']>;
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type CertificateInput = {
  certifiedDate?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  doNotify: Scalars['Boolean']['input'];
  expires?: InputMaybe<Scalars['Date']['input']>;
  notificationDays?: InputMaybe<Scalars['Int']['input']>;
  typeId: Scalars['Int']['input'];
};

export type CertificateType = {
  __typename?: 'CertificateType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type CertificateTypeFilterInput = {
  and?: InputMaybe<Array<CertificateTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CertificateTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type CertificateTypeInput = {
  name: Scalars['String']['input'];
};

export type CertificateTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CertificateTypesCollectionSegment = {
  __typename?: 'CertificateTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CertificateType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChecklistFilterInput = {
  and?: InputMaybe<Array<ChecklistFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  instanceId?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isDone?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ChecklistFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  template?: InputMaybe<TemplateFilterInput>;
  templateId?: InputMaybe<IntOperationFilterInput>;
};

export type ClearAccessCacheError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type ClearAccessCachePayload = {
  __typename?: 'ClearAccessCachePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<ClearAccessCacheError>>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type CompetenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['Int']['input']>;
  typeId: Scalars['Int']['input'];
};

export type CompetenceLevel = {
  __typename?: 'CompetenceLevel';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type CompetenceType = {
  __typename?: 'CompetenceType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  employees: Array<Employee>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};


export type CompetenceTypeEmployeesArgs = {
  organisationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompetenceTypeInput = {
  name: Scalars['String']['input'];
};

export type Contact = {
  __typename?: 'Contact';
  about?: Maybe<Scalars['String']['output']>;
  account: Account;
  accountId: Scalars['Int']['output'];
  accountPrimaryContacts: Array<AccountPrimaryContact>;
  age?: Maybe<Scalars['Int']['output']>;
  birthDate?: Maybe<Scalars['Date']['output']>;
  cellular?: Maybe<AxosPhoneNumber>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  email?: Maybe<AxosEmail>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  genderId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars['Int']['output']>;
  lastName: Scalars['String']['output'];
  number: Scalars['String']['output'];
  phone?: Maybe<AxosPhoneNumber>;
  positionId?: Maybe<Scalars['Int']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  syncEmployeeId?: Maybe<Scalars['Int']['output']>;
};

export type ContactFilterInput = {
  about?: InputMaybe<StringOperationFilterInput>;
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  accountPrimaryContacts?: InputMaybe<ListFilterInputTypeOfAccountPrimaryContactFilterInput>;
  age?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ContactFilterInput>>;
  birthDate?: InputMaybe<DateOperationFilterInput>;
  cellular?: InputMaybe<AxosPhoneNumberFilterInput>;
  contactSocialHandles?: InputMaybe<ListFilterInputTypeOfContactSocialHandleFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<AxosEmailFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  gender?: InputMaybe<GenderFilterInput>;
  genderId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  language?: InputMaybe<LanguageFilterInput>;
  languageId?: InputMaybe<IntOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  phone?: InputMaybe<AxosPhoneNumberFilterInput>;
  position?: InputMaybe<PositionFilterInput>;
  positionId?: InputMaybe<IntOperationFilterInput>;
  profilePicture?: InputMaybe<StringOperationFilterInput>;
  syncEmployee?: InputMaybe<EmployeeFilterInput>;
  syncEmployeeId?: InputMaybe<IntOperationFilterInput>;
};

export type ContactSocialHandleFilterInput = {
  and?: InputMaybe<Array<ContactSocialHandleFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  handle?: InputMaybe<StringOperationFilterInput>;
  handleTypeId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ContactSocialHandleFilterInput>>;
  socialHandleType?: InputMaybe<SocialHandleTypeFilterInput>;
};

export type ContactSortInput = {
  about?: InputMaybe<SortEnumType>;
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  age?: InputMaybe<SortEnumType>;
  birthDate?: InputMaybe<SortEnumType>;
  cellular?: InputMaybe<AxosPhoneNumberSortInput>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  email?: InputMaybe<AxosEmailSortInput>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  gender?: InputMaybe<GenderSortInput>;
  genderId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  language?: InputMaybe<LanguageSortInput>;
  languageId?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<AxosPhoneNumberSortInput>;
  position?: InputMaybe<PositionSortInput>;
  positionId?: InputMaybe<SortEnumType>;
  profilePicture?: InputMaybe<SortEnumType>;
  syncEmployee?: InputMaybe<EmployeeSortInput>;
  syncEmployeeId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ContactsCollectionSegment = {
  __typename?: 'ContactsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Council = {
  __typename?: 'Council';
  county: County;
  countyId: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type CouncilFilterInput = {
  and?: InputMaybe<Array<CouncilFilterInput>>;
  county?: InputMaybe<CountyFilterInput>;
  countyId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CouncilFilterInput>>;
  zipCodes?: InputMaybe<ListFilterInputTypeOfZipCodesFilterInput>;
};

export type CouncilSortInput = {
  county?: InputMaybe<CountySortInput>;
  countyId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CountriesCollectionSegment = {
  __typename?: 'CountriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Country>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Country = {
  __typename?: 'Country';
  a2?: Maybe<Scalars['String']['output']>;
  a3?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  flag: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phonePrefix?: Maybe<Scalars['String']['output']>;
  tld?: Maybe<Scalars['String']['output']>;
};

export type CountryDropdownDto = {
  __typename?: 'CountryDropdownDto';
  countryCode?: Maybe<Scalars['String']['output']>;
  flag: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label: Scalars['String']['output'];
  phonePrefix?: Maybe<Scalars['String']['output']>;
  /** SortIndex to sort the dropdowns by. If not provided, the dropdowns will be sorted by Label */
  sortIndex?: Maybe<Scalars['Int']['output']>;
  /** Value that field should be bound to, usually an ID */
  value: Scalars['Int']['output'];
};

export type CountryFilterInput = {
  a2?: InputMaybe<StringOperationFilterInput>;
  a3?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CountryFilterInput>>;
  counties?: InputMaybe<ListFilterInputTypeOfCountyFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  flag?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CountryFilterInput>>;
  phonePrefix?: InputMaybe<StringOperationFilterInput>;
  tld?: InputMaybe<StringOperationFilterInput>;
};

export type CountrySortInput = {
  a2?: InputMaybe<SortEnumType>;
  a3?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  flag?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phonePrefix?: InputMaybe<SortEnumType>;
  tld?: InputMaybe<SortEnumType>;
};

export type County = {
  __typename?: 'County';
  country: Country;
  countryId: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type CountyFilterInput = {
  and?: InputMaybe<Array<CountyFilterInput>>;
  councils?: InputMaybe<ListFilterInputTypeOfCouncilFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CountyFilterInput>>;
};

export type CountySortInput = {
  country?: InputMaybe<CountrySortInput>;
  countryId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type CreateAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateAddressInput = {
  addressInput: AddressInput;
};

export type CreateAddressPayload = {
  __typename?: 'CreateAddressPayload';
  errors?: Maybe<Array<CreateAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateBenefitError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateBenefitFormInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
};

export type CreateBenefitInput = {
  input: CreateBenefitFormInput;
};

export type CreateBenefitPayload = {
  __typename?: 'CreateBenefitPayload';
  errors?: Maybe<Array<CreateBenefitError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateCertificateTypeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateCertificateTypeInput = {
  input: CertificateTypeInput;
};

export type CreateCertificateTypePayload = {
  __typename?: 'CreateCertificateTypePayload';
  errors?: Maybe<Array<CreateCertificateTypeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateClientError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateClientInput = {
  databaseName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  legalName: Scalars['String']['input'];
  legalNo: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<CreateClientError>>;
};

export type CreateCompetenceTypeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateCompetenceTypeInput = {
  input: CompetenceTypeInput;
};

export type CreateCompetenceTypePayload = {
  __typename?: 'CreateCompetenceTypePayload';
  errors?: Maybe<Array<CreateCompetenceTypeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateCustomFieldForEntityError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateCustomFieldForEntityInput = {
  entity: AxosEntities;
  fieldInput: CustomFieldInput;
};

export type CreateCustomFieldForEntityPayload = {
  __typename?: 'CreateCustomFieldForEntityPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<CreateCustomFieldForEntityError>>;
};

export type CreateDepartmentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateDepartmentInput = {
  input: DepartmentInput;
  organisationId: Scalars['Int']['input'];
};

export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  errors?: Maybe<Array<CreateDepartmentError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeAbsenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeAbsenceInput = {
  employeeId: Scalars['Int']['input'];
  input: AbsenceInput;
};

export type CreateEmployeeAbsencePayload = {
  __typename?: 'CreateEmployeeAbsencePayload';
  errors?: Maybe<Array<CreateEmployeeAbsenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeAddressInput = {
  addressInput: AddressInput;
  employeeId: Scalars['Int']['input'];
};

export type CreateEmployeeAddressPayload = {
  __typename?: 'CreateEmployeeAddressPayload';
  errors?: Maybe<Array<CreateEmployeeAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeAllergyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeAllergyInput = {
  employeeId: Scalars['Int']['input'];
  input: AllergyInput;
};

export type CreateEmployeeAllergyPayload = {
  __typename?: 'CreateEmployeeAllergyPayload';
  errors?: Maybe<Array<CreateEmployeeAllergyError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeCertificateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeCertificateInput = {
  employeeId: Scalars['Int']['input'];
  input: CertificateInput;
};

export type CreateEmployeeCertificatePayload = {
  __typename?: 'CreateEmployeeCertificatePayload';
  errors?: Maybe<Array<CreateEmployeeCertificateError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeCompetenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeCompetenceInput = {
  employeeId: Scalars['Int']['input'];
  input: CompetenceInput;
};

export type CreateEmployeeCompetencePayload = {
  __typename?: 'CreateEmployeeCompetencePayload';
  errors?: Maybe<Array<CreateEmployeeCompetenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeDeviceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeDeviceInput = {
  employeeId: Scalars['Int']['input'];
  input: DeviceInput;
};

export type CreateEmployeeDevicePayload = {
  __typename?: 'CreateEmployeeDevicePayload';
  errors?: Maybe<Array<CreateEmployeeDeviceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeEducationError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeEducationInput = {
  employeeId: Scalars['Int']['input'];
  input: EducationInput;
};

export type CreateEmployeeEducationPayload = {
  __typename?: 'CreateEmployeeEducationPayload';
  errors?: Maybe<Array<CreateEmployeeEducationError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeEmploymentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeEmploymentInput = {
  employeeId: Scalars['Int']['input'];
  input: EmploymentInput;
};

export type CreateEmployeeEmploymentPayload = {
  __typename?: 'CreateEmployeeEmploymentPayload';
  errors?: Maybe<Array<CreateEmployeeEmploymentError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeFamilyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateEmployeeFamilyInput = {
  employeeId: Scalars['Int']['input'];
  input: FamilyInput;
};

export type CreateEmployeeFamilyPayload = {
  __typename?: 'CreateEmployeeFamilyPayload';
  errors?: Maybe<Array<CreateEmployeeFamilyError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateEmployeeInput = {
  addressInput?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  cellular?: InputMaybe<PhoneNumberInput>;
  defaultProductId?: InputMaybe<Scalars['Int']['input']>;
  departmentId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  employment: EmploymentInput;
  externalErpSystemID?: InputMaybe<Scalars['String']['input']>;
  externalSalarySystemID?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  genderId: Scalars['Int']['input'];
  initials: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  managerId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<PhoneNumberInput>;
  profilePictureId?: InputMaybe<Scalars['Int']['input']>;
  trialMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateEmployeePayload = {
  __typename?: 'CreateEmployeePayload';
  errors?: Maybe<Array<CreateEmployeeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateIdeaCommentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateIdeaCommentInput = {
  ideaId: Scalars['Int']['input'];
  input: IdeaCommentInput;
};

export type CreateIdeaCommentPayload = {
  __typename?: 'CreateIdeaCommentPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<CreateIdeaCommentError>>;
};

export type CreateIdeaError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateIdeaInput = {
  input: IdeaInput;
};

export type CreateIdeaPayload = {
  __typename?: 'CreateIdeaPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<CreateIdeaError>>;
};

export type CreateInsuranceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateInsuranceFormInput = {
  contactId: Scalars['Int']['input'];
  coverage?: InputMaybe<Scalars['String']['input']>;
  deductibleAmount?: InputMaybe<Scalars['Decimal']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  helpline?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
  policyEndDate?: InputMaybe<Scalars['Date']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  policyStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateInsuranceInput = {
  input: CreateInsuranceFormInput;
};

export type CreateInsurancePayload = {
  __typename?: 'CreateInsurancePayload';
  errors?: Maybe<Array<CreateInsuranceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateMasterModuleError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateMasterModuleInput = {
  moduleInput: ModuleInput;
};

export type CreateMasterModulePayload = {
  __typename?: 'CreateMasterModulePayload';
  errors?: Maybe<Array<CreateMasterModuleError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateOfficeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateOfficeInput = {
  input: OfficeInput;
  organisationId: Scalars['Int']['input'];
};

export type CreateOfficePayload = {
  __typename?: 'CreateOfficePayload';
  errors?: Maybe<Array<CreateOfficeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateOkrObjectiveError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateOkrObjectiveInput = {
  employeeId: Scalars['Int']['input'];
  input: OkrObjectiveInput;
};

export type CreateOkrObjectivePayload = {
  __typename?: 'CreateOkrObjectivePayload';
  errors?: Maybe<Array<CreateOkrObjectiveError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateOrganisationAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateOrganisationAddressInput = {
  addressInput: AddressInput;
  organisationId: Scalars['Int']['input'];
};

export type CreateOrganisationAddressPayload = {
  __typename?: 'CreateOrganisationAddressPayload';
  errors?: Maybe<Array<CreateOrganisationAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateOrganisationError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateOrganisationInput = {
  addressInput: AddressInput;
  email: Scalars['String']['input'];
  languageId: Scalars['Int']['input'];
  legalName: Scalars['String']['input'];
  legalNo: Scalars['String']['input'];
  managerId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  payTermId: Scalars['Int']['input'];
  phone: PhoneNumberInput;
};

export type CreateOrganisationPayload = {
  __typename?: 'CreateOrganisationPayload';
  errors?: Maybe<Array<CreateOrganisationError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreatePositionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreatePositionInput = {
  defaultJobDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreatePositionPayload = {
  __typename?: 'CreatePositionPayload';
  errors?: Maybe<Array<CreatePositionError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreatePowerGridError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreatePowerGridInput = {
  entityId: Scalars['Int']['input'];
  powerGridInput: PowerGridInput;
};

export type CreatePowerGridPayload = {
  __typename?: 'CreatePowerGridPayload';
  errors?: Maybe<Array<CreatePowerGridError>>;
  powerGridDto?: Maybe<PowerGridDto>;
};

export type CreateProductError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateProductInput = {
  productInput: ProductInput;
};

export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  errors?: Maybe<Array<CreateProductError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateResponsibilityError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateResponsibilityInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
};

export type CreateResponsibilityPayload = {
  __typename?: 'CreateResponsibilityPayload';
  errors?: Maybe<Array<CreateResponsibilityError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateResponsibilityTaskError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateResponsibilityTaskInput = {
  responsibilityTaskInput: ResponsibilityTaskInput;
};

export type CreateResponsibilityTaskPayload = {
  __typename?: 'CreateResponsibilityTaskPayload';
  errors?: Maybe<Array<CreateResponsibilityTaskError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateTeamError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type CreateTeamInput = {
  input: TeamInput;
  organisationId: Scalars['Int']['input'];
};

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  errors?: Maybe<Array<CreateTeamError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type CreateTodoInput = {
  formInput: TodoFormInput;
};

export type CreateTodoPayload = {
  __typename?: 'CreateTodoPayload';
  int?: Maybe<Scalars['Int']['output']>;
};

export type CustomFieldBooleanFilter = {
  __typename?: 'CustomFieldBooleanFilter';
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomFieldBooleanFilterDto = {
  __typename?: 'CustomFieldBooleanFilterDto';
  createdOn: Scalars['DateTime']['output'];
  customFieldId: Scalars['Int']['output'];
  filter: CustomFieldBooleanFilter;
  filterType: CustomFieldDataType;
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type CustomFieldBooleanFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldBooleanFilterFilterInput>>;
  or?: InputMaybe<Array<CustomFieldBooleanFilterFilterInput>>;
  value?: InputMaybe<BooleanOperationFilterInput>;
};

export type CustomFieldBooleanFilterInput = {
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldBooleanGridFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldBooleanGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  customField?: InputMaybe<EntityCustomFieldFilterInput>;
  customFieldId?: InputMaybe<IntOperationFilterInput>;
  filter?: InputMaybe<CustomFieldBooleanFilterFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CustomFieldBooleanGridFilterFilterInput>>;
};

export type CustomFieldBooleanGridFilterInput = {
  customFieldId: Scalars['Int']['input'];
  filter: CustomFieldBooleanFilterInput;
};

export enum CustomFieldDataType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Link = 'LINK',
  Number = 'NUMBER',
  Relation = 'RELATION',
  String = 'STRING'
}

export type CustomFieldDataTypeOperationFilterInput = {
  eq?: InputMaybe<CustomFieldDataType>;
  in?: InputMaybe<Array<CustomFieldDataType>>;
  neq?: InputMaybe<CustomFieldDataType>;
  nin?: InputMaybe<Array<CustomFieldDataType>>;
};

export type CustomFieldDateFilter = {
  __typename?: 'CustomFieldDateFilter';
  fromDate?: Maybe<Scalars['DateTime']['output']>;
  toDate?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomFieldDateFilterDto = {
  __typename?: 'CustomFieldDateFilterDto';
  createdOn: Scalars['DateTime']['output'];
  customFieldId: Scalars['Int']['output'];
  filter: CustomFieldDateFilter;
  filterType: CustomFieldDataType;
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type CustomFieldDateFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldDateFilterFilterInput>>;
  fromDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomFieldDateFilterFilterInput>>;
  toDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CustomFieldDateFilterInput = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomFieldDateGridFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldDateGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  customField?: InputMaybe<EntityCustomFieldFilterInput>;
  customFieldId?: InputMaybe<IntOperationFilterInput>;
  filter?: InputMaybe<CustomFieldDateFilterFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CustomFieldDateGridFilterFilterInput>>;
};

export type CustomFieldDateGridFilterInput = {
  customFieldId: Scalars['Int']['input'];
  filter: CustomFieldDateFilterInput;
};

export type CustomFieldFilterConfigDto = {
  __typename?: 'CustomFieldFilterConfigDto';
  customField: EntityCustomField;
  customFieldId: Scalars['Int']['output'];
  filterType: CustomFieldDataType;
};

export type CustomFieldInput = {
  labelResourceId: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
  typeMeta?: InputMaybe<Scalars['String']['input']>;
};

export type CustomFieldNumberFilter = {
  __typename?: 'CustomFieldNumberFilter';
  operator: OperatorEnum;
  value?: Maybe<Scalars['Decimal']['output']>;
};

export type CustomFieldNumberFilterDto = {
  __typename?: 'CustomFieldNumberFilterDto';
  createdOn: Scalars['DateTime']['output'];
  customFieldId: Scalars['Int']['output'];
  filter: CustomFieldNumberFilter;
  filterType: CustomFieldDataType;
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type CustomFieldNumberFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldNumberFilterFilterInput>>;
  operator?: InputMaybe<OperatorEnumOperationFilterInput>;
  or?: InputMaybe<Array<CustomFieldNumberFilterFilterInput>>;
  value?: InputMaybe<DecimalOperationFilterInput>;
};

export type CustomFieldNumberFilterInput = {
  operator: OperatorEnum;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CustomFieldNumberGridFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldNumberGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  customField?: InputMaybe<EntityCustomFieldFilterInput>;
  customFieldId?: InputMaybe<IntOperationFilterInput>;
  filter?: InputMaybe<CustomFieldNumberFilterFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CustomFieldNumberGridFilterFilterInput>>;
};

export type CustomFieldNumberGridFilterInput = {
  customFieldId: Scalars['Int']['input'];
  filter: CustomFieldNumberFilterInput;
};

export type CustomFieldSortArgumentInput = {
  customFieldId: Scalars['Int']['input'];
  sortDirection: SortDirection;
};

export type CustomFieldStringFilter = {
  __typename?: 'CustomFieldStringFilter';
  value?: Maybe<Scalars['String']['output']>;
};

export type CustomFieldStringFilterDto = {
  __typename?: 'CustomFieldStringFilterDto';
  createdOn: Scalars['DateTime']['output'];
  customFieldId: Scalars['Int']['output'];
  filter: CustomFieldStringFilter;
  filterType: CustomFieldDataType;
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type CustomFieldStringFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldStringFilterFilterInput>>;
  or?: InputMaybe<Array<CustomFieldStringFilterFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type CustomFieldStringFilterInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CustomFieldStringGridFilterFilterInput = {
  and?: InputMaybe<Array<CustomFieldStringGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  customField?: InputMaybe<EntityCustomFieldFilterInput>;
  customFieldId?: InputMaybe<IntOperationFilterInput>;
  filter?: InputMaybe<CustomFieldStringFilterFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CustomFieldStringGridFilterFilterInput>>;
};

export type CustomFieldStringGridFilterInput = {
  customFieldId: Scalars['Int']['input'];
  filter: CustomFieldStringFilterInput;
};

export enum DataType {
  Date = 'DATE',
  DateOnly = 'DATE_ONLY',
  Image = 'IMAGE',
  Number = 'NUMBER',
  String = 'STRING',
  TimeOnly = 'TIME_ONLY'
}

export type DataTypeOperationFilterInput = {
  eq?: InputMaybe<DataType>;
  in?: InputMaybe<Array<DataType>>;
  neq?: InputMaybe<DataType>;
  nin?: InputMaybe<Array<DataType>>;
};

export type DateFilter = {
  __typename?: 'DateFilter';
  fromDate?: Maybe<Scalars['Date']['output']>;
  toDate?: Maybe<Scalars['Date']['output']>;
};

export type DateFilterDto = {
  __typename?: 'DateFilterDto';
  createdOn: Scalars['DateTime']['output'];
  filter: DateFilter;
  filterConfigId: Scalars['Int']['output'];
  filterType: FilterType;
  gridId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type DateFilterFilterInput = {
  and?: InputMaybe<Array<DateFilterFilterInput>>;
  fromDate?: InputMaybe<DateOperationFilterInput>;
  or?: InputMaybe<Array<DateFilterFilterInput>>;
  toDate?: InputMaybe<DateOperationFilterInput>;
};

export type DateFilterInput = {
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum DateFormat {
  Day = 'DAY',
  DayMonth = 'DAY_MONTH',
  DayMonthYear = 'DAY_MONTH_YEAR',
  Month = 'MONTH',
  MonthYear = 'MONTH_YEAR'
}

export type DateGridFilterFilterInput = {
  and?: InputMaybe<Array<DateGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dateFilter?: InputMaybe<DateFilterFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DateGridFilterFilterInput>>;
};

export type DateGridFilterInput = {
  dateFilter: DateFilterInput;
  entityGridFilterConfigId: Scalars['Int']['input'];
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeactivateEmployeeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeactivateEmployeeInput = {
  deactivateDate: Scalars['Date']['input'];
  id: Scalars['Int']['input'];
};

export type DeactivateEmployeePayload = {
  __typename?: 'DeactivateEmployeePayload';
  errors?: Maybe<Array<DeactivateEmployeeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DecisionArgumentFilterInput = {
  and?: InputMaybe<Array<DecisionArgumentFilterInput>>;
  argument?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  decision?: InputMaybe<DecisionFilterInput>;
  decisionId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isCon?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isPro?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DecisionArgumentFilterInput>>;
};

export type DecisionEmployee = {
  __typename?: 'DecisionEmployee';
  decisionId: Scalars['Int']['output'];
  employeeId: Scalars['Int']['output'];
};

export type DecisionEmployeeFilterInput = {
  and?: InputMaybe<Array<DecisionEmployeeFilterInput>>;
  decision?: InputMaybe<DecisionFilterInput>;
  decisionId?: InputMaybe<IntOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DecisionEmployeeFilterInput>>;
};

export type DecisionFilterInput = {
  and?: InputMaybe<Array<DecisionFilterInput>>;
  challenge?: InputMaybe<StringOperationFilterInput>;
  closedOn?: InputMaybe<DateTimeOperationFilterInput>;
  conclusion?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  decisionArguments?: InputMaybe<ListFilterInputTypeOfDecisionArgumentFilterInput>;
  decisionEmployees?: InputMaybe<ListFilterInputTypeOfDecisionEmployeeFilterInput>;
  dueOn?: InputMaybe<DateOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DecisionFilterInput>>;
  primaryEmployee?: InputMaybe<EmployeeFilterInput>;
  primaryEmployeeId?: InputMaybe<IntOperationFilterInput>;
};

export type DefaultDateGridFilterFilterInput = {
  and?: InputMaybe<Array<DefaultDateGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dateFilter?: InputMaybe<DateFilterFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityDefaultGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DefaultDateGridFilterFilterInput>>;
};

export type DefaultNumberGridFilterFilterInput = {
  and?: InputMaybe<Array<DefaultNumberGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityDefaultGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  numberFilter?: InputMaybe<NumberFilterFilterInput>;
  or?: InputMaybe<Array<DefaultNumberGridFilterFilterInput>>;
};

export type DefaultSelectGridFilterFilterInput = {
  and?: InputMaybe<Array<DefaultSelectGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityDefaultGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DefaultSelectGridFilterFilterInput>>;
  selectFilter?: InputMaybe<SelectFilterFilterInput>;
};

export type DefaultStringGridFilterFilterInput = {
  and?: InputMaybe<Array<DefaultStringGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityDefaultGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DefaultStringGridFilterFilterInput>>;
  stringFilter?: InputMaybe<StringFilterFilterInput>;
};

export type DeleteAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteAccessToEntityRowInput = {
  accessRowId: Scalars['Int']['input'];
};

export type DeleteAccessToEntityRowPayload = {
  __typename?: 'DeleteAccessToEntityRowPayload';
  errors?: Maybe<Array<DeleteAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteAddressInput = {
  id: Scalars['Int']['input'];
};

export type DeleteAddressPayload = {
  __typename?: 'DeleteAddressPayload';
  errors?: Maybe<Array<DeleteAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteBenefitError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteBenefitInput = {
  benefitId: Scalars['Int']['input'];
};

export type DeleteBenefitPayload = {
  __typename?: 'DeleteBenefitPayload';
  errors?: Maybe<Array<DeleteBenefitError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteCertificateTypeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteCertificateTypeInput = {
  certificateTypeId: Scalars['Int']['input'];
};

export type DeleteCertificateTypePayload = {
  __typename?: 'DeleteCertificateTypePayload';
  errors?: Maybe<Array<DeleteCertificateTypeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteCompetenceTypeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteCompetenceTypeInput = {
  competenceTypeId: Scalars['Int']['input'];
};

export type DeleteCompetenceTypePayload = {
  __typename?: 'DeleteCompetenceTypePayload';
  errors?: Maybe<Array<DeleteCompetenceTypeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteCustomFieldForEntityError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteCustomFieldForEntityInput = {
  fieldId: Scalars['Int']['input'];
};

export type DeleteCustomFieldForEntityPayload = {
  __typename?: 'DeleteCustomFieldForEntityPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<DeleteCustomFieldForEntityError>>;
};

export type DeleteDepartmentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteDepartmentInput = {
  departmentId: Scalars['Int']['input'];
};

export type DeleteDepartmentPayload = {
  __typename?: 'DeleteDepartmentPayload';
  errors?: Maybe<Array<DeleteDepartmentError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeAbsenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeAbsenceInput = {
  absenceId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
};

export type DeleteEmployeeAbsencePayload = {
  __typename?: 'DeleteEmployeeAbsencePayload';
  errors?: Maybe<Array<DeleteEmployeeAbsenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeAddressInput = {
  addressId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
};

export type DeleteEmployeeAddressPayload = {
  __typename?: 'DeleteEmployeeAddressPayload';
  errors?: Maybe<Array<DeleteEmployeeAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeAllergyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeAllergyInput = {
  allergyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
};

export type DeleteEmployeeAllergyPayload = {
  __typename?: 'DeleteEmployeeAllergyPayload';
  errors?: Maybe<Array<DeleteEmployeeAllergyError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeCertificateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeCertificateInput = {
  certificateId: Scalars['Int']['input'];
};

export type DeleteEmployeeCertificatePayload = {
  __typename?: 'DeleteEmployeeCertificatePayload';
  errors?: Maybe<Array<DeleteEmployeeCertificateError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeCompetenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeCompetenceInput = {
  competenceId: Scalars['Int']['input'];
};

export type DeleteEmployeeCompetencePayload = {
  __typename?: 'DeleteEmployeeCompetencePayload';
  errors?: Maybe<Array<DeleteEmployeeCompetenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeDeviceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeDeviceInput = {
  deviceId: Scalars['Int']['input'];
};

export type DeleteEmployeeDevicePayload = {
  __typename?: 'DeleteEmployeeDevicePayload';
  errors?: Maybe<Array<DeleteEmployeeDeviceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeEducationError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeEducationInput = {
  educationId: Scalars['Int']['input'];
};

export type DeleteEmployeeEducationPayload = {
  __typename?: 'DeleteEmployeeEducationPayload';
  errors?: Maybe<Array<DeleteEmployeeEducationError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeEmploymentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeEmploymentInput = {
  employmentId: Scalars['Int']['input'];
};

export type DeleteEmployeeEmploymentPayload = {
  __typename?: 'DeleteEmployeeEmploymentPayload';
  errors?: Maybe<Array<DeleteEmployeeEmploymentError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEmployeeFamilyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteEmployeeFamilyInput = {
  employeeId: Scalars['Int']['input'];
  familyId: Scalars['Int']['input'];
};

export type DeleteEmployeeFamilyPayload = {
  __typename?: 'DeleteEmployeeFamilyPayload';
  errors?: Maybe<Array<DeleteEmployeeFamilyError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteInsuranceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteInsuranceInput = {
  insuranceId: Scalars['Int']['input'];
};

export type DeleteInsurancePayload = {
  __typename?: 'DeleteInsurancePayload';
  errors?: Maybe<Array<DeleteInsuranceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteMasterModuleError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteMasterModuleInput = {
  moduleId: Scalars['Int']['input'];
};

export type DeleteMasterModulePayload = {
  __typename?: 'DeleteMasterModulePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<DeleteMasterModuleError>>;
};

export type DeleteOfficeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteOfficeInput = {
  officeId: Scalars['Int']['input'];
};

export type DeleteOfficePayload = {
  __typename?: 'DeleteOfficePayload';
  errors?: Maybe<Array<DeleteOfficeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteOkrObjectiveError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteOkrObjectiveInput = {
  okrObjectiveId: Scalars['Int']['input'];
};

export type DeleteOkrObjectivePayload = {
  __typename?: 'DeleteOkrObjectivePayload';
  errors?: Maybe<Array<DeleteOkrObjectiveError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteOrganisationAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteOrganisationAddressInput = {
  addressId: Scalars['Int']['input'];
  organisationId: Scalars['Int']['input'];
};

export type DeleteOrganisationAddressPayload = {
  __typename?: 'DeleteOrganisationAddressPayload';
  errors?: Maybe<Array<DeleteOrganisationAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteOrganisationError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteOrganisationInput = {
  id: Scalars['Int']['input'];
};

export type DeleteOrganisationPayload = {
  __typename?: 'DeleteOrganisationPayload';
  errors?: Maybe<Array<DeleteOrganisationError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeletePositionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeletePositionInput = {
  positionId: Scalars['Int']['input'];
};

export type DeletePositionPayload = {
  __typename?: 'DeletePositionPayload';
  errors?: Maybe<Array<DeletePositionError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeletePowerGridError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeletePowerGridInput = {
  powerGridId: Scalars['Int']['input'];
};

export type DeletePowerGridPayload = {
  __typename?: 'DeletePowerGridPayload';
  errors?: Maybe<Array<DeletePowerGridError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteResponsibilityError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteResponsibilityInput = {
  responsibilityId: Scalars['Int']['input'];
};

export type DeleteResponsibilityPayload = {
  __typename?: 'DeleteResponsibilityPayload';
  errors?: Maybe<Array<DeleteResponsibilityError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteTeamError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type DeleteTeamInput = {
  teamId: Scalars['Int']['input'];
};

export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  errors?: Maybe<Array<DeleteTeamError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type DeleteTodoInput = {
  todoId: Scalars['Int']['input'];
};

export type DeleteTodoPayload = {
  __typename?: 'DeleteTodoPayload';
  int?: Maybe<Scalars['Int']['output']>;
};

export type DepartmentAccessDto = {
  __typename?: 'DepartmentAccessDto';
  access: AccessDto;
  departmentId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type DepartmentInput = {
  externalERPId?: InputMaybe<Scalars['String']['input']>;
  managerId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

/** A segment of a collection. */
export type DepartmentsForOrganisationCollectionSegment = {
  __typename?: 'DepartmentsForOrganisationCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganisationDepartment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeviceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Int']['input'];
};

export type DeviceType = {
  __typename?: 'DeviceType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type DropdownDto = {
  __typename?: 'DropdownDto';
  isDeleted: Scalars['Boolean']['output'];
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label: Scalars['String']['output'];
  /** SortIndex to sort the dropdowns by. If not provided, the dropdowns will be sorted by Label */
  sortIndex?: Maybe<Scalars['Int']['output']>;
  /** Value that field should be bound to, usually an ID */
  value: Scalars['Int']['output'];
};

export type EducationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  institutionName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  typeId: Scalars['Int']['input'];
};

export type EducationType = {
  __typename?: 'EducationType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type Employee = {
  __typename?: 'Employee';
  about?: Maybe<Scalars['String']['output']>;
  absences: Array<EmployeeAbsence>;
  accountPrimaryEmployees: Array<AccountPrimaryEmployee>;
  addresses: Array<Address>;
  age?: Maybe<Scalars['Int']['output']>;
  allergies: Array<EmployeeAllergy>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['Date']['output']>;
  cellular?: Maybe<AxosPhoneNumber>;
  certificates: Array<EmployeeCertificate>;
  competences: Array<EmployeeCompetence>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  currentEmployment?: Maybe<EmployeeEmployment>;
  currentEmploymentId?: Maybe<Scalars['Int']['output']>;
  currentEmploymentType?: Maybe<EmploymentType>;
  decisionEmployees: Array<DecisionEmployee>;
  defaultAddress?: Maybe<Address>;
  defaultAddressId?: Maybe<Scalars['Int']['output']>;
  defaultProduct?: Maybe<Product>;
  defaultProductId?: Maybe<Scalars['Int']['output']>;
  devices: Array<EmployeeDevice>;
  educations: Array<EmployeeEducation>;
  email: AxosEmail;
  employeeAddresses: Array<EmployeeAddress>;
  employments: Array<EmployeeEmployment>;
  expenseManagerId?: Maybe<Scalars['Int']['output']>;
  externalERPSystemId?: Maybe<Scalars['String']['output']>;
  externalSalarySystemId?: Maybe<Scalars['String']['output']>;
  family: Array<EmployeeFamily>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  genderId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  initials: Scalars['String']['output'];
  invitationSentOn?: Maybe<Scalars['DateTime']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isDeactivated: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isEmployed: Scalars['Boolean']['output'];
  isSystemUser: Scalars['Boolean']['output'];
  jobDescription?: Maybe<Scalars['String']['output']>;
  keycloakId: Scalars['UUID']['output'];
  kmHomeWork?: Maybe<Scalars['Int']['output']>;
  language: Language;
  languageId: Scalars['Int']['output'];
  languageName?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  manager?: Maybe<Employee>;
  managerId?: Maybe<Scalars['Int']['output']>;
  maritalStatusType?: Maybe<MaritalStatusType>;
  maritalStatusTypeId?: Maybe<Scalars['Int']['output']>;
  nationalityId?: Maybe<Scalars['Int']['output']>;
  nationalityName?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  okrObjectives: Array<OkrObjective>;
  organisation?: Maybe<Organisation>;
  organisationBenefitEmployees: Array<OrganisationBenefitEmployee>;
  organisationBenefits: Array<OrganisationBenefit>;
  organisationDepartment?: Maybe<OrganisationDepartment>;
  organisationDepartmentId: Scalars['Int']['output'];
  organisationId: Scalars['Int']['output'];
  organisationInsuranceEmployees: Array<OrganisationInsuranceEmployee>;
  organisationInsurances: Array<OrganisationInsurance>;
  organisationOfficeEmployees: Array<OrganisationOfficeEmployee>;
  organisationOffices: Array<OrganisationOffice>;
  organisationTeamEmployees: Array<OrganisationTeamEmployee>;
  organisationTeams: Array<OrganisationTeam>;
  phone?: Maybe<AxosPhoneNumber>;
  position?: Maybe<Position>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  recursiveManagerIds: Array<Scalars['Int']['output']>;
  responsibilities: Array<Address>;
  sizeFeet?: Maybe<SizeFeet>;
  sizeFeetId?: Maybe<Scalars['Int']['output']>;
  sizeLegs?: Maybe<SizeLegs>;
  sizeLegsId?: Maybe<Scalars['Int']['output']>;
  sizeTorso?: Maybe<SizeTorso>;
  sizeTorsoId?: Maybe<Scalars['Int']['output']>;
  sizeWaist?: Maybe<SizeWaist>;
  sizeWaistId?: Maybe<Scalars['Int']['output']>;
  socialSecurityNumber?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['Date']['output'];
  templateRevisions: Array<TemplateRevision>;
  timeSheetManagerId?: Maybe<Scalars['Int']['output']>;
  toDoEmployees: Array<ToDoEmployee>;
  trialMonths?: Maybe<Scalars['Int']['output']>;
};

export type EmployeeAbsence = {
  __typename?: 'EmployeeAbsence';
  absenceType: EmployeeAbsenceType;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  employee: Employee;
  employeeId: Scalars['Int']['output'];
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  latestStatus: EmployeeAbsenceStatus;
  number: Scalars['String']['output'];
  numberOfDays: Scalars['Decimal']['output'];
  startDate: Scalars['Date']['output'];
  statusType: EmployeeAbsenceStatusType;
  statusTypeId: Scalars['Int']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeAbsenceBalanceDto = {
  __typename?: 'EmployeeAbsenceBalanceDto';
  balanceDays: Scalars['Decimal']['output'];
  balanceYear: Scalars['Int']['output'];
  employee: Employee;
  employeeId: Scalars['Int']['output'];
  holidays: Scalars['Decimal']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  previousBalanceDays: Scalars['Decimal']['output'];
  transferredDays: Scalars['Decimal']['output'];
};

export type EmployeeAbsenceBalanceInput = {
  holidays: Scalars['Decimal']['input'];
  transferredDays: Scalars['Decimal']['input'];
};

export type EmployeeAbsenceStatistics = {
  __typename?: 'EmployeeAbsenceStatistics';
  absenceBalance: Scalars['Decimal']['output'];
  leave: Scalars['Decimal']['output'];
  leaveChangePercent: Scalars['Decimal']['output'];
  paidLeave: Scalars['Decimal']['output'];
  sickAbsence: Scalars['Decimal']['output'];
  sickAbsenceChange: Scalars['Decimal']['output'];
  sickChild: Scalars['Decimal']['output'];
  totalChangePercent: Scalars['Decimal']['output'];
  totalDays: Scalars['Decimal']['output'];
  vacation: Scalars['Decimal']['output'];
};

export type EmployeeAbsenceStatus = {
  __typename?: 'EmployeeAbsenceStatus';
  absenceId: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  statusType: EmployeeAbsenceStatusType;
  statusTypeId: Scalars['Int']['output'];
};

export type EmployeeAbsenceStatusType = {
  __typename?: 'EmployeeAbsenceStatusType';
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type EmployeeAbsenceType = {
  __typename?: 'EmployeeAbsenceType';
  id: Scalars['Int']['output'];
  isEmployeeSelectable: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type EmployeeAccessDto = {
  __typename?: 'EmployeeAccessDto';
  access: AccessDto;
  employeeId: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type EmployeeAddress = {
  __typename?: 'EmployeeAddress';
  addressId: Scalars['Int']['output'];
  employeeId: Scalars['Int']['output'];
};

export type EmployeeAddressFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<EmployeeAddressFilterInput>>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeAddressFilterInput>>;
};

export type EmployeeAllergy = {
  __typename?: 'EmployeeAllergy';
  allergyType: AllergyType;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeAllergySortInput = {
  allergyType?: InputMaybe<AllergyTypeSortInput>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  employee?: InputMaybe<EmployeeSortInput>;
  employeeId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  typeId?: InputMaybe<SortEnumType>;
};

export type EmployeeCertificate = {
  __typename?: 'EmployeeCertificate';
  certificateType: CertificateType;
  certifiedDate?: Maybe<Scalars['Date']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  doNotify: Scalars['Boolean']['output'];
  employeeId: Scalars['Int']['output'];
  expires?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  notificationDays?: Maybe<Scalars['Int']['output']>;
  number: Scalars['String']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeCompetence = {
  __typename?: 'EmployeeCompetence';
  competenceLevel?: Maybe<CompetenceLevel>;
  competenceType: CompetenceType;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  levelId?: Maybe<Scalars['Int']['output']>;
  number: Scalars['String']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeDevice = {
  __typename?: 'EmployeeDevice';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceType: DeviceType;
  employeeId: Scalars['Int']['output'];
  handInApprovedBy?: Maybe<Employee>;
  handInApprovedById?: Maybe<Scalars['Int']['output']>;
  handedInOn?: Maybe<Scalars['Date']['output']>;
  handedOutById?: Maybe<Scalars['Int']['output']>;
  handedOutOn?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeDropdownDto = {
  __typename?: 'EmployeeDropdownDto';
  initials: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label: Scalars['String']['output'];
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** SortIndex to sort the dropdowns by. If not provided, the dropdowns will be sorted by Label */
  sortIndex?: Maybe<Scalars['Int']['output']>;
  /** Value that field should be bound to, usually an ID */
  value: Scalars['Int']['output'];
};

export type EmployeeDropdownDtoSortInput = {
  initials?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label?: InputMaybe<SortEnumType>;
  profilePicture?: InputMaybe<SortEnumType>;
  /** SortIndex to sort the dropdowns by. If not provided, the dropdowns will be sorted by Label */
  sortIndex?: InputMaybe<SortEnumType>;
  /** Value that field should be bound to, usually an ID */
  value?: InputMaybe<SortEnumType>;
};

export type EmployeeEducation = {
  __typename?: 'EmployeeEducation';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  educationType: EducationType;
  employeeId: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  institutionName?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeEmployment = {
  __typename?: 'EmployeeEmployment';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['Int']['output'];
  employmentRatePercent?: Maybe<Scalars['Int']['output']>;
  employmentType: EmploymentType;
  employmentTypeId: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  isCurrent: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  position: Position;
  positionId: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  wageType: EmploymentWageType;
  wageTypeId: Scalars['Int']['output'];
  wageValue?: Maybe<Scalars['Decimal']['output']>;
};

export type EmployeeEmploymentFilterInput = {
  and?: InputMaybe<Array<EmployeeEmploymentFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  employmentRatePercent?: InputMaybe<IntOperationFilterInput>;
  employmentType?: InputMaybe<EmploymentTypeFilterInput>;
  employmentTypeId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeEmploymentFilterInput>>;
  position?: InputMaybe<PositionFilterInput>;
  positionId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  wageType?: InputMaybe<EmploymentWageTypeFilterInput>;
  wageTypeId?: InputMaybe<IntOperationFilterInput>;
  wageValue?: InputMaybe<DecimalOperationFilterInput>;
};

export type EmployeeEmploymentSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  employee?: InputMaybe<EmployeeSortInput>;
  employeeId?: InputMaybe<SortEnumType>;
  employmentRatePercent?: InputMaybe<SortEnumType>;
  employmentType?: InputMaybe<EmploymentTypeSortInput>;
  employmentTypeId?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  position?: InputMaybe<PositionSortInput>;
  positionId?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  wageType?: InputMaybe<EmploymentWageTypeSortInput>;
  wageTypeId?: InputMaybe<SortEnumType>;
  wageValue?: InputMaybe<SortEnumType>;
};

export type EmployeeFamily = {
  __typename?: 'EmployeeFamily';
  age?: Maybe<Scalars['Int']['output']>;
  birthDate?: Maybe<Scalars['Date']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  email?: Maybe<AxosEmail>;
  employeeId: Scalars['Int']['output'];
  familyAllergies: Array<EmployeeFamilyAllergy>;
  familyRelationType: FamilyRelationType;
  gender: Gender;
  genderId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isNextOfKin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<AxosPhoneNumber>;
  relationTypeId: Scalars['Int']['output'];
  sizeFeet?: Maybe<SizeFeet>;
  sizeFeetId?: Maybe<Scalars['Int']['output']>;
  sizeLegs?: Maybe<SizeLegs>;
  sizeLegsId?: Maybe<Scalars['Int']['output']>;
  sizeTorso?: Maybe<SizeTorso>;
  sizeTorsoId?: Maybe<Scalars['Int']['output']>;
  sizeWaist?: Maybe<SizeWaist>;
  sizeWaistId?: Maybe<Scalars['Int']['output']>;
};

export type EmployeeFamilyAllergy = {
  __typename?: 'EmployeeFamilyAllergy';
  allergyType: AllergyType;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  employeeFamilyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  typeId: Scalars['Int']['output'];
};

export type EmployeeFilterInput = {
  about?: InputMaybe<StringOperationFilterInput>;
  accountPrimaryEmployees?: InputMaybe<ListFilterInputTypeOfAccountPrimaryEmployeeFilterInput>;
  age?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<EmployeeFilterInput>>;
  bankAccountNumber?: InputMaybe<StringOperationFilterInput>;
  birthDate?: InputMaybe<DateOperationFilterInput>;
  cellular?: InputMaybe<AxosPhoneNumberFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  currentEmployment?: InputMaybe<EmployeeEmploymentFilterInput>;
  currentEmploymentId?: InputMaybe<IntOperationFilterInput>;
  decisionEmployees?: InputMaybe<ListFilterInputTypeOfDecisionEmployeeFilterInput>;
  decisions?: InputMaybe<ListFilterInputTypeOfDecisionFilterInput>;
  defaultAddress?: InputMaybe<AddressFilterInput>;
  defaultAddressId?: InputMaybe<IntOperationFilterInput>;
  defaultProduct?: InputMaybe<ProductFilterInput>;
  defaultProductId?: InputMaybe<IntOperationFilterInput>;
  departments?: InputMaybe<ListFilterInputTypeOfOrganisationDepartmentFilterInput>;
  email?: InputMaybe<AxosEmailFilterInput>;
  employeeAddresses?: InputMaybe<ListFilterInputTypeOfEmployeeAddressFilterInput>;
  expenseManager?: InputMaybe<EmployeeFilterInput>;
  expenseManagerId?: InputMaybe<IntOperationFilterInput>;
  externalERPSystemId?: InputMaybe<StringOperationFilterInput>;
  externalSalarySystemId?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  gender?: InputMaybe<GenderFilterInput>;
  genderId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initials?: InputMaybe<StringOperationFilterInput>;
  invitationSentOn?: InputMaybe<DateTimeOperationFilterInput>;
  isAdmin?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isEmployed?: InputMaybe<BooleanOperationFilterInput>;
  isSystemUser?: InputMaybe<BooleanOperationFilterInput>;
  jobDescription?: InputMaybe<StringOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  keycloakId?: InputMaybe<UuidOperationFilterInput>;
  kmHomeWork?: InputMaybe<IntOperationFilterInput>;
  language?: InputMaybe<LanguageFilterInput>;
  languageId?: InputMaybe<IntOperationFilterInput>;
  lastActive?: InputMaybe<DateTimeOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  managedEmployees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  maritalStatusType?: InputMaybe<MaritalStatusTypeFilterInput>;
  maritalStatusTypeId?: InputMaybe<IntOperationFilterInput>;
  nationality?: InputMaybe<CountryFilterInput>;
  nationalityId?: InputMaybe<IntOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationBenefitEmployees?: InputMaybe<ListFilterInputTypeOfOrganisationBenefitEmployeeFilterInput>;
  organisationDepartment?: InputMaybe<OrganisationDepartmentFilterInput>;
  organisationDepartmentId?: InputMaybe<IntOperationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  organisationInsuranceEmployees?: InputMaybe<ListFilterInputTypeOfOrganisationInsuranceEmployeeFilterInput>;
  organisationInsurances?: InputMaybe<ListFilterInputTypeOfOrganisationInsuranceFilterInput>;
  organisationOfficeEmployees?: InputMaybe<ListFilterInputTypeOfOrganisationOfficeEmployeeFilterInput>;
  organisationOffices?: InputMaybe<ListFilterInputTypeOfOrganisationOfficeFilterInput>;
  organisationResponsibilityTasks?: InputMaybe<ListFilterInputTypeOfOrganisationResponsibilityTaskFilterInput>;
  organisationTeamEmployees?: InputMaybe<ListFilterInputTypeOfOrganisationTeamEmployeeFilterInput>;
  organisationTeams?: InputMaybe<ListFilterInputTypeOfOrganisationTeamFilterInput>;
  organisations?: InputMaybe<ListFilterInputTypeOfOrganisationFilterInput>;
  phone?: InputMaybe<AxosPhoneNumberFilterInput>;
  profilePicture?: InputMaybe<StringOperationFilterInput>;
  sizeFeet?: InputMaybe<SizeFeetFilterInput>;
  sizeFeetId?: InputMaybe<IntOperationFilterInput>;
  sizeLegs?: InputMaybe<SizeLegsFilterInput>;
  sizeLegsId?: InputMaybe<IntOperationFilterInput>;
  sizeTorso?: InputMaybe<SizeTorsoFilterInput>;
  sizeTorsoId?: InputMaybe<IntOperationFilterInput>;
  sizeWaist?: InputMaybe<SizeWaistFilterInput>;
  sizeWaistId?: InputMaybe<IntOperationFilterInput>;
  socialSecurityNumber?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  templateRevisions?: InputMaybe<ListFilterInputTypeOfTemplateRevisionFilterInput>;
  timeSheetManager?: InputMaybe<EmployeeFilterInput>;
  timeSheetManagerId?: InputMaybe<IntOperationFilterInput>;
  toDoEmployees?: InputMaybe<ListFilterInputTypeOfToDoEmployeeFilterInput>;
  toDoStatuses?: InputMaybe<ListFilterInputTypeOfToDoStatusFilterInput>;
  toDos?: InputMaybe<ListFilterInputTypeOfToDoFilterInput>;
  trialMonths?: InputMaybe<IntOperationFilterInput>;
};

export type EmployeeMeetingFilterInput = {
  and?: InputMaybe<Array<EmployeeMeetingFilterInput>>;
  checklist?: InputMaybe<ChecklistFilterInput>;
  checklistId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<StringOperationFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  meetingDate?: InputMaybe<DateOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeMeetingFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  privateNotes?: InputMaybe<StringOperationFilterInput>;
  sharedNotes?: InputMaybe<StringOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  template?: InputMaybe<TemplateFilterInput>;
  templateId?: InputMaybe<IntOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type EmployeeSortInput = {
  about?: InputMaybe<SortEnumType>;
  age?: InputMaybe<SortEnumType>;
  bankAccountNumber?: InputMaybe<SortEnumType>;
  birthDate?: InputMaybe<SortEnumType>;
  cellular?: InputMaybe<AxosPhoneNumberSortInput>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  currentEmployment?: InputMaybe<EmployeeEmploymentSortInput>;
  currentEmploymentId?: InputMaybe<SortEnumType>;
  defaultAddress?: InputMaybe<AddressSortInput>;
  defaultAddressId?: InputMaybe<SortEnumType>;
  defaultProduct?: InputMaybe<ProductSortInput>;
  defaultProductId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<AxosEmailSortInput>;
  expenseManager?: InputMaybe<EmployeeSortInput>;
  expenseManagerId?: InputMaybe<SortEnumType>;
  externalERPSystemId?: InputMaybe<SortEnumType>;
  externalSalarySystemId?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  gender?: InputMaybe<GenderSortInput>;
  genderId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initials?: InputMaybe<SortEnumType>;
  invitationSentOn?: InputMaybe<SortEnumType>;
  isAdmin?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isEmployed?: InputMaybe<SortEnumType>;
  isSystemUser?: InputMaybe<SortEnumType>;
  jobDescription?: InputMaybe<SortEnumType>;
  keycloakId?: InputMaybe<SortEnumType>;
  kmHomeWork?: InputMaybe<SortEnumType>;
  language?: InputMaybe<LanguageSortInput>;
  languageId?: InputMaybe<SortEnumType>;
  lastActive?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<EmployeeSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  maritalStatusType?: InputMaybe<MaritalStatusTypeSortInput>;
  maritalStatusTypeId?: InputMaybe<SortEnumType>;
  nationality?: InputMaybe<CountrySortInput>;
  nationalityId?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationDepartment?: InputMaybe<OrganisationDepartmentSortInput>;
  organisationDepartmentId?: InputMaybe<SortEnumType>;
  organisationId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<AxosPhoneNumberSortInput>;
  profilePicture?: InputMaybe<SortEnumType>;
  sizeFeet?: InputMaybe<SizeFeetSortInput>;
  sizeFeetId?: InputMaybe<SortEnumType>;
  sizeLegs?: InputMaybe<SizeLegsSortInput>;
  sizeLegsId?: InputMaybe<SortEnumType>;
  sizeTorso?: InputMaybe<SizeTorsoSortInput>;
  sizeTorsoId?: InputMaybe<SortEnumType>;
  sizeWaist?: InputMaybe<SizeWaistSortInput>;
  sizeWaistId?: InputMaybe<SortEnumType>;
  socialSecurityNumber?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  timeSheetManager?: InputMaybe<EmployeeSortInput>;
  timeSheetManagerId?: InputMaybe<SortEnumType>;
  trialMonths?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EmployeesCollectionSegment = {
  __typename?: 'EmployeesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Employee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EmploymentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  employmentRatePercent: Scalars['Int']['input'];
  employmentTypeId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  positionId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  wageTypeId: Scalars['Int']['input'];
  wageValue?: InputMaybe<Scalars['Decimal']['input']>;
};

export type EmploymentType = {
  __typename?: 'EmploymentType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type EmploymentTypeFilterInput = {
  and?: InputMaybe<Array<EmploymentTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmploymentTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type EmploymentTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type EmploymentWageType = {
  __typename?: 'EmploymentWageType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type EmploymentWageTypeFilterInput = {
  and?: InputMaybe<Array<EmploymentWageTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmploymentWageTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type EmploymentWageTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EntitiesCollectionSegment = {
  __typename?: 'EntitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Entity>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Entity = {
  __typename?: 'Entity';
  defaultOrganisationNumberings: Array<OrganisationNumberingDefault>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nameResourceId: Scalars['String']['output'];
  relativeUrl?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Int']['output'];
};

export type EntityCustomField = {
  __typename?: 'EntityCustomField';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  /** Returns data type of custom field column */
  dataType: CustomFieldDataType;
  entity: Entity;
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  typeId: Scalars['Int']['output'];
  typeMeta?: Maybe<Scalars['String']['output']>;
  typeName: Scalars['String']['output'];
};

export type EntityCustomFieldFilterInput = {
  and?: InputMaybe<Array<EntityCustomFieldFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  fieldValues?: InputMaybe<ListFilterInputTypeOfEntityCustomFieldValueFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EntityCustomFieldFilterInput>>;
  typeId?: InputMaybe<CustomFieldDataTypeOperationFilterInput>;
  typeMeta?: InputMaybe<StringOperationFilterInput>;
};

export type EntityCustomFieldValueFilterInput = {
  and?: InputMaybe<Array<EntityCustomFieldValueFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  field?: InputMaybe<EntityCustomFieldFilterInput>;
  fieldId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  instanceId?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EntityCustomFieldValueFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
  valueBoolean?: InputMaybe<BooleanOperationFilterInput>;
  valueDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  valueNumber?: InputMaybe<DecimalOperationFilterInput>;
  valueString?: InputMaybe<StringOperationFilterInput>;
};

export type EntityDefaultGridColumnFilterInput = {
  and?: InputMaybe<Array<EntityDefaultGridColumnFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  defaultGrid?: InputMaybe<EntityDefaultGridFilterInput>;
  entityGridColumnConfig?: InputMaybe<EntityGridColumnConfigFilterInput>;
  entityGridColumnConfigId?: InputMaybe<IntOperationFilterInput>;
  gridColumnProperties?: InputMaybe<GridColumnPropertiesFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EntityDefaultGridColumnFilterInput>>;
};

export type EntityDefaultGridFilterInput = {
  and?: InputMaybe<Array<EntityDefaultGridFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dateFilters?: InputMaybe<ListFilterInputTypeOfDefaultDateGridFilterFilterInput>;
  defaultGridColumns?: InputMaybe<ListFilterInputTypeOfEntityDefaultGridColumnFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isZebra?: InputMaybe<BooleanOperationFilterInput>;
  listType?: InputMaybe<IntOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  numberFilters?: InputMaybe<ListFilterInputTypeOfDefaultNumberGridFilterFilterInput>;
  or?: InputMaybe<Array<EntityDefaultGridFilterInput>>;
  pageCount?: InputMaybe<IntOperationFilterInput>;
  selectFilters?: InputMaybe<ListFilterInputTypeOfDefaultSelectGridFilterFilterInput>;
  sortColumnConfig?: InputMaybe<EntityGridColumnConfigFilterInput>;
  sortColumnConfigId?: InputMaybe<IntOperationFilterInput>;
  sortDirection?: InputMaybe<NullableOfSortDirectionOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  stringFilters?: InputMaybe<ListFilterInputTypeOfDefaultStringGridFilterFilterInput>;
  subGrids?: InputMaybe<ListFilterInputTypeOfEntityGridSubGridFilterInput>;
};

export type EntityEventDto = {
  __typename?: 'EntityEventDto';
  createdOn: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  instanceId: Scalars['Int']['output'];
  isRead: Scalars['Boolean']['output'];
  resourceId: Scalars['String']['output'];
  responsibleId?: Maybe<Scalars['Int']['output']>;
  responsibleImage?: Maybe<Scalars['String']['output']>;
  responsibleInitials?: Maybe<Scalars['String']['output']>;
  responsibleName?: Maybe<Scalars['String']['output']>;
};

export type EntityEventSubscription = {
  __typename?: 'EntityEventSubscription';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  employeeId: Scalars['Int']['output'];
  entity: Entity;
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  instanceId?: Maybe<Scalars['Int']['output']>;
  isDeleted: Scalars['Boolean']['output'];
};

export type EntityFilterInput = {
  and?: InputMaybe<Array<EntityFilterInput>>;
  childLinks?: InputMaybe<ListFilterInputTypeOfLinkFilterInput>;
  defaultOrganisationNumberings?: InputMaybe<ListFilterInputTypeOfOrganisationNumberingDefaultFilterInput>;
  entityDefaultGrids?: InputMaybe<ListFilterInputTypeOfEntityDefaultGridFilterInput>;
  entityGrids?: InputMaybe<ListFilterInputTypeOfEntityGridFilterInput>;
  gridConfig?: InputMaybe<EntityGridConfigFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EntityFilterInput>>;
  organisationNumberings?: InputMaybe<ListFilterInputTypeOfOrganisationNumberingFilterInput>;
  parentLinks?: InputMaybe<ListFilterInputTypeOfLinkFilterInput>;
  relativeUrl?: InputMaybe<StringOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  templates?: InputMaybe<ListFilterInputTypeOfTemplateFilterInput>;
};

export type EntityGridColumnConfig = {
  __typename?: 'EntityGridColumnConfig';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  dataKey: Scalars['String']['output'];
  dataType: DataType;
  entityGridConfigId: Scalars['Int']['output'];
  gridColumnProperties: GridColumnProperties;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSortable: Scalars['Boolean']['output'];
  linkModel?: Maybe<Scalars['String']['output']>;
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type EntityGridColumnConfigFilterInput = {
  and?: InputMaybe<Array<EntityGridColumnConfigFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dataKey?: InputMaybe<StringOperationFilterInput>;
  dataType?: InputMaybe<DataTypeOperationFilterInput>;
  entityGridConfig?: InputMaybe<EntityGridConfigFilterInput>;
  entityGridConfigId?: InputMaybe<IntOperationFilterInput>;
  gridColumnProperties?: InputMaybe<GridColumnPropertiesFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isSortable?: InputMaybe<BooleanOperationFilterInput>;
  linkModel?: InputMaybe<StringOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridColumnConfigFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type EntityGridColumnFilterInput = {
  and?: InputMaybe<Array<EntityGridColumnFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  entityGridColumnConfig?: InputMaybe<EntityGridColumnConfigFilterInput>;
  entityGridColumnConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridColumnProperties?: InputMaybe<GridColumnPropertiesFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridColumnFilterInput>>;
};

export type EntityGridConfig = {
  __typename?: 'EntityGridConfig';
  /** Returns all columns that can be configured on a powergrid for the given entity */
  availableCustomFieldsForEntityGrid: Array<EntityCustomField>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  dataSource: Scalars['String']['output'];
  entity: Entity;
  entityGridColumnConfigs: Array<EntityGridColumnConfig>;
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  subGridConfigs: Array<EntityGridSubGridConfig>;
};

export type EntityGridConfigFilterInput = {
  and?: InputMaybe<Array<EntityGridConfigFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dataSource?: InputMaybe<StringOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityGridColumnConfigs?: InputMaybe<ListFilterInputTypeOfEntityGridColumnConfigFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridConfigFilterInput>>;
};

export type EntityGridConfigSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  dataSource?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<EntitySortInput>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
};

export type EntityGridCustomFieldColumnFilterInput = {
  and?: InputMaybe<Array<EntityGridCustomFieldColumnFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  customField?: InputMaybe<EntityCustomFieldFilterInput>;
  customFieldId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridColumnProperties?: InputMaybe<GridColumnPropertiesFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridCustomFieldColumnFilterInput>>;
};

export type EntityGridFilterConfig = {
  __typename?: 'EntityGridFilterConfig';
  columnName: Scalars['String']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  dropdownQuery?: Maybe<Scalars['String']['output']>;
  entity: Entity;
  entityId: Scalars['Int']['output'];
  filterType: FilterType;
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type EntityGridFilterConfigFilterInput = {
  and?: InputMaybe<Array<EntityGridFilterConfigFilterInput>>;
  columnName?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dropdownQuery?: InputMaybe<StringOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  filterType?: InputMaybe<FilterTypeOperationFilterInput>;
  icon?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridFilterConfigFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type EntityGridFilterInput = {
  and?: InputMaybe<Array<EntityGridFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  customFieldBooleanGridFilters?: InputMaybe<ListFilterInputTypeOfCustomFieldBooleanGridFilterFilterInput>;
  customFieldDateGridFilters?: InputMaybe<ListFilterInputTypeOfCustomFieldDateGridFilterFilterInput>;
  customFieldGridColumns?: InputMaybe<ListFilterInputTypeOfEntityGridCustomFieldColumnFilterInput>;
  customFieldNumberFilters?: InputMaybe<ListFilterInputTypeOfCustomFieldNumberGridFilterFilterInput>;
  customFieldStringGridFilters?: InputMaybe<ListFilterInputTypeOfCustomFieldStringGridFilterFilterInput>;
  dateFilters?: InputMaybe<ListFilterInputTypeOfDateGridFilterFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  gridColumns?: InputMaybe<ListFilterInputTypeOfEntityGridColumnFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isSubGrid?: InputMaybe<BooleanOperationFilterInput>;
  isZebra?: InputMaybe<BooleanOperationFilterInput>;
  listType?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberFilters?: InputMaybe<ListFilterInputTypeOfNumberGridFilterFilterInput>;
  or?: InputMaybe<Array<EntityGridFilterInput>>;
  owner?: InputMaybe<EmployeeFilterInput>;
  ownerId?: InputMaybe<IntOperationFilterInput>;
  pageCount?: InputMaybe<IntOperationFilterInput>;
  selectFilters?: InputMaybe<ListFilterInputTypeOfSelectGridFilterFilterInput>;
  sortColumnConfig?: InputMaybe<EntityGridColumnConfigFilterInput>;
  sortColumnConfigId?: InputMaybe<IntOperationFilterInput>;
  sortCustomField?: InputMaybe<EntityCustomFieldFilterInput>;
  sortCustomFieldId?: InputMaybe<IntOperationFilterInput>;
  sortDirection?: InputMaybe<NullableOfSortDirectionOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  stringFilters?: InputMaybe<ListFilterInputTypeOfStringGridFilterFilterInput>;
  subGrids?: InputMaybe<ListFilterInputTypeOfEntityGridSubGridFilterInput>;
};

export type EntityGridSubGridConfig = {
  __typename?: 'EntityGridSubGridConfig';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  filterConfig: EntityGridFilterConfig;
  filterConfigId: Scalars['Int']['output'];
  filterValueField: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
  sourceGridId: Scalars['Int']['output'];
  targetGridConfigId: Scalars['Int']['output'];
};

export type EntityGridSubGridConfigFilterInput = {
  and?: InputMaybe<Array<EntityGridSubGridConfigFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  filterValueField?: InputMaybe<StringOperationFilterInput>;
  icon?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridSubGridConfigFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  sourceGrid?: InputMaybe<EntityDefaultGridFilterInput>;
  sourceGridId?: InputMaybe<IntOperationFilterInput>;
  targetGridConfig?: InputMaybe<EntityGridConfigFilterInput>;
  targetGridConfigId?: InputMaybe<IntOperationFilterInput>;
};

export type EntityGridSubGridFilterInput = {
  and?: InputMaybe<Array<EntityGridSubGridFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EntityGridSubGridFilterInput>>;
  subGrid?: InputMaybe<EntityGridFilterInput>;
  subGridConfig?: InputMaybe<EntityGridSubGridConfigFilterInput>;
  subGridConfigId?: InputMaybe<IntOperationFilterInput>;
  subGridId?: InputMaybe<IntOperationFilterInput>;
};

export type EntityNotFoundError = AxosError & {
  __typename?: 'EntityNotFoundError';
  code: Scalars['String']['output'];
  extensions: Array<KeyValuePairOfStringAndObject>;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  resourceId: Scalars['String']['output'];
};

export type EntityRowAccessDto = {
  __typename?: 'EntityRowAccessDto';
  departmentAccesses: Array<DepartmentAccessDto>;
  employeeAccesses: Array<EmployeeAccessDto>;
  globalAccessRow?: Maybe<AccessDto>;
  officeAccesses: Array<OfficeAccessDto>;
  organisationAccesses: Array<OrganisationAccessDto>;
  teamAccesses: Array<TeamAccessDto>;
};

export type EntitySortInput = {
  gridConfig?: InputMaybe<EntityGridConfigSortInput>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nameResourceId?: InputMaybe<SortEnumType>;
  relativeUrl?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type EntityUrlDto = {
  __typename?: 'EntityUrlDto';
  entity: AxosEntities;
  id: Scalars['Int']['output'];
  relativeUrl: Scalars['String']['output'];
};

export type EntityValidationError = AxosError & {
  __typename?: 'EntityValidationError';
  code: Scalars['String']['output'];
  extensions: Array<KeyValuePairOfStringAndObject>;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  resourceId: Scalars['String']['output'];
};

export type FamilyInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  cellular?: InputMaybe<PhoneNumberInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  genderId: Scalars['Int']['input'];
  isNextOfKin: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<PhoneNumberInput>;
  relationTypeId: Scalars['Int']['input'];
  sizeFeetId?: InputMaybe<Scalars['Int']['input']>;
  sizeLegsId?: InputMaybe<Scalars['Int']['input']>;
  sizeTorsoId?: InputMaybe<Scalars['Int']['input']>;
  sizeWaistId?: InputMaybe<Scalars['Int']['input']>;
};

export type FamilyRelationType = {
  __typename?: 'FamilyRelationType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type FilterConfigDto = {
  __typename?: 'FilterConfigDto';
  customFieldFilterConfig: Array<CustomFieldFilterConfigDto>;
  entityGridFilterConfigs: Array<EntityGridFilterConfig>;
};

export type FilterListInput = {
  customFieldBooleanFilters: Array<CustomFieldBooleanGridFilterInput>;
  customFieldDateFilters: Array<CustomFieldDateGridFilterInput>;
  customFieldNumberFilters: Array<CustomFieldNumberGridFilterInput>;
  customFieldStringFilterInputs: Array<CustomFieldStringGridFilterInput>;
  dateFilters: Array<DateGridFilterInput>;
  numberFilters: Array<NumberGridFilterInput>;
  selectFilters: Array<SelectGridFilterInput>;
  stringFilters: Array<StringGridFilterInput>;
};

export enum FilterType {
  Date = 'DATE',
  Number = 'NUMBER',
  Select = 'SELECT',
  String = 'STRING'
}

export type FilterTypeOperationFilterInput = {
  eq?: InputMaybe<FilterType>;
  in?: InputMaybe<Array<FilterType>>;
  neq?: InputMaybe<FilterType>;
  nin?: InputMaybe<Array<FilterType>>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type Gender = {
  __typename?: 'Gender';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type GenderFilterInput = {
  and?: InputMaybe<Array<GenderFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GenderFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type GenderSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type GridColumnProperties = {
  __typename?: 'GridColumnProperties';
  dateFormat?: Maybe<DateFormat>;
  horizontalAlignType: HorizontalAlignType;
  isNoWrap: Scalars['Boolean']['output'];
  showAverage: Scalars['Boolean']['output'];
  showMedian: Scalars['Boolean']['output'];
  showSum: Scalars['Boolean']['output'];
  sortIndex: Scalars['Int']['output'];
  timeFormat?: Maybe<TimeFormat>;
  verticalAlignType: VerticalAlignType;
};

export type GridColumnPropertiesFilterInput = {
  and?: InputMaybe<Array<GridColumnPropertiesFilterInput>>;
  dateFormat?: InputMaybe<NullableOfDateFormatOperationFilterInput>;
  horizontalAlignType?: InputMaybe<HorizontalAlignTypeOperationFilterInput>;
  isNoWrap?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<GridColumnPropertiesFilterInput>>;
  showAverage?: InputMaybe<BooleanOperationFilterInput>;
  showMedian?: InputMaybe<BooleanOperationFilterInput>;
  showSum?: InputMaybe<BooleanOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  timeFormat?: InputMaybe<NullableOfTimeFormatOperationFilterInput>;
  verticalAlignType?: InputMaybe<VerticalAlignTypeOperationFilterInput>;
};

export type GridColumnPropertiesInput = {
  dateFormat?: InputMaybe<DateFormat>;
  horizontalAlignType: HorizontalAlignType;
  isNoWrap: Scalars['Boolean']['input'];
  showAverage: Scalars['Boolean']['input'];
  showMedian: Scalars['Boolean']['input'];
  showSum: Scalars['Boolean']['input'];
  sortIndex: Scalars['Int']['input'];
  timeFormat?: InputMaybe<TimeFormat>;
  verticalAlignType: VerticalAlignType;
};

export type HandInEmployeeDeviceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type HandInEmployeeDeviceInput = {
  date: Scalars['Date']['input'];
  deviceId: Scalars['Int']['input'];
};

export type HandInEmployeeDevicePayload = {
  __typename?: 'HandInEmployeeDevicePayload';
  errors?: Maybe<Array<HandInEmployeeDeviceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type HandOutEmployeeDeviceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type HandOutEmployeeDeviceInput = {
  date: Scalars['Date']['input'];
  deviceId: Scalars['Int']['input'];
};

export type HandOutEmployeeDevicePayload = {
  __typename?: 'HandOutEmployeeDevicePayload';
  errors?: Maybe<Array<HandOutEmployeeDeviceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type HasAccessDto = {
  __typename?: 'HasAccessDto';
  canRead: Scalars['Boolean']['output'];
  canWrite: Scalars['Boolean']['output'];
  rowId: Scalars['Int']['output'];
};

export enum HorizontalAlignType {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type HorizontalAlignTypeOperationFilterInput = {
  eq?: InputMaybe<HorizontalAlignType>;
  in?: InputMaybe<Array<HorizontalAlignType>>;
  neq?: InputMaybe<HorizontalAlignType>;
  nin?: InputMaybe<Array<HorizontalAlignType>>;
};

export type Idea = {
  __typename?: 'Idea';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  childIdeas: Array<Idea>;
  client: AxosClient;
  clientId: Scalars['Int']['output'];
  comments: Array<IdeaComment>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  creator: Employee;
  employeeId: Scalars['Int']['output'];
  entityId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  importanceScore: Scalars['Int']['output'];
  importanceVotes: Array<IdeaImportanceVote>;
  isDeleted: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  organisation: Organisation;
  organisationId: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  parentIdea?: Maybe<Idea>;
  statusType: IdeaStatusType;
  statusTypeId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type IdeaComment = {
  __typename?: 'IdeaComment';
  body: Scalars['String']['output'];
  client: AxosClient;
  clientId: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  creator: Employee;
  employeeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  ideaId: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  organisationId: Scalars['Int']['output'];
};

export type IdeaCommentInput = {
  body: Scalars['String']['input'];
};

export type IdeaImportanceType = {
  __typename?: 'IdeaImportanceType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type IdeaImportanceVote = {
  __typename?: 'IdeaImportanceVote';
  client: AxosClient;
  clientId: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  creator: Employee;
  employeeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  ideaId: Scalars['Int']['output'];
  ideaImportanceTypeId: Scalars['Int']['output'];
  importanceType: IdeaImportanceType;
  isDeleted: Scalars['Boolean']['output'];
  organisationId: Scalars['Int']['output'];
};

export type IdeaInput = {
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  importanceTypeId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type IdeaStatusType = {
  __typename?: 'IdeaStatusType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type ImpersonatedBy = {
  __typename?: 'ImpersonatedBy';
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type IndustryFilterInput = {
  and?: InputMaybe<Array<IndustryFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IndustryFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type IndustrySortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceMethodFilterInput = {
  and?: InputMaybe<Array<InvoiceMethodFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InvoiceMethodFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type InvoiceMethodSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type JobCandidateFilterInput = {
  addressLine1?: InputMaybe<StringOperationFilterInput>;
  addressLine2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<JobCandidateFilterInput>>;
  cellular?: InputMaybe<AxosPhoneNumberFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<AxosEmailFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  genderId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobId?: InputMaybe<IntOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  nationality?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobCandidateFilterInput>>;
  phone?: InputMaybe<AxosPhoneNumberFilterInput>;
  postalCode?: InputMaybe<StringOperationFilterInput>;
};

export type JobFilterInput = {
  and?: InputMaybe<Array<JobFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  deadline?: InputMaybe<DateOperationFilterInput>;
  department?: InputMaybe<OrganisationDepartmentFilterInput>;
  departmentId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jobCandidates?: InputMaybe<ListFilterInputTypeOfJobCandidateFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  office?: InputMaybe<OrganisationOfficeFilterInput>;
  officeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<JobFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  team?: InputMaybe<OrganisationTeamFilterInput>;
  teamId?: InputMaybe<IntOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type KeyValuePairOfStringAndObject = {
  __typename?: 'KeyValuePairOfStringAndObject';
  key: Scalars['String']['output'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  flag: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type LanguageDropdown = {
  __typename?: 'LanguageDropdown';
  isDeleted: Scalars['Boolean']['output'];
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  /** SortIndex to sort the dropdowns by. If not provided, the dropdowns will be sorted by Label */
  sortIndex?: Maybe<Scalars['Int']['output']>;
  /** Value that field should be bound to, usually an ID */
  value: Scalars['Int']['output'];
};

export type LanguageDropdownSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  /** Label to display in the dropdown. Localization is resolved in the backend */
  label?: InputMaybe<SortEnumType>;
  languageCode?: InputMaybe<SortEnumType>;
  /** SortIndex to sort the dropdowns by. If not provided, the dropdowns will be sorted by Label */
  sortIndex?: InputMaybe<SortEnumType>;
  /** Value that field should be bound to, usually an ID */
  value?: InputMaybe<SortEnumType>;
};

export type LanguageDto = {
  __typename?: 'LanguageDto';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type LanguageFilterInput = {
  and?: InputMaybe<Array<LanguageFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  flag?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LanguageFilterInput>>;
  organisations?: InputMaybe<ListFilterInputTypeOfOrganisationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type LanguageSortInput = {
  code?: InputMaybe<SortEnumType>;
  flag?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  nameResourceId?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type LinkFilterInput = {
  and?: InputMaybe<Array<LinkFilterInput>>;
  childDisplayText?: InputMaybe<StringOperationFilterInput>;
  childEntity?: InputMaybe<EntityFilterInput>;
  childEntityId?: InputMaybe<IntOperationFilterInput>;
  childRowId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<LinkFilterInput>>;
  parentDisplayText?: InputMaybe<StringOperationFilterInput>;
  parentEntity?: InputMaybe<EntityFilterInput>;
  parentEntityId?: InputMaybe<IntOperationFilterInput>;
  parentRowId?: InputMaybe<IntOperationFilterInput>;
};

export type ListFilterInputTypeOfAccountFilterInput = {
  all?: InputMaybe<AccountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountFilterInput>;
  some?: InputMaybe<AccountFilterInput>;
};

export type ListFilterInputTypeOfAccountPrimaryContactFilterInput = {
  all?: InputMaybe<AccountPrimaryContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountPrimaryContactFilterInput>;
  some?: InputMaybe<AccountPrimaryContactFilterInput>;
};

export type ListFilterInputTypeOfAccountPrimaryEmployeeFilterInput = {
  all?: InputMaybe<AccountPrimaryEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountPrimaryEmployeeFilterInput>;
  some?: InputMaybe<AccountPrimaryEmployeeFilterInput>;
};

export type ListFilterInputTypeOfAccountSocialHandleFilterInput = {
  all?: InputMaybe<AccountSocialHandleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountSocialHandleFilterInput>;
  some?: InputMaybe<AccountSocialHandleFilterInput>;
};

export type ListFilterInputTypeOfAccountTypeFilterInput = {
  all?: InputMaybe<AccountTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountTypeFilterInput>;
  some?: InputMaybe<AccountTypeFilterInput>;
};

export type ListFilterInputTypeOfAddressFilterInput = {
  all?: InputMaybe<AddressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AddressFilterInput>;
  some?: InputMaybe<AddressFilterInput>;
};

export type ListFilterInputTypeOfContactFilterInput = {
  all?: InputMaybe<ContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContactFilterInput>;
  some?: InputMaybe<ContactFilterInput>;
};

export type ListFilterInputTypeOfContactSocialHandleFilterInput = {
  all?: InputMaybe<ContactSocialHandleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContactSocialHandleFilterInput>;
  some?: InputMaybe<ContactSocialHandleFilterInput>;
};

export type ListFilterInputTypeOfCouncilFilterInput = {
  all?: InputMaybe<CouncilFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CouncilFilterInput>;
  some?: InputMaybe<CouncilFilterInput>;
};

export type ListFilterInputTypeOfCountyFilterInput = {
  all?: InputMaybe<CountyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CountyFilterInput>;
  some?: InputMaybe<CountyFilterInput>;
};

export type ListFilterInputTypeOfCustomFieldBooleanGridFilterFilterInput = {
  all?: InputMaybe<CustomFieldBooleanGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomFieldBooleanGridFilterFilterInput>;
  some?: InputMaybe<CustomFieldBooleanGridFilterFilterInput>;
};

export type ListFilterInputTypeOfCustomFieldDateGridFilterFilterInput = {
  all?: InputMaybe<CustomFieldDateGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomFieldDateGridFilterFilterInput>;
  some?: InputMaybe<CustomFieldDateGridFilterFilterInput>;
};

export type ListFilterInputTypeOfCustomFieldNumberGridFilterFilterInput = {
  all?: InputMaybe<CustomFieldNumberGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomFieldNumberGridFilterFilterInput>;
  some?: InputMaybe<CustomFieldNumberGridFilterFilterInput>;
};

export type ListFilterInputTypeOfCustomFieldStringGridFilterFilterInput = {
  all?: InputMaybe<CustomFieldStringGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomFieldStringGridFilterFilterInput>;
  some?: InputMaybe<CustomFieldStringGridFilterFilterInput>;
};

export type ListFilterInputTypeOfDateGridFilterFilterInput = {
  all?: InputMaybe<DateGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DateGridFilterFilterInput>;
  some?: InputMaybe<DateGridFilterFilterInput>;
};

export type ListFilterInputTypeOfDecisionArgumentFilterInput = {
  all?: InputMaybe<DecisionArgumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DecisionArgumentFilterInput>;
  some?: InputMaybe<DecisionArgumentFilterInput>;
};

export type ListFilterInputTypeOfDecisionEmployeeFilterInput = {
  all?: InputMaybe<DecisionEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DecisionEmployeeFilterInput>;
  some?: InputMaybe<DecisionEmployeeFilterInput>;
};

export type ListFilterInputTypeOfDecisionFilterInput = {
  all?: InputMaybe<DecisionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DecisionFilterInput>;
  some?: InputMaybe<DecisionFilterInput>;
};

export type ListFilterInputTypeOfDefaultDateGridFilterFilterInput = {
  all?: InputMaybe<DefaultDateGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DefaultDateGridFilterFilterInput>;
  some?: InputMaybe<DefaultDateGridFilterFilterInput>;
};

export type ListFilterInputTypeOfDefaultNumberGridFilterFilterInput = {
  all?: InputMaybe<DefaultNumberGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DefaultNumberGridFilterFilterInput>;
  some?: InputMaybe<DefaultNumberGridFilterFilterInput>;
};

export type ListFilterInputTypeOfDefaultSelectGridFilterFilterInput = {
  all?: InputMaybe<DefaultSelectGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DefaultSelectGridFilterFilterInput>;
  some?: InputMaybe<DefaultSelectGridFilterFilterInput>;
};

export type ListFilterInputTypeOfDefaultStringGridFilterFilterInput = {
  all?: InputMaybe<DefaultStringGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DefaultStringGridFilterFilterInput>;
  some?: InputMaybe<DefaultStringGridFilterFilterInput>;
};

export type ListFilterInputTypeOfEmployeeAddressFilterInput = {
  all?: InputMaybe<EmployeeAddressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EmployeeAddressFilterInput>;
  some?: InputMaybe<EmployeeAddressFilterInput>;
};

export type ListFilterInputTypeOfEmployeeFilterInput = {
  all?: InputMaybe<EmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EmployeeFilterInput>;
  some?: InputMaybe<EmployeeFilterInput>;
};

export type ListFilterInputTypeOfEntityCustomFieldValueFilterInput = {
  all?: InputMaybe<EntityCustomFieldValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityCustomFieldValueFilterInput>;
  some?: InputMaybe<EntityCustomFieldValueFilterInput>;
};

export type ListFilterInputTypeOfEntityDefaultGridColumnFilterInput = {
  all?: InputMaybe<EntityDefaultGridColumnFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityDefaultGridColumnFilterInput>;
  some?: InputMaybe<EntityDefaultGridColumnFilterInput>;
};

export type ListFilterInputTypeOfEntityDefaultGridFilterInput = {
  all?: InputMaybe<EntityDefaultGridFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityDefaultGridFilterInput>;
  some?: InputMaybe<EntityDefaultGridFilterInput>;
};

export type ListFilterInputTypeOfEntityGridColumnConfigFilterInput = {
  all?: InputMaybe<EntityGridColumnConfigFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityGridColumnConfigFilterInput>;
  some?: InputMaybe<EntityGridColumnConfigFilterInput>;
};

export type ListFilterInputTypeOfEntityGridColumnFilterInput = {
  all?: InputMaybe<EntityGridColumnFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityGridColumnFilterInput>;
  some?: InputMaybe<EntityGridColumnFilterInput>;
};

export type ListFilterInputTypeOfEntityGridCustomFieldColumnFilterInput = {
  all?: InputMaybe<EntityGridCustomFieldColumnFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityGridCustomFieldColumnFilterInput>;
  some?: InputMaybe<EntityGridCustomFieldColumnFilterInput>;
};

export type ListFilterInputTypeOfEntityGridFilterInput = {
  all?: InputMaybe<EntityGridFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityGridFilterInput>;
  some?: InputMaybe<EntityGridFilterInput>;
};

export type ListFilterInputTypeOfEntityGridSubGridFilterInput = {
  all?: InputMaybe<EntityGridSubGridFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EntityGridSubGridFilterInput>;
  some?: InputMaybe<EntityGridSubGridFilterInput>;
};

export type ListFilterInputTypeOfJobCandidateFilterInput = {
  all?: InputMaybe<JobCandidateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobCandidateFilterInput>;
  some?: InputMaybe<JobCandidateFilterInput>;
};

export type ListFilterInputTypeOfJobFilterInput = {
  all?: InputMaybe<JobFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobFilterInput>;
  some?: InputMaybe<JobFilterInput>;
};

export type ListFilterInputTypeOfLinkFilterInput = {
  all?: InputMaybe<LinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<LinkFilterInput>;
  some?: InputMaybe<LinkFilterInput>;
};

export type ListFilterInputTypeOfModuleFilterInput = {
  all?: InputMaybe<ModuleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ModuleFilterInput>;
  some?: InputMaybe<ModuleFilterInput>;
};

export type ListFilterInputTypeOfNumberGridFilterFilterInput = {
  all?: InputMaybe<NumberGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NumberGridFilterFilterInput>;
  some?: InputMaybe<NumberGridFilterFilterInput>;
};

export type ListFilterInputTypeOfOrganisationAddressFilterInput = {
  all?: InputMaybe<OrganisationAddressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationAddressFilterInput>;
  some?: InputMaybe<OrganisationAddressFilterInput>;
};

export type ListFilterInputTypeOfOrganisationBenefitEmployeeFilterInput = {
  all?: InputMaybe<OrganisationBenefitEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationBenefitEmployeeFilterInput>;
  some?: InputMaybe<OrganisationBenefitEmployeeFilterInput>;
};

export type ListFilterInputTypeOfOrganisationBenefitFilterInput = {
  all?: InputMaybe<OrganisationBenefitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationBenefitFilterInput>;
  some?: InputMaybe<OrganisationBenefitFilterInput>;
};

export type ListFilterInputTypeOfOrganisationDepartmentFilterInput = {
  all?: InputMaybe<OrganisationDepartmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationDepartmentFilterInput>;
  some?: InputMaybe<OrganisationDepartmentFilterInput>;
};

export type ListFilterInputTypeOfOrganisationFilterInput = {
  all?: InputMaybe<OrganisationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationFilterInput>;
  some?: InputMaybe<OrganisationFilterInput>;
};

export type ListFilterInputTypeOfOrganisationInsuranceEmployeeFilterInput = {
  all?: InputMaybe<OrganisationInsuranceEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationInsuranceEmployeeFilterInput>;
  some?: InputMaybe<OrganisationInsuranceEmployeeFilterInput>;
};

export type ListFilterInputTypeOfOrganisationInsuranceFilterInput = {
  all?: InputMaybe<OrganisationInsuranceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationInsuranceFilterInput>;
  some?: InputMaybe<OrganisationInsuranceFilterInput>;
};

export type ListFilterInputTypeOfOrganisationNumberingDefaultFilterInput = {
  all?: InputMaybe<OrganisationNumberingDefaultFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationNumberingDefaultFilterInput>;
  some?: InputMaybe<OrganisationNumberingDefaultFilterInput>;
};

export type ListFilterInputTypeOfOrganisationNumberingFilterInput = {
  all?: InputMaybe<OrganisationNumberingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationNumberingFilterInput>;
  some?: InputMaybe<OrganisationNumberingFilterInput>;
};

export type ListFilterInputTypeOfOrganisationOfficeEmployeeFilterInput = {
  all?: InputMaybe<OrganisationOfficeEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationOfficeEmployeeFilterInput>;
  some?: InputMaybe<OrganisationOfficeEmployeeFilterInput>;
};

export type ListFilterInputTypeOfOrganisationOfficeFilterInput = {
  all?: InputMaybe<OrganisationOfficeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationOfficeFilterInput>;
  some?: InputMaybe<OrganisationOfficeFilterInput>;
};

export type ListFilterInputTypeOfOrganisationResponsibilityFilterInput = {
  all?: InputMaybe<OrganisationResponsibilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationResponsibilityFilterInput>;
  some?: InputMaybe<OrganisationResponsibilityFilterInput>;
};

export type ListFilterInputTypeOfOrganisationResponsibilityTaskFilterInput = {
  all?: InputMaybe<OrganisationResponsibilityTaskFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationResponsibilityTaskFilterInput>;
  some?: InputMaybe<OrganisationResponsibilityTaskFilterInput>;
};

export type ListFilterInputTypeOfOrganisationTeamEmployeeFilterInput = {
  all?: InputMaybe<OrganisationTeamEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationTeamEmployeeFilterInput>;
  some?: InputMaybe<OrganisationTeamEmployeeFilterInput>;
};

export type ListFilterInputTypeOfOrganisationTeamFilterInput = {
  all?: InputMaybe<OrganisationTeamFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganisationTeamFilterInput>;
  some?: InputMaybe<OrganisationTeamFilterInput>;
};

export type ListFilterInputTypeOfPositionFilterInput = {
  all?: InputMaybe<PositionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PositionFilterInput>;
  some?: InputMaybe<PositionFilterInput>;
};

export type ListFilterInputTypeOfSeedingModuleFilterInput = {
  all?: InputMaybe<SeedingModuleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SeedingModuleFilterInput>;
  some?: InputMaybe<SeedingModuleFilterInput>;
};

export type ListFilterInputTypeOfSelectGridFilterFilterInput = {
  all?: InputMaybe<SelectGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SelectGridFilterFilterInput>;
  some?: InputMaybe<SelectGridFilterFilterInput>;
};

export type ListFilterInputTypeOfStringGridFilterFilterInput = {
  all?: InputMaybe<StringGridFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringGridFilterFilterInput>;
  some?: InputMaybe<StringGridFilterFilterInput>;
};

export type ListFilterInputTypeOfTemplateFilterInput = {
  all?: InputMaybe<TemplateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TemplateFilterInput>;
  some?: InputMaybe<TemplateFilterInput>;
};

export type ListFilterInputTypeOfTemplateRevisionFilterInput = {
  all?: InputMaybe<TemplateRevisionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TemplateRevisionFilterInput>;
  some?: InputMaybe<TemplateRevisionFilterInput>;
};

export type ListFilterInputTypeOfToDoEmployeeFilterInput = {
  all?: InputMaybe<ToDoEmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoEmployeeFilterInput>;
  some?: InputMaybe<ToDoEmployeeFilterInput>;
};

export type ListFilterInputTypeOfToDoFilterInput = {
  all?: InputMaybe<ToDoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoFilterInput>;
  some?: InputMaybe<ToDoFilterInput>;
};

export type ListFilterInputTypeOfToDoRecurringExceptionFilterInput = {
  all?: InputMaybe<ToDoRecurringExceptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoRecurringExceptionFilterInput>;
  some?: InputMaybe<ToDoRecurringExceptionFilterInput>;
};

export type ListFilterInputTypeOfToDoRecurringPatternFilterInput = {
  all?: InputMaybe<ToDoRecurringPatternFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoRecurringPatternFilterInput>;
  some?: InputMaybe<ToDoRecurringPatternFilterInput>;
};

export type ListFilterInputTypeOfToDoStatusFilterInput = {
  all?: InputMaybe<ToDoStatusFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoStatusFilterInput>;
  some?: InputMaybe<ToDoStatusFilterInput>;
};

export type ListFilterInputTypeOfWorkflowTemplateFilterInput = {
  all?: InputMaybe<WorkflowTemplateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkflowTemplateFilterInput>;
  some?: InputMaybe<WorkflowTemplateFilterInput>;
};

export type ListFilterInputTypeOfWorkflowTemplateItemFilterInput = {
  all?: InputMaybe<WorkflowTemplateItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkflowTemplateItemFilterInput>;
  some?: InputMaybe<WorkflowTemplateItemFilterInput>;
};

export type ListFilterInputTypeOfZipCodesFilterInput = {
  all?: InputMaybe<ZipCodesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ZipCodesFilterInput>;
  some?: InputMaybe<ZipCodesFilterInput>;
};

export type MaritalStatusType = {
  __typename?: 'MaritalStatusType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type MaritalStatusTypeFilterInput = {
  and?: InputMaybe<Array<MaritalStatusTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MaritalStatusTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type MaritalStatusTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type MarkNotificationsAsReadError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type MarkNotificationsAsReadPayload = {
  __typename?: 'MarkNotificationsAsReadPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<MarkNotificationsAsReadError>>;
};

/** A segment of a collection. */
export type MasterModulesCollectionSegment = {
  __typename?: 'MasterModulesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SeedingModule>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type MetricTypeFilterInput = {
  and?: InputMaybe<Array<MetricTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MetricTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type MetricTypeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type Module = {
  __typename?: 'Module';
  childModules: Array<Module>;
  deactivatable: Scalars['Boolean']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActivated: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  parentModule?: Maybe<Module>;
  parentModuleId?: Maybe<Scalars['Int']['output']>;
  sortIndex: Scalars['Int']['output'];
};

export type ModuleFilterInput = {
  and?: InputMaybe<Array<ModuleFilterInput>>;
  childModules?: InputMaybe<ListFilterInputTypeOfModuleFilterInput>;
  deactivatable?: InputMaybe<BooleanOperationFilterInput>;
  icon?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActivated?: InputMaybe<BooleanOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ModuleFilterInput>>;
  parentModule?: InputMaybe<ModuleFilterInput>;
  parentModuleId?: InputMaybe<IntOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type ModuleInput = {
  childModules: Array<Scalars['Int']['input']>;
  entityId?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  nameResourceId: Scalars['String']['input'];
  sortIndex: Scalars['Int']['input'];
};

export type ModuleSortInput = {
  deactivatable?: InputMaybe<SortEnumType>;
  icon?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActivated?: InputMaybe<SortEnumType>;
  isEnabled?: InputMaybe<SortEnumType>;
  nameResourceId?: InputMaybe<SortEnumType>;
  parentModule?: InputMaybe<ModuleSortInput>;
  parentModuleId?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type ModuleWithAccessDto = {
  __typename?: 'ModuleWithAccessDto';
  access: EntityRowAccessDto;
  module: Module;
};

/** A segment of a collection. */
export type ModulesCollectionSegment = {
  __typename?: 'ModulesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Module>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateEmployee: ActivateEmployeePayload;
  addDepartmentAccessToEntityRow: AddDepartmentAccessToEntityRowPayload;
  addEmployeeAccessToEntityRow: AddEmployeeAccessToEntityRowPayload;
  addEmployeeToBenefit: AddEmployeeToBenefitPayload;
  addEmployeeToInsurance: AddEmployeeToInsurancePayload;
  addEmployeeToOffice: AddEmployeeToOfficePayload;
  addEmployeeToTeam: AddEmployeeToTeamPayload;
  addOfficeAccessToEntityRow: AddOfficeAccessToEntityRowPayload;
  addOrganisationAccessToEntityRow: AddOrganisationAccessToEntityRowPayload;
  addTeamAccessToEntityRow: AddTeamAccessToEntityRowPayload;
  applyForEmployeeAbsence: ApplyForEmployeeAbsencePayload;
  approveAllEmployeeAbsenceBalances: ApproveAllEmployeeAbsenceBalancesPayload;
  clearAccessCache: ClearAccessCachePayload;
  /** Create a new address. */
  createAddress: CreateAddressPayload;
  createBenefit: CreateBenefitPayload;
  createCertificateType: CreateCertificateTypePayload;
  createClient: CreateClientPayload;
  createCompetenceType: CreateCompetenceTypePayload;
  createCustomFieldForEntity: CreateCustomFieldForEntityPayload;
  createDepartment: CreateDepartmentPayload;
  /** Creates a new employee */
  createEmployee: CreateEmployeePayload;
  createEmployeeAbsence: CreateEmployeeAbsencePayload;
  /** Add an address to the given employee. If the employee already has an address of the same type, a validation error will be thrown. */
  createEmployeeAddress: CreateEmployeeAddressPayload;
  createEmployeeAllergy: CreateEmployeeAllergyPayload;
  createEmployeeCertificate: CreateEmployeeCertificatePayload;
  createEmployeeCompetence: CreateEmployeeCompetencePayload;
  createEmployeeDevice: CreateEmployeeDevicePayload;
  createEmployeeEducation: CreateEmployeeEducationPayload;
  createEmployeeEmployment: CreateEmployeeEmploymentPayload;
  createEmployeeFamily: CreateEmployeeFamilyPayload;
  createIdea: CreateIdeaPayload;
  createIdeaComment: CreateIdeaCommentPayload;
  createInsurance: CreateInsurancePayload;
  createMasterModule: CreateMasterModulePayload;
  createOffice: CreateOfficePayload;
  createOkrObjective: CreateOkrObjectivePayload;
  /** Create a new organisation */
  createOrganisation: CreateOrganisationPayload;
  /** Add an address to the given organisation. If the organisation already has an address of the same type, a validation error will be thrown. */
  createOrganisationAddress: CreateOrganisationAddressPayload;
  createPosition: CreatePositionPayload;
  createPowerGrid: CreatePowerGridPayload;
  createProduct: CreateProductPayload;
  /** Create a new responsibility for the given organisation */
  createResponsibility: CreateResponsibilityPayload;
  /** Create a new Task for Organisation Responsibility. */
  createResponsibilityTask: CreateResponsibilityTaskPayload;
  createTeam: CreateTeamPayload;
  createTodo: CreateTodoPayload;
  deactivateEmployee: DeactivateEmployeePayload;
  deleteAccessToEntityRow: DeleteAccessToEntityRowPayload;
  /** Delete the given address */
  deleteAddress: DeleteAddressPayload;
  deleteBenefit: DeleteBenefitPayload;
  deleteCertificateType: DeleteCertificateTypePayload;
  deleteCompetenceType: DeleteCompetenceTypePayload;
  deleteCustomFieldForEntity: DeleteCustomFieldForEntityPayload;
  deleteDepartment: DeleteDepartmentPayload;
  deleteEmployeeAbsence: DeleteEmployeeAbsencePayload;
  /** Delete an address from an employee. Can not delete the postaddress. */
  deleteEmployeeAddress: DeleteEmployeeAddressPayload;
  deleteEmployeeAllergy: DeleteEmployeeAllergyPayload;
  deleteEmployeeCertificate: DeleteEmployeeCertificatePayload;
  deleteEmployeeCompetence: DeleteEmployeeCompetencePayload;
  deleteEmployeeDevice: DeleteEmployeeDevicePayload;
  deleteEmployeeEducation: DeleteEmployeeEducationPayload;
  deleteEmployeeEmployment: DeleteEmployeeEmploymentPayload;
  deleteEmployeeFamily: DeleteEmployeeFamilyPayload;
  deleteInsurance: DeleteInsurancePayload;
  deleteMasterModule: DeleteMasterModulePayload;
  deleteOffice: DeleteOfficePayload;
  deleteOkrObjective: DeleteOkrObjectivePayload;
  /** Delete organisation by id */
  deleteOrganisation: DeleteOrganisationPayload;
  /** Delete an address from an organisation. Can not delete the postaddress. */
  deleteOrganisationAddress: DeleteOrganisationAddressPayload;
  deletePosition: DeletePositionPayload;
  deletePowerGrid: DeletePowerGridPayload;
  /** Delete a responsibility for Organisation Responsibility. */
  deleteResponsibility: DeleteResponsibilityPayload;
  deleteTeam: DeleteTeamPayload;
  deleteTodo: DeleteTodoPayload;
  handInEmployeeDevice: HandInEmployeeDevicePayload;
  handOutEmployeeDevice: HandOutEmployeeDevicePayload;
  markNotificationsAsRead: MarkNotificationsAsReadPayload;
  readEntityEvent: ReadEntityEventPayload;
  removeEmployeeFromBenefit: RemoveEmployeeFromBenefitPayload;
  removeEmployeeFromInsurance: RemoveEmployeeFromInsurancePayload;
  removeEmployeeFromOffice: RemoveEmployeeFromOfficePayload;
  removeEmployeeFromTeam: RemoveEmployeeFromTeamPayload;
  seedCountryDataToClientDb: SeedCountryDataToClientDbPayload;
  seedNewClient: SeedNewClientPayload;
  seedNorwayGeoDataToClientDb: SeedNorwayGeoDataToClientDbPayload;
  seedOneTimeSeedToClient: SeedOneTimeSeedToClientPayload;
  setCurrentEmployment: SetCurrentEmploymentPayload;
  setGlobalAccessToEntityRow: SetGlobalAccessToEntityRowPayload;
  toggleModuleEnabled: ToggleModuleEnabledPayload;
  updateAccessToEntityRow: UpdateAccessToEntityRowPayload;
  /** Update an existing address */
  updateAddress: UpdateAddressPayload;
  updateBenefit: UpdateBenefitPayload;
  updateCertificateType: UpdateCertificateTypePayload;
  updateCompetenceType: UpdateCompetenceTypePayload;
  updateCustomFieldForEntity: UpdateCustomFieldForEntityPayload;
  updateDepartment: UpdateDepartmentPayload;
  updateEmployeeAbout: UpdateEmployeeAboutPayload;
  updateEmployeeAbsence: UpdateEmployeeAbsencePayload;
  updateEmployeeAbsenceBalance: UpdateEmployeeAbsenceBalancePayload;
  updateEmployeeAbsenceDescription: UpdateEmployeeAbsenceDescriptionPayload;
  updateEmployeeAbsenceEndDate: UpdateEmployeeAbsenceEndDatePayload;
  updateEmployeeAbsenceStartDate: UpdateEmployeeAbsenceStartDatePayload;
  updateEmployeeAbsenceStatus: UpdateEmployeeAbsenceStatusPayload;
  updateEmployeeAbsenceTypeId: UpdateEmployeeAbsenceTypeIdPayload;
  /**
   * Update an address for the given employee. If the type is changed to an address that
   *             the Employee already has configured, a validation error is thrown.
   */
  updateEmployeeAddress: UpdateEmployeeAddressPayload;
  updateEmployeeAdmin: UpdateEmployeeAdminPayload;
  updateEmployeeAllergy: UpdateEmployeeAllergyPayload;
  updateEmployeeBankAccountNumber: UpdateEmployeeBankAccountNumberPayload;
  updateEmployeeBirthDate: UpdateEmployeeBirthDatePayload;
  updateEmployeeCellular: UpdateEmployeeCellularPayload;
  updateEmployeeCertificate: UpdateEmployeeCertificatePayload;
  updateEmployeeCompetence: UpdateEmployeeCompetencePayload;
  updateEmployeeDefaultAddress: UpdateEmployeeDefaultAddressPayload;
  updateEmployeeDefaultProduct: UpdateEmployeeDefaultProductPayload;
  updateEmployeeDevice: UpdateEmployeeDevicePayload;
  updateEmployeeEducation: UpdateEmployeeEducationPayload;
  updateEmployeeEmail: UpdateEmployeeEmailPayload;
  updateEmployeeEmployment: UpdateEmployeeEmploymentPayload;
  updateEmployeeEmploymentDescription: UpdateEmployeeEmploymentDescriptionPayload;
  updateEmployeeEmploymentEmploymentRatePercent: UpdateEmployeeEmploymentEmploymentRatePercentPayload;
  updateEmployeeEmploymentEmploymentTypeId: UpdateEmployeeEmploymentEmploymentTypeIdPayload;
  updateEmployeeEmploymentEndDate: UpdateEmployeeEmploymentEndDatePayload;
  updateEmployeeEmploymentPositionId: UpdateEmployeeEmploymentPositionIdPayload;
  updateEmployeeEmploymentStartDate: UpdateEmployeeEmploymentStartDatePayload;
  updateEmployeeEmploymentWageTypeId: UpdateEmployeeEmploymentWageTypeIdPayload;
  updateEmployeeEmploymentWageValue: UpdateEmployeeEmploymentWageValuePayload;
  updateEmployeeExpenseManagerId: UpdateEmployeeExpenseManagerIdPayload;
  updateEmployeeExternalId: UpdateEmployeeExternalIdPayload;
  updateEmployeeExternalSystemId: UpdateEmployeeExternalSystemIdPayload;
  updateEmployeeFamily: UpdateEmployeeFamilyPayload;
  updateEmployeeFirstName: UpdateEmployeeFirstNamePayload;
  updateEmployeeGenderId: UpdateEmployeeGenderIdPayload;
  updateEmployeeInitials: UpdateEmployeeInitialsPayload;
  updateEmployeeJobDescription: UpdateEmployeeJobDescriptionPayload;
  updateEmployeeKmHomeWork: UpdateEmployeeKmHomeWorkPayload;
  updateEmployeeLanguageId: UpdateEmployeeLanguageIdPayload;
  updateEmployeeLastName: UpdateEmployeeLastNamePayload;
  updateEmployeeManagerId: UpdateEmployeeManagerIdPayload;
  updateEmployeeMaritalStatusTypeId: UpdateEmployeeMaritalStatusTypeIdPayload;
  updateEmployeeNationalityId: UpdateEmployeeNationalityIdPayload;
  updateEmployeeOrganisationDepartmentId: UpdateEmployeeOrganisationDepartmentIdPayload;
  updateEmployeeOrganisationId: UpdateEmployeeOrganisationIdPayload;
  updateEmployeePhone: UpdateEmployeePhonePayload;
  updateEmployeeProfilePicture: UpdateEmployeeProfilePicturePayload;
  updateEmployeeSizeFeetId: UpdateEmployeeSizeFeetIdPayload;
  updateEmployeeSizeLegsId: UpdateEmployeeSizeLegsIdPayload;
  updateEmployeeSizeTorsoId: UpdateEmployeeSizeTorsoIdPayload;
  updateEmployeeSizeWaistId: UpdateEmployeeSizeWaistIdPayload;
  updateEmployeeSocialSecurityNumber: UpdateEmployeeSocialSecurityNumberPayload;
  updateEmployeeStartDate: UpdateEmployeeStartDatePayload;
  updateEmployeeTimeSheetManagerId: UpdateEmployeeTimeSheetManagerIdPayload;
  updateEmployeeTrialMonths: UpdateEmployeeTrialMonthsPayload;
  updateEntityEventSubscription: UpdateEntityEventSubscriptionPayload;
  updateIdeaBody: UpdateIdeaBodyPayload;
  updateIdeaParentId: UpdateIdeaParentIdPayload;
  updateIdeaStatusTypeId: UpdateIdeaStatusTypeIdPayload;
  updateIdeaTitle: UpdateIdeaTitlePayload;
  updateInsurance: UpdateInsurancePayload;
  /** Activate or deactivate a language */
  updateLanguageIsActive: UpdateLanguageIsActivePayload;
  updateMasterModule: UpdateMasterModulePayload;
  updateOffice: UpdateOfficePayload;
  updateOkrObjective: UpdateOkrObjectivePayload;
  updateOrganisationAccountId: UpdateOrganisationAccountIdPayload;
  /**
   * Update an address for the given organisation. If the type is changed to an address that
   *             the Organisation already has configured, a validation error is thrown.
   */
  updateOrganisationAddress: UpdateOrganisationAddressPayload;
  /** Update the fee calculation rule of an organisation. This will enable or disable fee calculation threshold */
  updateOrganisationAdministrationFeeCalculationRule: UpdateOrganisationAdministrationFeeCalculationRulePayload;
  /** Update the administration fee calculation rule threshold of an organisation. This is enabled if the administration fee calculation rule is set to Threshold */
  updateOrganisationAdministrationFeeCalculationRuleThreshold: UpdateOrganisationAdministrationFeeCalculationRuleThresholdPayload;
  /** Update the administration fee product ID of an organisation */
  updateOrganisationAdministrationFeeProductId: UpdateOrganisationAdministrationFeeProductIdPayload;
  /** Update the email of an organisation. Must be valid email format */
  updateOrganisationEmail: UpdateOrganisationEmailPayload;
  updateOrganisationEmailFooterColor: UpdateOrganisationEmailFooterColorPayload;
  updateOrganisationEmailHeaderColor: UpdateOrganisationEmailHeaderColorPayload;
  updateOrganisationExternalErpId: UpdateOrganisationExternalErpIdPayload;
  updateOrganisationLanguageId: UpdateOrganisationLanguageIdPayload;
  /** Update the legal name of an organisation */
  updateOrganisationLegalName: UpdateOrganisationLegalNamePayload;
  /** Update the organisation number of an organisation */
  updateOrganisationLegalNo: UpdateOrganisationLegalNoPayload;
  updateOrganisationLogo: UpdateOrganisationLogoPayload;
  updateOrganisationManagerId: UpdateOrganisationManagerIdPayload;
  /** Update the name of an organisation */
  updateOrganisationName: UpdateOrganisationNamePayload;
  /**
   * Update the prefix and startnumber for a numbering belonging to an organisation. Returns current number.
   *         Prefix can be null or max 3 characters long.
   */
  updateOrganisationNumbering: UpdateOrganisationNumberingPayload;
  updateOrganisationPayTermId: UpdateOrganisationPayTermIdPayload;
  /** Update the phone number of an organisation */
  updateOrganisationPhone: UpdateOrganisationPhonePayload;
  updateOrganisationPrimaryColorCode: UpdateOrganisationPrimaryColorCodePayload;
  updateOrganisationSecondaryColorCode: UpdateOrganisationSecondaryColorCodePayload;
  /** Update the default name of SMS that are sent form the organisation */
  updateOrganisationSmsSenderName: UpdateOrganisationSmsSenderNamePayload;
  updateOrganisationSymbol: UpdateOrganisationSymbolPayload;
  updateOrganisationTemplateLogo: UpdateOrganisationTemplateLogoPayload;
  updatePosition: UpdatePositionPayload;
  updatePowerGrid: UpdatePowerGridPayload;
  /** Set name and description of Organisation Responsibility. */
  updateResponsibility: UpdateResponsibilityPayload;
  /** Create a new Task for Organisation Responsibility. */
  updateResponsibilityTask: UpdateResponsibilityTaskPayload;
  updateTeam: UpdateTeamPayload;
  updateTodo: UpdateTodoPayload;
};


export type MutationActivateEmployeeArgs = {
  input: ActivateEmployeeInput;
};


export type MutationAddDepartmentAccessToEntityRowArgs = {
  input: AddDepartmentAccessToEntityRowInput;
};


export type MutationAddEmployeeAccessToEntityRowArgs = {
  input: AddEmployeeAccessToEntityRowInput;
};


export type MutationAddEmployeeToBenefitArgs = {
  input: AddEmployeeToBenefitInput;
};


export type MutationAddEmployeeToInsuranceArgs = {
  input: AddEmployeeToInsuranceInput;
};


export type MutationAddEmployeeToOfficeArgs = {
  input: AddEmployeeToOfficeInput;
};


export type MutationAddEmployeeToTeamArgs = {
  input: AddEmployeeToTeamInput;
};


export type MutationAddOfficeAccessToEntityRowArgs = {
  input: AddOfficeAccessToEntityRowInput;
};


export type MutationAddOrganisationAccessToEntityRowArgs = {
  input: AddOrganisationAccessToEntityRowInput;
};


export type MutationAddTeamAccessToEntityRowArgs = {
  input: AddTeamAccessToEntityRowInput;
};


export type MutationApplyForEmployeeAbsenceArgs = {
  input: ApplyForEmployeeAbsenceInput;
};


export type MutationApproveAllEmployeeAbsenceBalancesArgs = {
  input: ApproveAllEmployeeAbsenceBalancesInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateBenefitArgs = {
  input: CreateBenefitInput;
};


export type MutationCreateCertificateTypeArgs = {
  input: CreateCertificateTypeInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateCompetenceTypeArgs = {
  input: CreateCompetenceTypeInput;
};


export type MutationCreateCustomFieldForEntityArgs = {
  input: CreateCustomFieldForEntityInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateEmployeeArgs = {
  createEmployeeInput: CreateEmployeeInput;
};


export type MutationCreateEmployeeAbsenceArgs = {
  input: CreateEmployeeAbsenceInput;
};


export type MutationCreateEmployeeAddressArgs = {
  input: CreateEmployeeAddressInput;
};


export type MutationCreateEmployeeAllergyArgs = {
  input: CreateEmployeeAllergyInput;
};


export type MutationCreateEmployeeCertificateArgs = {
  input: CreateEmployeeCertificateInput;
};


export type MutationCreateEmployeeCompetenceArgs = {
  input: CreateEmployeeCompetenceInput;
};


export type MutationCreateEmployeeDeviceArgs = {
  input: CreateEmployeeDeviceInput;
};


export type MutationCreateEmployeeEducationArgs = {
  input: CreateEmployeeEducationInput;
};


export type MutationCreateEmployeeEmploymentArgs = {
  input: CreateEmployeeEmploymentInput;
};


export type MutationCreateEmployeeFamilyArgs = {
  input: CreateEmployeeFamilyInput;
};


export type MutationCreateIdeaArgs = {
  input: CreateIdeaInput;
};


export type MutationCreateIdeaCommentArgs = {
  input: CreateIdeaCommentInput;
};


export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};


export type MutationCreateMasterModuleArgs = {
  input: CreateMasterModuleInput;
};


export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};


export type MutationCreateOkrObjectiveArgs = {
  input: CreateOkrObjectiveInput;
};


export type MutationCreateOrganisationArgs = {
  createOrganisationInput: CreateOrganisationInput;
};


export type MutationCreateOrganisationAddressArgs = {
  input: CreateOrganisationAddressInput;
};


export type MutationCreatePositionArgs = {
  input: CreatePositionInput;
};


export type MutationCreatePowerGridArgs = {
  input: CreatePowerGridInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateResponsibilityArgs = {
  input: CreateResponsibilityInput;
};


export type MutationCreateResponsibilityTaskArgs = {
  input: CreateResponsibilityTaskInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTodoArgs = {
  input: CreateTodoInput;
};


export type MutationDeactivateEmployeeArgs = {
  input: DeactivateEmployeeInput;
};


export type MutationDeleteAccessToEntityRowArgs = {
  input: DeleteAccessToEntityRowInput;
};


export type MutationDeleteAddressArgs = {
  input: DeleteAddressInput;
};


export type MutationDeleteBenefitArgs = {
  input: DeleteBenefitInput;
};


export type MutationDeleteCertificateTypeArgs = {
  input: DeleteCertificateTypeInput;
};


export type MutationDeleteCompetenceTypeArgs = {
  input: DeleteCompetenceTypeInput;
};


export type MutationDeleteCustomFieldForEntityArgs = {
  input: DeleteCustomFieldForEntityInput;
};


export type MutationDeleteDepartmentArgs = {
  input: DeleteDepartmentInput;
};


export type MutationDeleteEmployeeAbsenceArgs = {
  input: DeleteEmployeeAbsenceInput;
};


export type MutationDeleteEmployeeAddressArgs = {
  input: DeleteEmployeeAddressInput;
};


export type MutationDeleteEmployeeAllergyArgs = {
  input: DeleteEmployeeAllergyInput;
};


export type MutationDeleteEmployeeCertificateArgs = {
  input: DeleteEmployeeCertificateInput;
};


export type MutationDeleteEmployeeCompetenceArgs = {
  input: DeleteEmployeeCompetenceInput;
};


export type MutationDeleteEmployeeDeviceArgs = {
  input: DeleteEmployeeDeviceInput;
};


export type MutationDeleteEmployeeEducationArgs = {
  input: DeleteEmployeeEducationInput;
};


export type MutationDeleteEmployeeEmploymentArgs = {
  input: DeleteEmployeeEmploymentInput;
};


export type MutationDeleteEmployeeFamilyArgs = {
  input: DeleteEmployeeFamilyInput;
};


export type MutationDeleteInsuranceArgs = {
  input: DeleteInsuranceInput;
};


export type MutationDeleteMasterModuleArgs = {
  input: DeleteMasterModuleInput;
};


export type MutationDeleteOfficeArgs = {
  input: DeleteOfficeInput;
};


export type MutationDeleteOkrObjectiveArgs = {
  input: DeleteOkrObjectiveInput;
};


export type MutationDeleteOrganisationArgs = {
  input: DeleteOrganisationInput;
};


export type MutationDeleteOrganisationAddressArgs = {
  input: DeleteOrganisationAddressInput;
};


export type MutationDeletePositionArgs = {
  input: DeletePositionInput;
};


export type MutationDeletePowerGridArgs = {
  input: DeletePowerGridInput;
};


export type MutationDeleteResponsibilityArgs = {
  input: DeleteResponsibilityInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteTodoArgs = {
  input: DeleteTodoInput;
};


export type MutationHandInEmployeeDeviceArgs = {
  input: HandInEmployeeDeviceInput;
};


export type MutationHandOutEmployeeDeviceArgs = {
  input: HandOutEmployeeDeviceInput;
};


export type MutationReadEntityEventArgs = {
  input: ReadEntityEventInput;
};


export type MutationRemoveEmployeeFromBenefitArgs = {
  input: RemoveEmployeeFromBenefitInput;
};


export type MutationRemoveEmployeeFromInsuranceArgs = {
  input: RemoveEmployeeFromInsuranceInput;
};


export type MutationRemoveEmployeeFromOfficeArgs = {
  input: RemoveEmployeeFromOfficeInput;
};


export type MutationRemoveEmployeeFromTeamArgs = {
  input: RemoveEmployeeFromTeamInput;
};


export type MutationSetCurrentEmploymentArgs = {
  input: SetCurrentEmploymentInput;
};


export type MutationSetGlobalAccessToEntityRowArgs = {
  input: SetGlobalAccessToEntityRowInput;
};


export type MutationToggleModuleEnabledArgs = {
  input: ToggleModuleEnabledInput;
};


export type MutationUpdateAccessToEntityRowArgs = {
  input: UpdateAccessToEntityRowInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateBenefitArgs = {
  input: UpdateBenefitInput;
};


export type MutationUpdateCertificateTypeArgs = {
  input: UpdateCertificateTypeInput;
};


export type MutationUpdateCompetenceTypeArgs = {
  input: UpdateCompetenceTypeInput;
};


export type MutationUpdateCustomFieldForEntityArgs = {
  input: UpdateCustomFieldForEntityInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateEmployeeAboutArgs = {
  input: UpdateEmployeeAboutInput;
};


export type MutationUpdateEmployeeAbsenceArgs = {
  input: UpdateEmployeeAbsenceInput;
};


export type MutationUpdateEmployeeAbsenceBalanceArgs = {
  input: UpdateEmployeeAbsenceBalanceInput;
};


export type MutationUpdateEmployeeAbsenceDescriptionArgs = {
  input: UpdateEmployeeAbsenceDescriptionInput;
};


export type MutationUpdateEmployeeAbsenceEndDateArgs = {
  input: UpdateEmployeeAbsenceEndDateInput;
};


export type MutationUpdateEmployeeAbsenceStartDateArgs = {
  input: UpdateEmployeeAbsenceStartDateInput;
};


export type MutationUpdateEmployeeAbsenceStatusArgs = {
  input: UpdateEmployeeAbsenceStatusInput;
};


export type MutationUpdateEmployeeAbsenceTypeIdArgs = {
  input: UpdateEmployeeAbsenceTypeIdInput;
};


export type MutationUpdateEmployeeAddressArgs = {
  input: UpdateEmployeeAddressInput;
};


export type MutationUpdateEmployeeAdminArgs = {
  input: UpdateEmployeeAdminInput;
};


export type MutationUpdateEmployeeAllergyArgs = {
  input: UpdateEmployeeAllergyInput;
};


export type MutationUpdateEmployeeBankAccountNumberArgs = {
  input: UpdateEmployeeBankAccountNumberInput;
};


export type MutationUpdateEmployeeBirthDateArgs = {
  input: UpdateEmployeeBirthDateInput;
};


export type MutationUpdateEmployeeCellularArgs = {
  input: UpdateEmployeeCellularInput;
};


export type MutationUpdateEmployeeCertificateArgs = {
  input: UpdateEmployeeCertificateInput;
};


export type MutationUpdateEmployeeCompetenceArgs = {
  input: UpdateEmployeeCompetenceInput;
};


export type MutationUpdateEmployeeDefaultAddressArgs = {
  input: UpdateEmployeeDefaultAddressInput;
};


export type MutationUpdateEmployeeDefaultProductArgs = {
  input: UpdateEmployeeDefaultProductInput;
};


export type MutationUpdateEmployeeDeviceArgs = {
  input: UpdateEmployeeDeviceInput;
};


export type MutationUpdateEmployeeEducationArgs = {
  input: UpdateEmployeeEducationInput;
};


export type MutationUpdateEmployeeEmailArgs = {
  input: UpdateEmployeeEmailInput;
};


export type MutationUpdateEmployeeEmploymentArgs = {
  input: UpdateEmployeeEmploymentInput;
};


export type MutationUpdateEmployeeEmploymentDescriptionArgs = {
  input: UpdateEmployeeEmploymentDescriptionInput;
};


export type MutationUpdateEmployeeEmploymentEmploymentRatePercentArgs = {
  input: UpdateEmployeeEmploymentEmploymentRatePercentInput;
};


export type MutationUpdateEmployeeEmploymentEmploymentTypeIdArgs = {
  input: UpdateEmployeeEmploymentEmploymentTypeIdInput;
};


export type MutationUpdateEmployeeEmploymentEndDateArgs = {
  input: UpdateEmployeeEmploymentEndDateInput;
};


export type MutationUpdateEmployeeEmploymentPositionIdArgs = {
  input: UpdateEmployeeEmploymentPositionIdInput;
};


export type MutationUpdateEmployeeEmploymentStartDateArgs = {
  input: UpdateEmployeeEmploymentStartDateInput;
};


export type MutationUpdateEmployeeEmploymentWageTypeIdArgs = {
  input: UpdateEmployeeEmploymentWageTypeIdInput;
};


export type MutationUpdateEmployeeEmploymentWageValueArgs = {
  input: UpdateEmployeeEmploymentWageValueInput;
};


export type MutationUpdateEmployeeExpenseManagerIdArgs = {
  input: UpdateEmployeeExpenseManagerIdInput;
};


export type MutationUpdateEmployeeExternalIdArgs = {
  input: UpdateEmployeeExternalIdInput;
};


export type MutationUpdateEmployeeExternalSystemIdArgs = {
  input: UpdateEmployeeExternalSystemIdInput;
};


export type MutationUpdateEmployeeFamilyArgs = {
  input: UpdateEmployeeFamilyInput;
};


export type MutationUpdateEmployeeFirstNameArgs = {
  input: UpdateEmployeeFirstNameInput;
};


export type MutationUpdateEmployeeGenderIdArgs = {
  input: UpdateEmployeeGenderIdInput;
};


export type MutationUpdateEmployeeInitialsArgs = {
  input: UpdateEmployeeInitialsInput;
};


export type MutationUpdateEmployeeJobDescriptionArgs = {
  input: UpdateEmployeeJobDescriptionInput;
};


export type MutationUpdateEmployeeKmHomeWorkArgs = {
  input: UpdateEmployeeKmHomeWorkInput;
};


export type MutationUpdateEmployeeLanguageIdArgs = {
  input: UpdateEmployeeLanguageIdInput;
};


export type MutationUpdateEmployeeLastNameArgs = {
  input: UpdateEmployeeLastNameInput;
};


export type MutationUpdateEmployeeManagerIdArgs = {
  input: UpdateEmployeeManagerIdInput;
};


export type MutationUpdateEmployeeMaritalStatusTypeIdArgs = {
  input: UpdateEmployeeMaritalStatusTypeIdInput;
};


export type MutationUpdateEmployeeNationalityIdArgs = {
  input: UpdateEmployeeNationalityIdInput;
};


export type MutationUpdateEmployeeOrganisationDepartmentIdArgs = {
  input: UpdateEmployeeOrganisationDepartmentIdInput;
};


export type MutationUpdateEmployeeOrganisationIdArgs = {
  input: UpdateEmployeeOrganisationIdInput;
};


export type MutationUpdateEmployeePhoneArgs = {
  input: UpdateEmployeePhoneInput;
};


export type MutationUpdateEmployeeProfilePictureArgs = {
  input: UpdateEmployeeProfilePictureInput;
};


export type MutationUpdateEmployeeSizeFeetIdArgs = {
  input: UpdateEmployeeSizeFeetIdInput;
};


export type MutationUpdateEmployeeSizeLegsIdArgs = {
  input: UpdateEmployeeSizeLegsIdInput;
};


export type MutationUpdateEmployeeSizeTorsoIdArgs = {
  input: UpdateEmployeeSizeTorsoIdInput;
};


export type MutationUpdateEmployeeSizeWaistIdArgs = {
  input: UpdateEmployeeSizeWaistIdInput;
};


export type MutationUpdateEmployeeSocialSecurityNumberArgs = {
  input: UpdateEmployeeSocialSecurityNumberInput;
};


export type MutationUpdateEmployeeStartDateArgs = {
  input: UpdateEmployeeStartDateInput;
};


export type MutationUpdateEmployeeTimeSheetManagerIdArgs = {
  input: UpdateEmployeeTimeSheetManagerIdInput;
};


export type MutationUpdateEmployeeTrialMonthsArgs = {
  input: UpdateEmployeeTrialMonthsInput;
};


export type MutationUpdateEntityEventSubscriptionArgs = {
  input: UpdateEntityEventSubscriptionInput;
};


export type MutationUpdateIdeaBodyArgs = {
  input: UpdateIdeaBodyInput;
};


export type MutationUpdateIdeaParentIdArgs = {
  input: UpdateIdeaParentIdInput;
};


export type MutationUpdateIdeaStatusTypeIdArgs = {
  input: UpdateIdeaStatusTypeIdInput;
};


export type MutationUpdateIdeaTitleArgs = {
  input: UpdateIdeaTitleInput;
};


export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};


export type MutationUpdateLanguageIsActiveArgs = {
  input: UpdateLanguageIsActiveInput;
};


export type MutationUpdateMasterModuleArgs = {
  input: UpdateMasterModuleInput;
};


export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};


export type MutationUpdateOkrObjectiveArgs = {
  input: UpdateOkrObjectiveInput;
};


export type MutationUpdateOrganisationAccountIdArgs = {
  input: UpdateOrganisationAccountIdInput;
};


export type MutationUpdateOrganisationAddressArgs = {
  input: UpdateOrganisationAddressInput;
};


export type MutationUpdateOrganisationAdministrationFeeCalculationRuleArgs = {
  input: UpdateOrganisationAdministrationFeeCalculationRuleInput;
};


export type MutationUpdateOrganisationAdministrationFeeCalculationRuleThresholdArgs = {
  input: UpdateOrganisationAdministrationFeeCalculationRuleThresholdInput;
};


export type MutationUpdateOrganisationAdministrationFeeProductIdArgs = {
  input: UpdateOrganisationAdministrationFeeProductIdInput;
};


export type MutationUpdateOrganisationEmailArgs = {
  input: UpdateOrganisationEmailInput;
};


export type MutationUpdateOrganisationEmailFooterColorArgs = {
  input: UpdateOrganisationEmailFooterColorInput;
};


export type MutationUpdateOrganisationEmailHeaderColorArgs = {
  input: UpdateOrganisationEmailHeaderColorInput;
};


export type MutationUpdateOrganisationExternalErpIdArgs = {
  input: UpdateOrganisationExternalErpIdInput;
};


export type MutationUpdateOrganisationLanguageIdArgs = {
  input: UpdateOrganisationLanguageIdInput;
};


export type MutationUpdateOrganisationLegalNameArgs = {
  input: UpdateOrganisationLegalNameInput;
};


export type MutationUpdateOrganisationLegalNoArgs = {
  input: UpdateOrganisationLegalNoInput;
};


export type MutationUpdateOrganisationLogoArgs = {
  input: UpdateOrganisationLogoInput;
};


export type MutationUpdateOrganisationManagerIdArgs = {
  input: UpdateOrganisationManagerIdInput;
};


export type MutationUpdateOrganisationNameArgs = {
  input: UpdateOrganisationNameInput;
};


export type MutationUpdateOrganisationNumberingArgs = {
  input: UpdateOrganisationNumberingInput;
};


export type MutationUpdateOrganisationPayTermIdArgs = {
  input: UpdateOrganisationPayTermIdInput;
};


export type MutationUpdateOrganisationPhoneArgs = {
  input: UpdateOrganisationPhoneInput;
};


export type MutationUpdateOrganisationPrimaryColorCodeArgs = {
  input: UpdateOrganisationPrimaryColorCodeInput;
};


export type MutationUpdateOrganisationSecondaryColorCodeArgs = {
  input: UpdateOrganisationSecondaryColorCodeInput;
};


export type MutationUpdateOrganisationSmsSenderNameArgs = {
  input: UpdateOrganisationSmsSenderNameInput;
};


export type MutationUpdateOrganisationSymbolArgs = {
  input: UpdateOrganisationSymbolInput;
};


export type MutationUpdateOrganisationTemplateLogoArgs = {
  input: UpdateOrganisationTemplateLogoInput;
};


export type MutationUpdatePositionArgs = {
  input: UpdatePositionInput;
};


export type MutationUpdatePowerGridArgs = {
  input: UpdatePowerGridInput;
};


export type MutationUpdateResponsibilityArgs = {
  input: UpdateResponsibilityInput;
};


export type MutationUpdateResponsibilityTaskArgs = {
  input: UpdateResponsibilityTaskInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateTodoArgs = {
  input: UpdateTodoInput;
};

export type NoRowAccessError = AxosError & {
  __typename?: 'NoRowAccessError';
  code: Scalars['String']['output'];
  extensions: Array<KeyValuePairOfStringAndObject>;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  resourceId: Scalars['String']['output'];
};

export type NotificationDto = {
  __typename?: 'NotificationDto';
  createdOn: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  instanceId: Scalars['Int']['output'];
  isRead: Scalars['Boolean']['output'];
  resourceId: Scalars['String']['output'];
  responsibleId?: Maybe<Scalars['Int']['output']>;
  responsibleImage?: Maybe<Scalars['String']['output']>;
  responsibleInitials?: Maybe<Scalars['String']['output']>;
  responsibleName?: Maybe<Scalars['String']['output']>;
};

export type NullableOfDateFormatOperationFilterInput = {
  eq?: InputMaybe<DateFormat>;
  in?: InputMaybe<Array<InputMaybe<DateFormat>>>;
  neq?: InputMaybe<DateFormat>;
  nin?: InputMaybe<Array<InputMaybe<DateFormat>>>;
};

export type NullableOfSortDirectionOperationFilterInput = {
  eq?: InputMaybe<SortDirection>;
  in?: InputMaybe<Array<InputMaybe<SortDirection>>>;
  neq?: InputMaybe<SortDirection>;
  nin?: InputMaybe<Array<InputMaybe<SortDirection>>>;
};

export type NullableOfTimeFormatOperationFilterInput = {
  eq?: InputMaybe<TimeFormat>;
  in?: InputMaybe<Array<InputMaybe<TimeFormat>>>;
  neq?: InputMaybe<TimeFormat>;
  nin?: InputMaybe<Array<InputMaybe<TimeFormat>>>;
};

export type NumberFilter = {
  __typename?: 'NumberFilter';
  operator: OperatorEnum;
  value?: Maybe<Scalars['Int']['output']>;
};

export type NumberFilterDto = {
  __typename?: 'NumberFilterDto';
  createdOn: Scalars['DateTime']['output'];
  filter: NumberFilter;
  filterConfigId: Scalars['Int']['output'];
  filterType: FilterType;
  gridId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type NumberFilterFilterInput = {
  and?: InputMaybe<Array<NumberFilterFilterInput>>;
  operator?: InputMaybe<OperatorEnumOperationFilterInput>;
  or?: InputMaybe<Array<NumberFilterFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type NumberFilterInput = {
  operator: OperatorEnum;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type NumberGridFilterFilterInput = {
  and?: InputMaybe<Array<NumberGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  numberFilter?: InputMaybe<NumberFilterFilterInput>;
  or?: InputMaybe<Array<NumberGridFilterFilterInput>>;
};

export type NumberGridFilterInput = {
  entityGridFilterConfigId: Scalars['Int']['input'];
  numberFilter: NumberFilterInput;
};

export type NumberingDto = {
  __typename?: 'NumberingDto';
  currentNumber: Scalars['Int']['output'];
  entityId: Scalars['Int']['output'];
  entityName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  startNumber: Scalars['Int']['output'];
};

export type NumberingInput = {
  /** Can be null, or max 3 */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /** StartNumber for number series */
  startNumber: Scalars['Int']['input'];
};

export type OfficeAccessDto = {
  __typename?: 'OfficeAccessDto';
  access: AccessDto;
  name: Scalars['String']['output'];
  officeId: Scalars['Int']['output'];
};

export type OfficeInput = {
  managerId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

/** A segment of a collection. */
export type OfficesForOrganisationCollectionSegment = {
  __typename?: 'OfficesForOrganisationCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganisationOffice>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type OkrObjective = {
  __typename?: 'OkrObjective';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  due?: Maybe<Scalars['Date']['output']>;
  employeeId: Scalars['Int']['output'];
  employeeMeetingId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  objectiveType: OkrObjectiveType;
  start: Scalars['Date']['output'];
  status?: Maybe<OkrObjectiveStatus>;
  statusId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  typeId: Scalars['Int']['output'];
};

export type OkrObjectiveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['Date']['input']>;
  employeeMeetingId?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Date']['input'];
  statusId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
};

export type OkrObjectiveStatus = {
  __typename?: 'OkrObjectiveStatus';
  checkinId: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  objectiveId: Scalars['Int']['output'];
  statusType: OkrStatusType;
  typeId: Scalars['Int']['output'];
};

export type OkrObjectiveType = {
  __typename?: 'OkrObjectiveType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type OkrStatusType = {
  __typename?: 'OkrStatusType';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export enum OperatorEnum {
  ContainsId = 'CONTAINS_ID',
  ContainsString = 'CONTAINS_STRING',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  NotContainsId = 'NOT_CONTAINS_ID'
}

export type OperatorEnumOperationFilterInput = {
  eq?: InputMaybe<OperatorEnum>;
  in?: InputMaybe<Array<OperatorEnum>>;
  neq?: InputMaybe<OperatorEnum>;
  nin?: InputMaybe<Array<OperatorEnum>>;
};

/** Model representing a single organisation */
export type Organisation = {
  __typename?: 'Organisation';
  /** Standard customer in Axos */
  accountId?: Maybe<Scalars['Int']['output']>;
  addresses: Array<Address>;
  administrationFeeCalculationRule: AdministrationFeeCalculationRule;
  administrationFeeCalculationRuleThreshold?: Maybe<Scalars['Decimal']['output']>;
  administrationFeeProductId?: Maybe<Scalars['Int']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  email?: Maybe<AxosEmail>;
  emailFooterColor?: Maybe<Scalars['String']['output']>;
  emailHeaderColor?: Maybe<Scalars['String']['output']>;
  /** ID of the organisations external ERP system */
  externalERPId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  language: Language;
  /** Default language for the organisation */
  languageId: Scalars['Int']['output'];
  /** Legal name of an organisation */
  legalName: Scalars['String']['output'];
  /** Official organisation number of an organisation */
  legalNo: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Employee>;
  managerId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  payTermId: Scalars['Int']['output'];
  phone: AxosPhoneNumber;
  /** Primary color code of the organisation in hex format */
  primaryColorCode?: Maybe<Scalars['String']['output']>;
  secondaryColorCode?: Maybe<Scalars['String']['output']>;
  smsSenderName?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  templateLogo?: Maybe<Scalars['String']['output']>;
};

export type OrganisationAccessDto = {
  __typename?: 'OrganisationAccessDto';
  access: AccessDto;
  name: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
};

export type OrganisationAddress = {
  __typename?: 'OrganisationAddress';
  addressId: Scalars['Int']['output'];
  organisationId: Scalars['Int']['output'];
};

export type OrganisationAddressFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<OrganisationAddressFilterInput>>;
  or?: InputMaybe<Array<OrganisationAddressFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
};

export type OrganisationBenefit = {
  __typename?: 'OrganisationBenefit';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  employees: Array<OrganisationBenefitEmployee>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organisation: Organisation;
  organisationId: Scalars['Int']['output'];
};

export type OrganisationBenefitEmployee = {
  __typename?: 'OrganisationBenefitEmployee';
  benefitId: Scalars['Int']['output'];
  employeeId: Scalars['Int']['output'];
};

export type OrganisationBenefitEmployeeFilterInput = {
  and?: InputMaybe<Array<OrganisationBenefitEmployeeFilterInput>>;
  benefitId?: InputMaybe<IntOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationBenefitEmployeeFilterInput>>;
  organisationBenefit?: InputMaybe<OrganisationBenefitFilterInput>;
};

export type OrganisationBenefitFilterInput = {
  and?: InputMaybe<Array<OrganisationBenefitFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfOrganisationBenefitEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationBenefitFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
};

export type OrganisationDepartment = {
  __typename?: 'OrganisationDepartment';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  employees: Array<Employee>;
  externalERPId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  manager?: Maybe<Employee>;
  managerId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
};

export type OrganisationDepartmentFilterInput = {
  and?: InputMaybe<Array<OrganisationDepartmentFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  externalERPId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationDepartmentFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationDepartments?: InputMaybe<ListFilterInputTypeOfOrganisationDepartmentFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  parentDepartment?: InputMaybe<OrganisationDepartmentFilterInput>;
  parentId?: InputMaybe<IntOperationFilterInput>;
  workflowTemplates?: InputMaybe<ListFilterInputTypeOfWorkflowTemplateFilterInput>;
};

export type OrganisationDepartmentSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  externalERPId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<EmployeeSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationId?: InputMaybe<SortEnumType>;
  parentDepartment?: InputMaybe<OrganisationDepartmentSortInput>;
  parentId?: InputMaybe<SortEnumType>;
};

export type OrganisationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  administrationFeeCalculationRule?: InputMaybe<AdministrationFeeCalculationRuleOperationFilterInput>;
  administrationFeeCalculationRuleThreshold?: InputMaybe<DecimalOperationFilterInput>;
  administrationFeeProductId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<OrganisationFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  departments?: InputMaybe<ListFilterInputTypeOfOrganisationDepartmentFilterInput>;
  email?: InputMaybe<AxosEmailFilterInput>;
  emailFooterColor?: InputMaybe<StringOperationFilterInput>;
  emailHeaderColor?: InputMaybe<StringOperationFilterInput>;
  externalERPId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  language?: InputMaybe<LanguageFilterInput>;
  languageId?: InputMaybe<IntOperationFilterInput>;
  legalName?: InputMaybe<StringOperationFilterInput>;
  legalNo?: InputMaybe<StringOperationFilterInput>;
  logo?: InputMaybe<StringOperationFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationFilterInput>>;
  organisationAddresses?: InputMaybe<ListFilterInputTypeOfOrganisationAddressFilterInput>;
  organisationBenefits?: InputMaybe<ListFilterInputTypeOfOrganisationBenefitFilterInput>;
  organisationInsurances?: InputMaybe<ListFilterInputTypeOfOrganisationInsuranceFilterInput>;
  organisationNumberings?: InputMaybe<ListFilterInputTypeOfOrganisationNumberingFilterInput>;
  organisationOffices?: InputMaybe<ListFilterInputTypeOfOrganisationOfficeFilterInput>;
  organisationResponsibilities?: InputMaybe<ListFilterInputTypeOfOrganisationResponsibilityFilterInput>;
  organisationTeams?: InputMaybe<ListFilterInputTypeOfOrganisationTeamFilterInput>;
  payTermId?: InputMaybe<IntOperationFilterInput>;
  phone?: InputMaybe<AxosPhoneNumberFilterInput>;
  positions?: InputMaybe<ListFilterInputTypeOfPositionFilterInput>;
  primaryColorCode?: InputMaybe<StringOperationFilterInput>;
  secondaryColorCode?: InputMaybe<StringOperationFilterInput>;
  smsSenderName?: InputMaybe<StringOperationFilterInput>;
  symbol?: InputMaybe<StringOperationFilterInput>;
  templateLogo?: InputMaybe<StringOperationFilterInput>;
  templates?: InputMaybe<ListFilterInputTypeOfTemplateFilterInput>;
  workflowTemplates?: InputMaybe<ListFilterInputTypeOfWorkflowTemplateFilterInput>;
};

export type OrganisationInsurance = {
  __typename?: 'OrganisationInsurance';
  account: Account;
  accountId: Scalars['Int']['output'];
  contact: Contact;
  contactId: Scalars['Int']['output'];
  coverage?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  deductibleAmount?: Maybe<Scalars['Decimal']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employees: Array<OrganisationInsuranceEmployee>;
  externalUrl?: Maybe<Scalars['String']['output']>;
  helpline?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  organisation: Organisation;
  organisationId: Scalars['Int']['output'];
  policyEndDate?: Maybe<Scalars['Date']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  policyStartDate?: Maybe<Scalars['Date']['output']>;
};

export type OrganisationInsuranceEmployee = {
  __typename?: 'OrganisationInsuranceEmployee';
  employeeId: Scalars['Int']['output'];
  insuranceId: Scalars['Int']['output'];
};

export type OrganisationInsuranceEmployeeFilterInput = {
  and?: InputMaybe<Array<OrganisationInsuranceEmployeeFilterInput>>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  insuranceId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationInsuranceEmployeeFilterInput>>;
  organisationInsurance?: InputMaybe<OrganisationInsuranceFilterInput>;
};

export type OrganisationInsuranceFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<OrganisationInsuranceFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  coverage?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  deductibleAmount?: InputMaybe<DecimalOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfOrganisationInsuranceEmployeeFilterInput>;
  externalUrl?: InputMaybe<StringOperationFilterInput>;
  helpline?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationInsuranceFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  policyEndDate?: InputMaybe<DateOperationFilterInput>;
  policyNumber?: InputMaybe<StringOperationFilterInput>;
  policyStartDate?: InputMaybe<DateOperationFilterInput>;
};

export type OrganisationNumberingDefault = {
  __typename?: 'OrganisationNumberingDefault';
  entity: Entity;
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Int']['output'];
  startNumber: Scalars['Int']['output'];
};

export type OrganisationNumberingDefaultFilterInput = {
  and?: InputMaybe<Array<OrganisationNumberingDefaultFilterInput>>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationNumberingDefaultFilterInput>>;
  prefix?: InputMaybe<StringOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  startNumber?: InputMaybe<IntOperationFilterInput>;
};

export type OrganisationNumberingFilterInput = {
  and?: InputMaybe<Array<OrganisationNumberingFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  currentNumber?: InputMaybe<IntOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationNumberingFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  prefix?: InputMaybe<StringOperationFilterInput>;
  startNumber?: InputMaybe<IntOperationFilterInput>;
};

export type OrganisationOffice = {
  __typename?: 'OrganisationOffice';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  employees: Array<Employee>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  manager: Employee;
  managerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organisation: Organisation;
  organisationId: Scalars['Int']['output'];
};

export type OrganisationOfficeEmployee = {
  __typename?: 'OrganisationOfficeEmployee';
  employeeId: Scalars['Int']['output'];
  officeId: Scalars['Int']['output'];
};

export type OrganisationOfficeEmployeeFilterInput = {
  and?: InputMaybe<Array<OrganisationOfficeEmployeeFilterInput>>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  officeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationOfficeEmployeeFilterInput>>;
  organisationOffice?: InputMaybe<OrganisationOfficeFilterInput>;
};

export type OrganisationOfficeFilterInput = {
  and?: InputMaybe<Array<OrganisationOfficeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfOrganisationOfficeEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationOfficeFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  workflowTemplates?: InputMaybe<ListFilterInputTypeOfWorkflowTemplateFilterInput>;
};

export type OrganisationOfficeSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<EmployeeSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationId?: InputMaybe<SortEnumType>;
};

export type OrganisationResponsibility = {
  __typename?: 'OrganisationResponsibility';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
  organisationResponsibilityTasks: Array<OrganisationResponsibilityTask>;
  position: Position;
};

export type OrganisationResponsibilityFilterInput = {
  and?: InputMaybe<Array<OrganisationResponsibilityFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationResponsibilityFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  organisationResponsibilityTasks?: InputMaybe<ListFilterInputTypeOfOrganisationResponsibilityTaskFilterInput>;
};

export type OrganisationResponsibilitySortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationId?: InputMaybe<SortEnumType>;
};

export type OrganisationResponsibilityTask = {
  __typename?: 'OrganisationResponsibilityTask';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ownerEmployeeId?: Maybe<Scalars['Int']['output']>;
  ownerReminderMonths?: Maybe<Scalars['Int']['output']>;
  ownerReminderStartDate?: Maybe<Scalars['DateTime']['output']>;
  positionId: Scalars['Int']['output'];
  responsibilityId: Scalars['Int']['output'];
};

export type OrganisationResponsibilityTaskFilterInput = {
  and?: InputMaybe<Array<OrganisationResponsibilityTaskFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationResponsibilityTaskFilterInput>>;
  ownerEmployee?: InputMaybe<EmployeeFilterInput>;
  ownerEmployeeId?: InputMaybe<IntOperationFilterInput>;
  ownerReminderMonths?: InputMaybe<IntOperationFilterInput>;
  ownerReminderStartDate?: InputMaybe<DateTimeOperationFilterInput>;
  position?: InputMaybe<PositionFilterInput>;
  positionId?: InputMaybe<IntOperationFilterInput>;
  responsibility?: InputMaybe<OrganisationResponsibilityFilterInput>;
  responsibilityId?: InputMaybe<IntOperationFilterInput>;
};

export type OrganisationSortInput = {
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  administrationFeeCalculationRule?: InputMaybe<SortEnumType>;
  administrationFeeCalculationRuleThreshold?: InputMaybe<SortEnumType>;
  administrationFeeProductId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  email?: InputMaybe<AxosEmailSortInput>;
  emailFooterColor?: InputMaybe<SortEnumType>;
  emailHeaderColor?: InputMaybe<SortEnumType>;
  externalERPId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  language?: InputMaybe<LanguageSortInput>;
  languageId?: InputMaybe<SortEnumType>;
  legalName?: InputMaybe<SortEnumType>;
  legalNo?: InputMaybe<SortEnumType>;
  logo?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<EmployeeSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  payTermId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<AxosPhoneNumberSortInput>;
  primaryColorCode?: InputMaybe<SortEnumType>;
  secondaryColorCode?: InputMaybe<SortEnumType>;
  smsSenderName?: InputMaybe<SortEnumType>;
  symbol?: InputMaybe<SortEnumType>;
  templateLogo?: InputMaybe<SortEnumType>;
};

export type OrganisationTeam = {
  __typename?: 'OrganisationTeam';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  employees: Array<Employee>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  manager: Employee;
  managerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organisation: Organisation;
  organisationId: Scalars['Int']['output'];
};

export type OrganisationTeamEmployee = {
  __typename?: 'OrganisationTeamEmployee';
  employeeId: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
};

export type OrganisationTeamEmployeeFilterInput = {
  and?: InputMaybe<Array<OrganisationTeamEmployeeFilterInput>>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationTeamEmployeeFilterInput>>;
  organisationTeam?: InputMaybe<OrganisationTeamFilterInput>;
  teamId?: InputMaybe<IntOperationFilterInput>;
};

export type OrganisationTeamFilterInput = {
  and?: InputMaybe<Array<OrganisationTeamFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfOrganisationTeamEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobFilterInput>;
  manager?: InputMaybe<EmployeeFilterInput>;
  managerId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationTeamFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  workflowTemplates?: InputMaybe<ListFilterInputTypeOfWorkflowTemplateFilterInput>;
};

export type OrganisationTeamSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<EmployeeSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type OrganisationsCollectionSegment = {
  __typename?: 'OrganisationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Organisation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PaymentTermFilterInput = {
  and?: InputMaybe<Array<PaymentTermFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  externalId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PaymentTermFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type PaymentTermSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type PhoneNumberInput = {
  /** Used to determine country associated with the phone number, such as prefix and country code */
  countryId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type Position = {
  __typename?: 'Position';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  defaultJobDescription?: Maybe<Scalars['String']['output']>;
  employeeCount: Scalars['Int']['output'];
  employees: Array<Employee>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  responsibilityTasks: Array<OrganisationResponsibilityTask>;
  sortIndex: Scalars['Int']['output'];
};


export type PositionEmployeeCountArgs = {
  organisationId?: InputMaybe<Scalars['Int']['input']>;
};


export type PositionEmployeesArgs = {
  organisationId?: InputMaybe<Scalars['Int']['input']>;
};

export type PositionFilterInput = {
  and?: InputMaybe<Array<PositionFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  defaultJobDescription?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PositionFilterInput>>;
  responsibilityTasks?: InputMaybe<ListFilterInputTypeOfOrganisationResponsibilityTaskFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type PositionSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  defaultJobDescription?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type PowerGridColumnDto = {
  __typename?: 'PowerGridColumnDto';
  dataKey: Scalars['String']['output'];
  entityGridColumnConfigId: Scalars['Int']['output'];
  gridColumnConfig: EntityGridColumnConfig;
  gridColumnProperties: GridColumnProperties;
  gridId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type PowerGridColumnInput = {
  entityGridColumnConfigId: Scalars['Int']['input'];
  gridColumnProperties: GridColumnPropertiesInput;
};

export type PowerGridCustomFieldColumnDto = {
  __typename?: 'PowerGridCustomFieldColumnDto';
  customFieldDataType: CustomFieldDataType;
  customFieldId: Scalars['Int']['output'];
  gridColumnProperties: GridColumnProperties;
  gridId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type PowerGridCustomFieldColumnInput = {
  customFieldId: Scalars['Int']['input'];
  gridColumnProperties: GridColumnPropertiesInput;
};

export type PowerGridDto = {
  __typename?: 'PowerGridDto';
  createdBy: Scalars['Int']['output'];
  customFieldBooleanFilters: Array<CustomFieldBooleanFilterDto>;
  customFieldColumns: Array<PowerGridCustomFieldColumnDto>;
  customFieldDateFilters: Array<CustomFieldDateFilterDto>;
  customFieldNumberFilters: Array<CustomFieldNumberFilterDto>;
  customFieldStringFilters: Array<CustomFieldStringFilterDto>;
  dataSource: Scalars['String']['output'];
  dateFilters: Array<DateFilterDto>;
  /** ResourceId of default grid */
  defaultNameResourceId?: Maybe<Scalars['String']['output']>;
  /** Shows name if set, and fallbacks to defaultNameResourceId */
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  gridColumns: Array<PowerGridColumnDto>;
  id: Scalars['Int']['output'];
  isDefaultPowerGrid: Scalars['Boolean']['output'];
  isZebra: Scalars['Boolean']['output'];
  listType: Scalars['Int']['output'];
  /** Actual name of the powergrid, configured by the cuser */
  name?: Maybe<Scalars['String']['output']>;
  numberFilters: Array<NumberFilterDto>;
  owner?: Maybe<Employee>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  pageCount: Scalars['Int']['output'];
  selectFilters: Array<SelectFilterDto>;
  sortColumnConfigId?: Maybe<Scalars['Int']['output']>;
  sortCustomFieldId?: Maybe<Scalars['Int']['output']>;
  sortDirection?: Maybe<SortDirection>;
  sortIndex: Scalars['Int']['output'];
  stringFilters: Array<StringFilterDto>;
  subGrids: Array<PowerGridSubGridDto>;
};

export type PowerGridInput = {
  customFieldColumns: Array<PowerGridCustomFieldColumnInput>;
  filters: FilterListInput;
  gridColumns: Array<PowerGridColumnInput>;
  isZebra: Scalars['Boolean']['input'];
  listType: Scalars['Int']['input'];
  /** Name of actual powergrid, freetext by user */
  name: Scalars['String']['input'];
  pageCount: Scalars['Int']['input'];
  sortColumnConfigId?: InputMaybe<Scalars['Int']['input']>;
  sortCustomFieldId?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  subGrids?: InputMaybe<Array<PowerGridSubGridInput>>;
};

export type PowerGridListDto = {
  __typename?: 'PowerGridListDto';
  defaultGrids: Array<PowerGridDto>;
  sharedGrids: Array<PowerGridDto>;
  userGrids: Array<PowerGridDto>;
};

export type PowerGridSubGridDto = {
  __typename?: 'PowerGridSubGridDto';
  defaultSubGridId: Scalars['Int']['output'];
  filterConfig: EntityGridFilterConfig;
  filterConfigId: Scalars['Int']['output'];
  filterValueField: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
  subGridConfigId: Scalars['Int']['output'];
  subGridId?: Maybe<Scalars['Int']['output']>;
};

export type PowerGridSubGridInput = {
  powerGridInput: PowerGridInput;
  subGridConfigId: Scalars['Int']['input'];
};

export type Product = {
  __typename?: 'Product';
  categoryId: Scalars['Int']['output'];
  costPrice?: Maybe<Scalars['Decimal']['output']>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  defaultPrice?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['Int']['output'];
  isBillable: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  longDescription?: Maybe<Scalars['String']['output']>;
  marginRatio?: Maybe<Scalars['Decimal']['output']>;
  metricTypeId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
  productCategory: ProductCategory;
  shortDescription?: Maybe<Scalars['String']['output']>;
  sortIndex: Scalars['Int']['output'];
  vat?: Maybe<Scalars['Decimal']['output']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type ProductCategoryFilterInput = {
  and?: InputMaybe<Array<ProductCategoryFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductCategoryFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type ProductCategorySortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type ProductFilterInput = {
  and?: InputMaybe<Array<ProductFilterInput>>;
  category?: InputMaybe<ProductCategoryFilterInput>;
  categoryId?: InputMaybe<IntOperationFilterInput>;
  costPrice?: InputMaybe<DecimalOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  defaultPrice?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isBillable?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  longDescription?: InputMaybe<StringOperationFilterInput>;
  marginRatio?: InputMaybe<DecimalOperationFilterInput>;
  metricType?: InputMaybe<MetricTypeFilterInput>;
  metricTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  shortDescription?: InputMaybe<StringOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  vat?: InputMaybe<DecimalOperationFilterInput>;
};

export type ProductInput = {
  categoryId: Scalars['Int']['input'];
  isBillable: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
  sortIndex: Scalars['Int']['input'];
};

export type ProductSortInput = {
  category?: InputMaybe<ProductCategorySortInput>;
  categoryId?: InputMaybe<SortEnumType>;
  costPrice?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  defaultPrice?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isBillable?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  longDescription?: InputMaybe<SortEnumType>;
  marginRatio?: InputMaybe<SortEnumType>;
  metricType?: InputMaybe<MetricTypeSortInput>;
  metricTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationId?: InputMaybe<SortEnumType>;
  shortDescription?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
  vat?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ProductsCollectionSegment = {
  __typename?: 'ProductsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProfileDto = {
  __typename?: 'ProfileDto';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impersonatedBy?: Maybe<ImpersonatedBy>;
  initials: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isEmployeeManager: Scalars['Boolean']['output'];
  isImpersonated: Scalars['Boolean']['output'];
  languageId: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  organisationCountryId?: Maybe<Scalars['Int']['output']>;
  organisationId: Scalars['Int']['output'];
  organisationLogo?: Maybe<Scalars['String']['output']>;
  organisationName: Scalars['String']['output'];
  organisationSymbol?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  absenceBalanceYearDropdown: Array<DropdownDto>;
  absenceBalances: Array<EmployeeAbsenceBalanceDto>;
  accessForEntityRow?: Maybe<EntityRowAccessDto>;
  accessOptions: AccessOptions;
  account: Array<Account>;
  accountCount: Scalars['Int']['output'];
  /**
   *
   *         Currently containing dummy data until account is implemented in the database
   *
   */
  accountDropdown: Array<DropdownDto>;
  accounts?: Maybe<AccountsCollectionSegment>;
  address?: Maybe<Address>;
  addressTypeDropdown: Array<DropdownDto>;
  addresses?: Maybe<AddressesCollectionSegment>;
  adminEvents: Array<NotificationDto>;
  /**
   *
   *         Temp data for product dropdown until product is implemented in the database
   *
   */
  administrationFeeProductDropdown: Array<DropdownDto>;
  /**
   *
   *         Temp data for payterm until product is implemented in the database
   *
   */
  administrationFeeRuleDropdown: Array<AdministrationFeeCalcRuleDropdown>;
  allergies?: Maybe<AllergiesCollectionSegment>;
  allergyTypeDropdown: Array<DropdownDto>;
  availableCapabilities: Array<Capability>;
  /** Returns all columns that can be configured on a powergrid for the given entity */
  availableGridFilters: FilterConfigDto;
  benefitDropdown: Array<DropdownDto>;
  benefitsForOrganisation: Array<OrganisationBenefit>;
  brregSearch: Array<BrregCompanyDto>;
  /** Get ID of all actions that an employee can use */
  capabilitiesForMe: Array<AxosCapabilities>;
  certificateType?: Maybe<CertificateType>;
  certificateTypeDropdown: Array<DropdownDto>;
  certificateTypes?: Maybe<CertificateTypesCollectionSegment>;
  childIdeas: Array<Idea>;
  clientEntityDropdown: Array<DropdownDto>;
  competenceLevelDropdown: Array<DropdownDto>;
  competenceType?: Maybe<CompetenceType>;
  competenceTypeDropdown: Array<DropdownDto>;
  competenceTypes: Array<CompetenceType>;
  contactDropdown: Array<DropdownDto>;
  contacts?: Maybe<ContactsCollectionSegment>;
  countries?: Maybe<CountriesCollectionSegment>;
  countryDropdown: Array<CountryDropdownDto>;
  customField?: Maybe<EntityCustomField>;
  customFieldRelationTypeDropdown: Array<DropdownDto>;
  customFieldTypeDropdown: Array<DropdownDto>;
  customFieldsForEntity: Array<EntityCustomField>;
  /** Get default grid by ID. */
  defaultPowerGridById: PowerGridDto;
  departmentCountForOrganisation: Scalars['Int']['output'];
  departmentDropdown: Array<DropdownDto>;
  departmentsForOrganisation?: Maybe<DepartmentsForOrganisationCollectionSegment>;
  deviceTypeDropdown: Array<DropdownDto>;
  educationTypeDropdown: Array<DropdownDto>;
  employee?: Maybe<Employee>;
  employeeAbsence: EmployeeAbsence;
  employeeAbsenceStatistics: EmployeeAbsenceStatistics;
  employeeAbsenceStatusTypeDropdown: Array<DropdownDto>;
  employeeAbsenceTypeDropdown: Array<DropdownDto>;
  employeeAbsenceTypeSelectableDropdown: Array<DropdownDto>;
  employeeAbsencesForApproval: Array<EmployeeAbsence>;
  employeeAllergy: EmployeeAllergy;
  employeeCertificate?: Maybe<EmployeeCertificate>;
  employeeCompetence: EmployeeCompetence;
  employeeDevice: EmployeeDevice;
  /**
   *
   *         Get dropdown model containing all employees. Label is concat of first and last name, and value is employee ID.
   *
   */
  employeeDropdown: Array<EmployeeDropdownDto>;
  employeeEducation: EmployeeEducation;
  employeeEmployment?: Maybe<EmployeeEmployment>;
  employeeFamilyMember: EmployeeFamily;
  employeeOkrObjective: OkrObjective;
  employeeResponsibilities: Array<OrganisationResponsibilityTask>;
  employees?: Maybe<EmployeesCollectionSegment>;
  employmentTypeDropdown: Array<DropdownDto>;
  employmentWageTypeDropdown: Array<DropdownDto>;
  entities?: Maybe<EntitiesCollectionSegment>;
  entityAccess: Array<HasAccessDto>;
  entityCount: Scalars['Int']['output'];
  entityEventSubscriptions: Array<EntityEventSubscription>;
  entityEvents: Array<EntityEventDto>;
  /** Returns all columns that can be configured on a powergrid for the given entity */
  entityGridConfig: EntityGridConfig;
  entityUrls: Array<EntityUrlDto>;
  familyRelationTypeDropdown: Array<DropdownDto>;
  genderDropdown: Array<DropdownDto>;
  idea: Idea;
  ideaImportanceTypeDropdown: Array<DropdownDto>;
  ideaStatusTypeDropdown: Array<DropdownDto>;
  ideas: Array<Idea>;
  ideasDropdown: Array<DropdownDto>;
  ideasFromMe: Array<Idea>;
  industryDropdown: Array<DropdownDto>;
  insuranceDropdown: Array<DropdownDto>;
  insurancesForOrganisation: Array<OrganisationInsurance>;
  languageCount: Scalars['Int']['output'];
  /**
   *
   *         Get dropdown model containing all languages. Value is language ID. Name is localized.
   *
   */
  languageDropdown: Array<LanguageDropdown>;
  languages: Array<Language>;
  localization: Array<ResourceDto>;
  maritalStatusTypeDropdown: Array<DropdownDto>;
  /** Dropdown for entities from master database */
  masterEntityDropdown: Array<DropdownDto>;
  masterModules?: Maybe<MasterModulesCollectionSegment>;
  moduleCount: Scalars['Int']['output'];
  modules?: Maybe<ModulesCollectionSegment>;
  /** Get ID of all modules that an employee can access */
  modulesForMe: Array<AxosModules>;
  /** Get access definitions for modules for one employee */
  modulesWithAccess: Array<ModuleWithAccessDto>;
  myNotifications: Array<NotificationDto>;
  officeCountForOrganisation: Scalars['Int']['output'];
  officeDropdown: Array<DropdownDto>;
  officesForOrganisation?: Maybe<OfficesForOrganisationCollectionSegment>;
  okrObjectiveTypeDropdown: Array<DropdownDto>;
  /** Retrieve a single organisation by ID */
  organisation?: Maybe<Organisation>;
  /** Number of addresses connected to an organisation */
  organisationAddressCount: Scalars['Int']['output'];
  organisationDepartment?: Maybe<OrganisationDepartment>;
  organisationDropdown: Array<DropdownDto>;
  /** Number of numberings connected to an organisation */
  organisationNumberingCount: Scalars['Int']['output'];
  /** Retrieve a single organisation by ID */
  organisationNumberings: Array<NumberingDto>;
  organisationOffice?: Maybe<OrganisationOffice>;
  organisationPositionCount: Scalars['Int']['output'];
  /** Number of responsibilities connected to organisation */
  organisationResponsibilitiesCount: Scalars['Int']['output'];
  organisationTeam?: Maybe<OrganisationTeam>;
  /** Retrieve all organisations, can be filtered, sorted and has paging */
  organisations?: Maybe<OrganisationsCollectionSegment>;
  parentIdeas: Array<Idea>;
  /**
   *
   *         Temp data for payterm until product is implemented in the database
   *
   */
  payTermDropdown: Array<DropdownDto>;
  position?: Maybe<Position>;
  /** Dropdown for position */
  positionDropdown: Array<DropdownDto>;
  positions: Array<Position>;
  /** Get user grid by ID. */
  powerGridById?: Maybe<PowerGridDto>;
  /** Get all powerGrid configured on entity that are valid for an employee. */
  powerGridListForEntity: PowerGridListDto;
  product?: Maybe<Product>;
  productCategoryDropdown: Array<DropdownDto>;
  productDropdown: Array<DropdownDto>;
  products?: Maybe<ProductsCollectionSegment>;
  profile: ProfileDto;
  /** Responsibilities connected to an Organisation */
  responsibilities?: Maybe<ResponsibilitiesCollectionSegment>;
  sizeFeetTypeDropdown: Array<DropdownDto>;
  sizeLegsTypeDropdown: Array<DropdownDto>;
  sizeTorsoTypeDropdown: Array<DropdownDto>;
  sizeWaistTypeDropdown: Array<DropdownDto>;
  teamDropdown: Array<DropdownDto>;
  teamsForOrganisation?: Maybe<TeamsForOrganisationCollectionSegment>;
  teamsForOrganisationCount: Scalars['Int']['output'];
  templateCount: Scalars['Int']['output'];
  templates?: Maybe<TemplatesCollectionSegment>;
  wageTypeDropdown: Array<DropdownDto>;
  zipCodes?: Maybe<ZipCodesCollectionSegment>;
};


export type QueryAbsenceBalancesArgs = {
  departmentId?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};


export type QueryAccessForEntityRowArgs = {
  entityId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
};


export type QueryAccountArgs = {
  accountId: Scalars['Int']['input'];
};


export type QueryAccountCountArgs = {
  accountId: Scalars['Int']['input'];
};


export type QueryAccountsArgs = {
  order?: InputMaybe<Array<AccountSortInput>>;
  powerGridCustomFieldSort?: InputMaybe<CustomFieldSortArgumentInput>;
  powerGridFilters?: InputMaybe<FilterListInput>;
  powerGridFreetextSearch?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAddressArgs = {
  addressId: Scalars['Int']['input'];
};


export type QueryAddressesArgs = {
  order?: InputMaybe<Array<AddressSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AddressFilterInput>;
};


export type QueryAllergiesArgs = {
  order?: InputMaybe<Array<EmployeeAllergySortInput>>;
  powerGridCustomFieldSort?: InputMaybe<CustomFieldSortArgumentInput>;
  powerGridFilters?: InputMaybe<FilterListInput>;
  powerGridFreetextSearch?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAvailableGridFiltersArgs = {
  entityId: Scalars['Int']['input'];
};


export type QueryBenefitsForOrganisationArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryBrregSearchArgs = {
  searchValue: Scalars['String']['input'];
};


export type QueryCertificateTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCertificateTypesArgs = {
  order?: InputMaybe<Array<CertificateTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CertificateTypeFilterInput>;
};


export type QueryChildIdeasArgs = {
  parentId: Scalars['Int']['input'];
};


export type QueryCompetenceTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCompetenceTypesArgs = {
  organisationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryContactsArgs = {
  order?: InputMaybe<Array<ContactSortInput>>;
  powerGridCustomFieldSort?: InputMaybe<CustomFieldSortArgumentInput>;
  powerGridFilters?: InputMaybe<FilterListInput>;
  powerGridFreetextSearch?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountriesArgs = {
  order?: InputMaybe<Array<CountrySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryFilterInput>;
};


export type QueryCustomFieldArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCustomFieldsForEntityArgs = {
  entity: AxosEntities;
};


export type QueryDefaultPowerGridByIdArgs = {
  powerGridId: Scalars['Int']['input'];
};


export type QueryDepartmentCountForOrganisationArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryDepartmentsForOrganisationArgs = {
  order?: InputMaybe<Array<OrganisationDepartmentSortInput>>;
  organisationId: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganisationDepartmentFilterInput>;
};


export type QueryEmployeeArgs = {
  employeeId: Scalars['Int']['input'];
};


export type QueryEmployeeAbsenceArgs = {
  absenceId: Scalars['Int']['input'];
};


export type QueryEmployeeAbsenceStatisticsArgs = {
  employeeId: Scalars['Int']['input'];
};


export type QueryEmployeeAllergyArgs = {
  allergyId: Scalars['Int']['input'];
};


export type QueryEmployeeCertificateArgs = {
  certificateId: Scalars['Int']['input'];
};


export type QueryEmployeeCompetenceArgs = {
  competenceId: Scalars['Int']['input'];
};


export type QueryEmployeeDeviceArgs = {
  deviceId: Scalars['Int']['input'];
};


export type QueryEmployeeDropdownArgs = {
  order?: InputMaybe<Array<EmployeeDropdownDtoSortInput>>;
};


export type QueryEmployeeEducationArgs = {
  educationId: Scalars['Int']['input'];
};


export type QueryEmployeeEmploymentArgs = {
  employmentId: Scalars['Int']['input'];
};


export type QueryEmployeeFamilyMemberArgs = {
  familyId: Scalars['Int']['input'];
};


export type QueryEmployeeOkrObjectiveArgs = {
  okrObjectiveId: Scalars['Int']['input'];
};


export type QueryEmployeeResponsibilitiesArgs = {
  employeeId: Scalars['Int']['input'];
};


export type QueryEmployeesArgs = {
  order?: InputMaybe<Array<EmployeeSortInput>>;
  powerGridCustomFieldSort?: InputMaybe<CustomFieldSortArgumentInput>;
  powerGridFilters?: InputMaybe<FilterListInput>;
  powerGridFreetextSearch?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEntitiesArgs = {
  order?: InputMaybe<Array<EntitySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntityFilterInput>;
};


export type QueryEntityAccessArgs = {
  entity: AxosEntities;
};


export type QueryEntityEventSubscriptionsArgs = {
  entity: AxosEntities;
  instanceId: Scalars['Int']['input'];
};


export type QueryEntityEventsArgs = {
  entity: AxosEntities;
  instanceId: Scalars['Int']['input'];
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryEntityGridConfigArgs = {
  entityId: Scalars['Int']['input'];
};


export type QueryIdeaArgs = {
  ideaId: Scalars['Int']['input'];
};


export type QueryInsurancesForOrganisationArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryLanguageDropdownArgs = {
  order?: InputMaybe<Array<LanguageDropdownSortInput>>;
};


export type QueryMasterModulesArgs = {
  order?: InputMaybe<Array<SeedingModuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeedingModuleFilterInput>;
};


export type QueryModulesArgs = {
  order?: InputMaybe<Array<ModuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModuleFilterInput>;
};


export type QueryOfficeCountForOrganisationArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOfficesForOrganisationArgs = {
  order?: InputMaybe<Array<OrganisationOfficeSortInput>>;
  organisationId: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganisationOfficeFilterInput>;
};


export type QueryOrganisationArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOrganisationAddressCountArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOrganisationDepartmentArgs = {
  departmentId: Scalars['Int']['input'];
};


export type QueryOrganisationNumberingCountArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOrganisationNumberingsArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOrganisationOfficeArgs = {
  officeId: Scalars['Int']['input'];
};


export type QueryOrganisationPositionCountArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOrganisationResponsibilitiesCountArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryOrganisationTeamArgs = {
  teamId: Scalars['Int']['input'];
};


export type QueryOrganisationsArgs = {
  order?: InputMaybe<Array<OrganisationSortInput>>;
  powerGridCustomFieldSort?: InputMaybe<CustomFieldSortArgumentInput>;
  powerGridFilters?: InputMaybe<FilterListInput>;
  powerGridFreetextSearch?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPositionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPositionsArgs = {
  organisationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPowerGridByIdArgs = {
  powerGridId: Scalars['Int']['input'];
};


export type QueryPowerGridListForEntityArgs = {
  entity: AxosEntities;
};


export type QueryProductArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryProductsArgs = {
  order?: InputMaybe<Array<ProductSortInput>>;
  powerGridCustomFieldSort?: InputMaybe<CustomFieldSortArgumentInput>;
  powerGridFilters?: InputMaybe<FilterListInput>;
  powerGridFreetextSearch?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryResponsibilitiesArgs = {
  order?: InputMaybe<Array<OrganisationResponsibilitySortInput>>;
  organisationId: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganisationResponsibilityFilterInput>;
};


export type QueryTeamsForOrganisationArgs = {
  order?: InputMaybe<Array<OrganisationTeamSortInput>>;
  organisationId: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganisationTeamFilterInput>;
};


export type QueryTeamsForOrganisationCountArgs = {
  organisationId: Scalars['Int']['input'];
};


export type QueryTemplatesArgs = {
  order?: InputMaybe<Array<TemplateSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TemplateFilterInput>;
};


export type QueryZipCodesArgs = {
  order?: InputMaybe<Array<ZipCodesSortInput>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ZipCodesFilterInput>;
};

export type ReadEntityEventInput = {
  notificationId: Scalars['Int']['input'];
};

export type ReadEntityEventPayload = {
  __typename?: 'ReadEntityEventPayload';
  int?: Maybe<Scalars['Int']['output']>;
};

export type RemoveEmployeeFromBenefitError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type RemoveEmployeeFromBenefitInput = {
  benefitId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
};

export type RemoveEmployeeFromBenefitPayload = {
  __typename?: 'RemoveEmployeeFromBenefitPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<RemoveEmployeeFromBenefitError>>;
};

export type RemoveEmployeeFromInsuranceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type RemoveEmployeeFromInsuranceInput = {
  employeeId: Scalars['Int']['input'];
  insuranceId: Scalars['Int']['input'];
};

export type RemoveEmployeeFromInsurancePayload = {
  __typename?: 'RemoveEmployeeFromInsurancePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<RemoveEmployeeFromInsuranceError>>;
};

export type RemoveEmployeeFromOfficeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type RemoveEmployeeFromOfficeInput = {
  employeeId: Scalars['Int']['input'];
  officeId: Scalars['Int']['input'];
};

export type RemoveEmployeeFromOfficePayload = {
  __typename?: 'RemoveEmployeeFromOfficePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<RemoveEmployeeFromOfficeError>>;
};

export type RemoveEmployeeFromTeamError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type RemoveEmployeeFromTeamInput = {
  employeeId: Scalars['Int']['input'];
  teamId: Scalars['Int']['input'];
};

export type RemoveEmployeeFromTeamPayload = {
  __typename?: 'RemoveEmployeeFromTeamPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<RemoveEmployeeFromTeamError>>;
};

export enum ResourceContext {
  AdminEntityEventFeed = 'ADMIN_ENTITY_EVENT_FEED',
  Default = 'DEFAULT',
  EntityHistory = 'ENTITY_HISTORY',
  FrontpageAndNotification = 'FRONTPAGE_AND_NOTIFICATION'
}

export type ResourceDto = {
  __typename?: 'ResourceDto';
  resourceId: Scalars['String']['output'];
  resourceItems: Array<ResourceItemDto>;
  sortIndex: Scalars['Int']['output'];
};

export type ResourceItemDto = {
  __typename?: 'ResourceItemDto';
  context: ResourceContext;
  language: LanguageDto;
  languageId: Scalars['Int']['output'];
  resourceId: Scalars['String']['output'];
  valuePlural?: Maybe<Scalars['String']['output']>;
  valueSingular?: Maybe<Scalars['String']['output']>;
};

/** A segment of a collection. */
export type ResponsibilitiesCollectionSegment = {
  __typename?: 'ResponsibilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganisationResponsibility>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ResponsibilityInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ResponsibilityTaskInput = {
  description: Scalars['String']['input'];
  monthInterval: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  /** Responsible employee for task */
  ownerEmployeeId: Scalars['Int']['input'];
  positionId: Scalars['Int']['input'];
  responsibilityId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type SeedCountryDataToClientDbError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type SeedCountryDataToClientDbPayload = {
  __typename?: 'SeedCountryDataToClientDbPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SeedCountryDataToClientDbError>>;
};

export type SeedNewClientError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type SeedNewClientPayload = {
  __typename?: 'SeedNewClientPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SeedNewClientError>>;
};

export type SeedNorwayGeoDataToClientDbError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type SeedNorwayGeoDataToClientDbPayload = {
  __typename?: 'SeedNorwayGeoDataToClientDbPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SeedNorwayGeoDataToClientDbError>>;
};

export type SeedOneTimeSeedToClientError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type SeedOneTimeSeedToClientPayload = {
  __typename?: 'SeedOneTimeSeedToClientPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SeedOneTimeSeedToClientError>>;
};

export type SeedingEntity = {
  __typename?: 'SeedingEntity';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nameResourceId: Scalars['String']['output'];
  relativeUrl?: Maybe<Scalars['String']['output']>;
};

export type SeedingEntityFilterInput = {
  and?: InputMaybe<Array<SeedingEntityFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SeedingEntityFilterInput>>;
  relativeUrl?: InputMaybe<StringOperationFilterInput>;
};

export type SeedingEntitySortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nameResourceId?: InputMaybe<SortEnumType>;
  relativeUrl?: InputMaybe<SortEnumType>;
};

export type SeedingModule = {
  __typename?: 'SeedingModule';
  childModules: Array<SeedingModule>;
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  defaultPrice: Scalars['Decimal']['output'];
  entity?: Maybe<SeedingEntity>;
  entityId?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nameResourceId: Scalars['String']['output'];
  parentModule?: Maybe<SeedingModule>;
  parentModuleId?: Maybe<Scalars['Int']['output']>;
  sortIndex: Scalars['Int']['output'];
};

export type SeedingModuleFilterInput = {
  and?: InputMaybe<Array<SeedingModuleFilterInput>>;
  childModules?: InputMaybe<ListFilterInputTypeOfSeedingModuleFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  defaultPrice?: InputMaybe<DecimalOperationFilterInput>;
  entity?: InputMaybe<SeedingEntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  icon?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  nameResourceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SeedingModuleFilterInput>>;
  parentModule?: InputMaybe<SeedingModuleFilterInput>;
  parentModuleId?: InputMaybe<IntOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type SeedingModuleSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  defaultPrice?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<SeedingEntitySortInput>;
  entityId?: InputMaybe<SortEnumType>;
  icon?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  nameResourceId?: InputMaybe<SortEnumType>;
  parentModule?: InputMaybe<SeedingModuleSortInput>;
  parentModuleId?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type SelectFilter = {
  __typename?: 'SelectFilter';
  selectedIds?: Maybe<Scalars['String']['output']>;
};

export type SelectFilterDto = {
  __typename?: 'SelectFilterDto';
  createdOn: Scalars['DateTime']['output'];
  filter: SelectFilter;
  filterConfigId: Scalars['Int']['output'];
  filterType: FilterType;
  gridId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type SelectFilterFilterInput = {
  and?: InputMaybe<Array<SelectFilterFilterInput>>;
  or?: InputMaybe<Array<SelectFilterFilterInput>>;
  selectedIds?: InputMaybe<StringOperationFilterInput>;
};

export type SelectFilterInput = {
  selectedIds?: InputMaybe<Scalars['String']['input']>;
};

export type SelectGridFilterFilterInput = {
  and?: InputMaybe<Array<SelectGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<SelectGridFilterFilterInput>>;
  selectFilter?: InputMaybe<SelectFilterFilterInput>;
};

export type SelectGridFilterInput = {
  entityGridFilterConfigId: Scalars['Int']['input'];
  selectFilter: SelectFilterInput;
};

export type SetCurrentEmploymentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type SetCurrentEmploymentInput = {
  employeeId: Scalars['Int']['input'];
  employmentId: Scalars['Int']['input'];
};

export type SetCurrentEmploymentPayload = {
  __typename?: 'SetCurrentEmploymentPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SetCurrentEmploymentError>>;
};

export type SetGlobalAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type SetGlobalAccessToEntityRowInput = {
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
  entityId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
};

export type SetGlobalAccessToEntityRowPayload = {
  __typename?: 'SetGlobalAccessToEntityRowPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SetGlobalAccessToEntityRowError>>;
};

export type SizeFeet = {
  __typename?: 'SizeFeet';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type SizeFeetFilterInput = {
  and?: InputMaybe<Array<SizeFeetFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SizeFeetFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type SizeFeetSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type SizeLegs = {
  __typename?: 'SizeLegs';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type SizeLegsFilterInput = {
  and?: InputMaybe<Array<SizeLegsFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SizeLegsFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type SizeLegsSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type SizeTorso = {
  __typename?: 'SizeTorso';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type SizeTorsoFilterInput = {
  and?: InputMaybe<Array<SizeTorsoFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SizeTorsoFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type SizeTorsoSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type SizeWaist = {
  __typename?: 'SizeWaist';
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sortIndex: Scalars['Int']['output'];
};

export type SizeWaistFilterInput = {
  and?: InputMaybe<Array<SizeWaistFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SizeWaistFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export type SizeWaistSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sortIndex?: InputMaybe<SortEnumType>;
};

export type SocialHandleTypeFilterInput = {
  and?: InputMaybe<Array<SocialHandleTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SocialHandleTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringFilter = {
  __typename?: 'StringFilter';
  value?: Maybe<Scalars['String']['output']>;
};

export type StringFilterDto = {
  __typename?: 'StringFilterDto';
  createdOn: Scalars['DateTime']['output'];
  filter: StringFilter;
  filterConfigId: Scalars['Int']['output'];
  filterType: FilterType;
  gridId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameResourceId: Scalars['String']['output'];
};

export type StringFilterFilterInput = {
  and?: InputMaybe<Array<StringFilterFilterInput>>;
  or?: InputMaybe<Array<StringFilterFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type StringFilterInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type StringGridFilterFilterInput = {
  and?: InputMaybe<Array<StringGridFilterFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  filterConfig?: InputMaybe<EntityGridFilterConfigFilterInput>;
  filterConfigId?: InputMaybe<IntOperationFilterInput>;
  grid?: InputMaybe<EntityGridFilterInput>;
  gridId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<StringGridFilterFilterInput>>;
  stringFilter?: InputMaybe<StringFilterFilterInput>;
};

export type StringGridFilterInput = {
  entityGridFilterConfigId: Scalars['Int']['input'];
  stringFilter: StringFilterInput;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TeamAccessDto = {
  __typename?: 'TeamAccessDto';
  access: AccessDto;
  name: Scalars['String']['output'];
  teamId: Scalars['Int']['output'];
};

export type TeamInput = {
  managerId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

/** A segment of a collection. */
export type TeamsForOrganisationCollectionSegment = {
  __typename?: 'TeamsForOrganisationCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganisationTeam>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Template = {
  __typename?: 'Template';
  body: Scalars['String']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  entity: Entity;
  entityId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
  templateRevisions: Array<TemplateRevision>;
};

export type TemplateFilterInput = {
  and?: InputMaybe<Array<TemplateFilterInput>>;
  body?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TemplateFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  templateRevisions?: InputMaybe<ListFilterInputTypeOfTemplateRevisionFilterInput>;
};

export type TemplateRevision = {
  __typename?: 'TemplateRevision';
  body: Scalars['String']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  templateId: Scalars['Int']['output'];
};

export type TemplateRevisionFilterInput = {
  and?: InputMaybe<Array<TemplateRevisionFilterInput>>;
  body?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TemplateRevisionFilterInput>>;
  template?: InputMaybe<TemplateFilterInput>;
  templateId?: InputMaybe<IntOperationFilterInput>;
};

export type TemplateSortInput = {
  body?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<EntitySortInput>;
  entityId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organisation?: InputMaybe<OrganisationSortInput>;
  organisationId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type TemplatesCollectionSegment = {
  __typename?: 'TemplatesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Template>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum TimeFormat {
  HourMinutes = 'HOUR_MINUTES',
  HourMinutesSeconds = 'HOUR_MINUTES_SECONDS'
}

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']['input']>;
  gt?: InputMaybe<Scalars['TimeSpan']['input']>;
  gte?: InputMaybe<Scalars['TimeSpan']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']['input']>;
  lte?: InputMaybe<Scalars['TimeSpan']['input']>;
  neq?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngt?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngte?: InputMaybe<Scalars['TimeSpan']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']['input']>;
  nlte?: InputMaybe<Scalars['TimeSpan']['input']>;
};

export type ToDoEmployee = {
  __typename?: 'ToDoEmployee';
  employeeId: Scalars['Int']['output'];
  toDoId: Scalars['Int']['output'];
};

export type ToDoEmployeeFilterInput = {
  and?: InputMaybe<Array<ToDoEmployeeFilterInput>>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ToDoEmployeeFilterInput>>;
  toDo?: InputMaybe<ToDoFilterInput>;
  toDoId?: InputMaybe<IntOperationFilterInput>;
};

export type ToDoFilterInput = {
  and?: InputMaybe<Array<ToDoFilterInput>>;
  checklist?: InputMaybe<ChecklistFilterInput>;
  checklistId?: InputMaybe<IntOperationFilterInput>;
  contactId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  due?: InputMaybe<DateTimeOperationFilterInput>;
  employeeMeeting?: InputMaybe<EmployeeMeetingFilterInput>;
  employeeMeetingId?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isFullDay?: InputMaybe<BooleanOperationFilterInput>;
  isRecurring?: InputMaybe<BooleanOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ToDoFilterInput>>;
  parentId?: InputMaybe<IntOperationFilterInput>;
  parentToDo?: InputMaybe<ToDoFilterInput>;
  primaryEmployee?: InputMaybe<EmployeeFilterInput>;
  primaryEmployeeId?: InputMaybe<IntOperationFilterInput>;
  priorityId?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  template?: InputMaybe<TemplateFilterInput>;
  templateId?: InputMaybe<IntOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  toDoChildren?: InputMaybe<ListFilterInputTypeOfToDoFilterInput>;
  toDoEmployees?: InputMaybe<ListFilterInputTypeOfToDoEmployeeFilterInput>;
  toDoRecurringExceptions?: InputMaybe<ListFilterInputTypeOfToDoRecurringExceptionFilterInput>;
  toDoRecurringPatterns?: InputMaybe<ListFilterInputTypeOfToDoRecurringPatternFilterInput>;
  toDoStatuses?: InputMaybe<ListFilterInputTypeOfToDoStatusFilterInput>;
};

export type ToDoRecurringExceptionFilterInput = {
  and?: InputMaybe<Array<ToDoRecurringExceptionFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  endDate?: InputMaybe<DateOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isCancelled?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isFullDay?: InputMaybe<BooleanOperationFilterInput>;
  isRescheduled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ToDoRecurringExceptionFilterInput>>;
  startDate?: InputMaybe<DateOperationFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  toDo?: InputMaybe<ToDoFilterInput>;
  toDoId?: InputMaybe<IntOperationFilterInput>;
};

export type ToDoRecurringPatternFilterInput = {
  and?: InputMaybe<Array<ToDoRecurringPatternFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  dayOfMonth?: InputMaybe<IntOperationFilterInput>;
  dayOfWeek?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  maxOccurrences?: InputMaybe<IntOperationFilterInput>;
  monthOfYear?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ToDoRecurringPatternFilterInput>>;
  separationCount?: InputMaybe<IntOperationFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  toDo?: InputMaybe<ToDoFilterInput>;
  toDoId?: InputMaybe<IntOperationFilterInput>;
  type?: InputMaybe<ToDoRecurringTypeFilterInput>;
  typeId?: InputMaybe<IntOperationFilterInput>;
};

export type ToDoRecurringTypeFilterInput = {
  and?: InputMaybe<Array<ToDoRecurringTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ToDoRecurringTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  toDoRecurringPatterns?: InputMaybe<ListFilterInputTypeOfToDoRecurringPatternFilterInput>;
};

export type ToDoStatusFilterInput = {
  and?: InputMaybe<Array<ToDoStatusFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ToDoStatusFilterInput>>;
  toDo?: InputMaybe<ToDoFilterInput>;
  toDoId?: InputMaybe<IntOperationFilterInput>;
  toDoStatusType?: InputMaybe<ToDoStatusTypeFilterInput>;
  typeId?: InputMaybe<IntOperationFilterInput>;
};

export type ToDoStatusTypeFilterInput = {
  and?: InputMaybe<Array<ToDoStatusTypeFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ToDoStatusTypeFilterInput>>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  toDoStatuses?: InputMaybe<ListFilterInputTypeOfToDoStatusFilterInput>;
};

export type TodoFormInput = {
  contactId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['DateTime']['input']>;
  isFullDay?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  primaryEmployeeId?: InputMaybe<Scalars['Int']['input']>;
  priorityId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  startTime?: InputMaybe<Scalars['TimeSpan']['input']>;
  title: Scalars['String']['input'];
};

export type ToggleModuleEnabledError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type ToggleModuleEnabledInput = {
  isEnabled: Scalars['Boolean']['input'];
  moduleId: Scalars['Int']['input'];
};

export type ToggleModuleEnabledPayload = {
  __typename?: 'ToggleModuleEnabledPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<ToggleModuleEnabledError>>;
};

export type UpdateAccessToEntityRowError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateAccessToEntityRowInput = {
  accessRowId: Scalars['Int']['input'];
  canRead: Scalars['Boolean']['input'];
  canWrite: Scalars['Boolean']['input'];
};

export type UpdateAccessToEntityRowPayload = {
  __typename?: 'UpdateAccessToEntityRowPayload';
  errors?: Maybe<Array<UpdateAccessToEntityRowError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateAddressInput = {
  addressId: Scalars['Int']['input'];
  addressInput: AddressInput;
};

export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  errors?: Maybe<Array<UpdateAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateBenefitError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateBenefitFormInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
};

export type UpdateBenefitInput = {
  benefitId: Scalars['Int']['input'];
  formInput: UpdateBenefitFormInput;
};

export type UpdateBenefitPayload = {
  __typename?: 'UpdateBenefitPayload';
  errors?: Maybe<Array<UpdateBenefitError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateCertificateTypeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateCertificateTypeInput = {
  certificateTypeId: Scalars['Int']['input'];
  input: CertificateTypeInput;
};

export type UpdateCertificateTypePayload = {
  __typename?: 'UpdateCertificateTypePayload';
  errors?: Maybe<Array<UpdateCertificateTypeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateCompetenceTypeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateCompetenceTypeInput = {
  competenceTypeId: Scalars['Int']['input'];
  input: CompetenceTypeInput;
};

export type UpdateCompetenceTypePayload = {
  __typename?: 'UpdateCompetenceTypePayload';
  errors?: Maybe<Array<UpdateCompetenceTypeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateCustomFieldForEntityError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateCustomFieldForEntityInput = {
  fieldId: Scalars['Int']['input'];
  fieldInput: CustomFieldInput;
};

export type UpdateCustomFieldForEntityPayload = {
  __typename?: 'UpdateCustomFieldForEntityPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateCustomFieldForEntityError>>;
};

export type UpdateDepartmentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateDepartmentInput = {
  departmentId: Scalars['Int']['input'];
  input: DepartmentInput;
};

export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  errors?: Maybe<Array<UpdateDepartmentError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeAboutError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAboutInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeAboutPayload = {
  __typename?: 'UpdateEmployeeAboutPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAboutError>>;
};

export type UpdateEmployeeAbsenceBalanceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceBalanceInput = {
  employeeId: Scalars['Int']['input'];
  input: EmployeeAbsenceBalanceInput;
  year: Scalars['Int']['input'];
};

export type UpdateEmployeeAbsenceBalancePayload = {
  __typename?: 'UpdateEmployeeAbsenceBalancePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAbsenceBalanceError>>;
};

export type UpdateEmployeeAbsenceDescriptionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceDescriptionInput = {
  description: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeAbsenceDescriptionPayload = {
  __typename?: 'UpdateEmployeeAbsenceDescriptionPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAbsenceDescriptionError>>;
};

export type UpdateEmployeeAbsenceEndDateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceEndDateInput = {
  endDate: Scalars['Date']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeAbsenceEndDatePayload = {
  __typename?: 'UpdateEmployeeAbsenceEndDatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAbsenceEndDateError>>;
};

export type UpdateEmployeeAbsenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceInput = {
  absenceId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: AbsenceInput;
};

export type UpdateEmployeeAbsencePayload = {
  __typename?: 'UpdateEmployeeAbsencePayload';
  errors?: Maybe<Array<UpdateEmployeeAbsenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeAbsenceStartDateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceStartDateInput = {
  id: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type UpdateEmployeeAbsenceStartDatePayload = {
  __typename?: 'UpdateEmployeeAbsenceStartDatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAbsenceStartDateError>>;
};

export type UpdateEmployeeAbsenceStatusError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceStatusInput = {
  id: Scalars['Int']['input'];
  status: AbsenceStatusInput;
};

export type UpdateEmployeeAbsenceStatusPayload = {
  __typename?: 'UpdateEmployeeAbsenceStatusPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAbsenceStatusError>>;
};

export type UpdateEmployeeAbsenceTypeIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAbsenceTypeIdInput = {
  id: Scalars['Int']['input'];
  typeId: Scalars['Int']['input'];
};

export type UpdateEmployeeAbsenceTypeIdPayload = {
  __typename?: 'UpdateEmployeeAbsenceTypeIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAbsenceTypeIdError>>;
};

export type UpdateEmployeeAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAddressInput = {
  addressId: Scalars['Int']['input'];
  addressInput: AddressInput;
  employeeId: Scalars['Int']['input'];
};

export type UpdateEmployeeAddressPayload = {
  __typename?: 'UpdateEmployeeAddressPayload';
  errors?: Maybe<Array<UpdateEmployeeAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeAdminError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAdminInput = {
  admin: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeAdminPayload = {
  __typename?: 'UpdateEmployeeAdminPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeAdminError>>;
};

export type UpdateEmployeeAllergyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeAllergyInput = {
  allergyId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  input: AllergyInput;
};

export type UpdateEmployeeAllergyPayload = {
  __typename?: 'UpdateEmployeeAllergyPayload';
  errors?: Maybe<Array<UpdateEmployeeAllergyError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeBankAccountNumberError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeBankAccountNumberInput = {
  bankAccountNumber: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeBankAccountNumberPayload = {
  __typename?: 'UpdateEmployeeBankAccountNumberPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeBankAccountNumberError>>;
};

export type UpdateEmployeeBirthDateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeBirthDateInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeBirthDatePayload = {
  __typename?: 'UpdateEmployeeBirthDatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeBirthDateError>>;
};

export type UpdateEmployeeCellularError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeCellularInput = {
  cellular: PhoneNumberInput;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeCellularPayload = {
  __typename?: 'UpdateEmployeeCellularPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeCellularError>>;
};

export type UpdateEmployeeCertificateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeCertificateInput = {
  certificateId: Scalars['Int']['input'];
  input: CertificateInput;
};

export type UpdateEmployeeCertificatePayload = {
  __typename?: 'UpdateEmployeeCertificatePayload';
  errors?: Maybe<Array<UpdateEmployeeCertificateError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeCompetenceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeCompetenceInput = {
  competenceId: Scalars['Int']['input'];
  input: CompetenceInput;
};

export type UpdateEmployeeCompetencePayload = {
  __typename?: 'UpdateEmployeeCompetencePayload';
  errors?: Maybe<Array<UpdateEmployeeCompetenceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeDefaultAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeDefaultAddressInput = {
  addressId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
};

export type UpdateEmployeeDefaultAddressPayload = {
  __typename?: 'UpdateEmployeeDefaultAddressPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeDefaultAddressError>>;
};

export type UpdateEmployeeDefaultProductError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeDefaultProductInput = {
  defaultProductId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeDefaultProductPayload = {
  __typename?: 'UpdateEmployeeDefaultProductPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeDefaultProductError>>;
};

export type UpdateEmployeeDeviceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeDeviceInput = {
  deviceId: Scalars['Int']['input'];
  input: DeviceInput;
};

export type UpdateEmployeeDevicePayload = {
  __typename?: 'UpdateEmployeeDevicePayload';
  errors?: Maybe<Array<UpdateEmployeeDeviceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeEducationError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEducationInput = {
  educationId: Scalars['Int']['input'];
  input: EducationInput;
};

export type UpdateEmployeeEducationPayload = {
  __typename?: 'UpdateEmployeeEducationPayload';
  errors?: Maybe<Array<UpdateEmployeeEducationError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeEmailError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmailInput = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeEmailPayload = {
  __typename?: 'UpdateEmployeeEmailPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmailError>>;
};

export type UpdateEmployeeEmploymentDescriptionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentDescriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeEmploymentDescriptionPayload = {
  __typename?: 'UpdateEmployeeEmploymentDescriptionPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentDescriptionError>>;
};

export type UpdateEmployeeEmploymentEmploymentRatePercentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentEmploymentRatePercentInput = {
  employmentRatePercent: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeEmploymentEmploymentRatePercentPayload = {
  __typename?: 'UpdateEmployeeEmploymentEmploymentRatePercentPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentEmploymentRatePercentError>>;
};

export type UpdateEmployeeEmploymentEmploymentTypeIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentEmploymentTypeIdInput = {
  employmentTypeId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeEmploymentEmploymentTypeIdPayload = {
  __typename?: 'UpdateEmployeeEmploymentEmploymentTypeIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentEmploymentTypeIdError>>;
};

export type UpdateEmployeeEmploymentEndDateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentEndDateInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeEmploymentEndDatePayload = {
  __typename?: 'UpdateEmployeeEmploymentEndDatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentEndDateError>>;
};

export type UpdateEmployeeEmploymentError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentInput = {
  employmentId: Scalars['Int']['input'];
  input: EmploymentInput;
};

export type UpdateEmployeeEmploymentPayload = {
  __typename?: 'UpdateEmployeeEmploymentPayload';
  errors?: Maybe<Array<UpdateEmployeeEmploymentError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeEmploymentPositionIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentPositionIdInput = {
  id: Scalars['Int']['input'];
  positionId: Scalars['Int']['input'];
};

export type UpdateEmployeeEmploymentPositionIdPayload = {
  __typename?: 'UpdateEmployeeEmploymentPositionIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentPositionIdError>>;
};

export type UpdateEmployeeEmploymentStartDateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentStartDateInput = {
  id: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type UpdateEmployeeEmploymentStartDatePayload = {
  __typename?: 'UpdateEmployeeEmploymentStartDatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentStartDateError>>;
};

export type UpdateEmployeeEmploymentWageTypeIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentWageTypeIdInput = {
  id: Scalars['Int']['input'];
  wageTypeId: Scalars['Int']['input'];
};

export type UpdateEmployeeEmploymentWageTypeIdPayload = {
  __typename?: 'UpdateEmployeeEmploymentWageTypeIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentWageTypeIdError>>;
};

export type UpdateEmployeeEmploymentWageValueError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeEmploymentWageValueInput = {
  id: Scalars['Int']['input'];
  wageValue?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployeeEmploymentWageValuePayload = {
  __typename?: 'UpdateEmployeeEmploymentWageValuePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeEmploymentWageValueError>>;
};

export type UpdateEmployeeExpenseManagerIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeExpenseManagerIdInput = {
  expenseManagerId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeExpenseManagerIdPayload = {
  __typename?: 'UpdateEmployeeExpenseManagerIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeExpenseManagerIdError>>;
};

export type UpdateEmployeeExternalIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeExternalIdInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeExternalIdPayload = {
  __typename?: 'UpdateEmployeeExternalIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeExternalIdError>>;
};

export type UpdateEmployeeExternalSystemIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeExternalSystemIdInput = {
  externalSystemId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeExternalSystemIdPayload = {
  __typename?: 'UpdateEmployeeExternalSystemIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeExternalSystemIdError>>;
};

export type UpdateEmployeeFamilyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeFamilyInput = {
  employeeId: Scalars['Int']['input'];
  familyId: Scalars['Int']['input'];
  input: FamilyInput;
};

export type UpdateEmployeeFamilyPayload = {
  __typename?: 'UpdateEmployeeFamilyPayload';
  errors?: Maybe<Array<UpdateEmployeeFamilyError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateEmployeeFirstNameError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeFirstNameInput = {
  firstName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeFirstNamePayload = {
  __typename?: 'UpdateEmployeeFirstNamePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeFirstNameError>>;
};

export type UpdateEmployeeGenderIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeGenderIdInput = {
  genderId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEmployeeGenderIdPayload = {
  __typename?: 'UpdateEmployeeGenderIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeGenderIdError>>;
};

export type UpdateEmployeeInitialsError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeInitialsInput = {
  id: Scalars['Int']['input'];
  initials: Scalars['String']['input'];
};

export type UpdateEmployeeInitialsPayload = {
  __typename?: 'UpdateEmployeeInitialsPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeInitialsError>>;
};

export type UpdateEmployeeJobDescriptionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeJobDescriptionInput = {
  id: Scalars['Int']['input'];
  jobDescription?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployeeJobDescriptionPayload = {
  __typename?: 'UpdateEmployeeJobDescriptionPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeJobDescriptionError>>;
};

export type UpdateEmployeeKmHomeWorkError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeKmHomeWorkInput = {
  id: Scalars['Int']['input'];
  kmHomeWork: Scalars['Int']['input'];
};

export type UpdateEmployeeKmHomeWorkPayload = {
  __typename?: 'UpdateEmployeeKmHomeWorkPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeKmHomeWorkError>>;
};

export type UpdateEmployeeLanguageIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeLanguageIdInput = {
  id: Scalars['Int']['input'];
  languageId: Scalars['Int']['input'];
};

export type UpdateEmployeeLanguageIdPayload = {
  __typename?: 'UpdateEmployeeLanguageIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeLanguageIdError>>;
};

export type UpdateEmployeeLastNameError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeLastNameInput = {
  id: Scalars['Int']['input'];
  lastName: Scalars['String']['input'];
};

export type UpdateEmployeeLastNamePayload = {
  __typename?: 'UpdateEmployeeLastNamePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeLastNameError>>;
};

export type UpdateEmployeeManagerIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeManagerIdInput = {
  id: Scalars['Int']['input'];
  managerId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeManagerIdPayload = {
  __typename?: 'UpdateEmployeeManagerIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeManagerIdError>>;
};

export type UpdateEmployeeMaritalStatusTypeIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeMaritalStatusTypeIdInput = {
  id: Scalars['Int']['input'];
  maritalStatusTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeMaritalStatusTypeIdPayload = {
  __typename?: 'UpdateEmployeeMaritalStatusTypeIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeMaritalStatusTypeIdError>>;
};

export type UpdateEmployeeNationalityIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeNationalityIdInput = {
  id: Scalars['Int']['input'];
  nationalityId: Scalars['Int']['input'];
};

export type UpdateEmployeeNationalityIdPayload = {
  __typename?: 'UpdateEmployeeNationalityIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeNationalityIdError>>;
};

export type UpdateEmployeeOrganisationDepartmentIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeOrganisationDepartmentIdInput = {
  id: Scalars['Int']['input'];
  organisationDepartmentId: Scalars['Int']['input'];
};

export type UpdateEmployeeOrganisationDepartmentIdPayload = {
  __typename?: 'UpdateEmployeeOrganisationDepartmentIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeOrganisationDepartmentIdError>>;
};

export type UpdateEmployeeOrganisationIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeOrganisationIdInput = {
  id: Scalars['Int']['input'];
  organisationId: Scalars['Int']['input'];
};

export type UpdateEmployeeOrganisationIdPayload = {
  __typename?: 'UpdateEmployeeOrganisationIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeOrganisationIdError>>;
};

export type UpdateEmployeePhoneError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeePhoneInput = {
  id: Scalars['Int']['input'];
  phone: PhoneNumberInput;
};

export type UpdateEmployeePhonePayload = {
  __typename?: 'UpdateEmployeePhonePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeePhoneError>>;
};

export type UpdateEmployeeProfilePictureError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeProfilePictureInput = {
  id: Scalars['Int']['input'];
  profilePicture: Scalars['String']['input'];
};

export type UpdateEmployeeProfilePicturePayload = {
  __typename?: 'UpdateEmployeeProfilePicturePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeProfilePictureError>>;
};

export type UpdateEmployeeSizeFeetIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeSizeFeetIdInput = {
  id: Scalars['Int']['input'];
  sizeFeetId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeSizeFeetIdPayload = {
  __typename?: 'UpdateEmployeeSizeFeetIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeSizeFeetIdError>>;
};

export type UpdateEmployeeSizeLegsIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeSizeLegsIdInput = {
  id: Scalars['Int']['input'];
  sizeLegsId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeSizeLegsIdPayload = {
  __typename?: 'UpdateEmployeeSizeLegsIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeSizeLegsIdError>>;
};

export type UpdateEmployeeSizeTorsoIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeSizeTorsoIdInput = {
  id: Scalars['Int']['input'];
  sizeTorsoId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeSizeTorsoIdPayload = {
  __typename?: 'UpdateEmployeeSizeTorsoIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeSizeTorsoIdError>>;
};

export type UpdateEmployeeSizeWaistIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeSizeWaistIdInput = {
  id: Scalars['Int']['input'];
  sizeWaistId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeSizeWaistIdPayload = {
  __typename?: 'UpdateEmployeeSizeWaistIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeSizeWaistIdError>>;
};

export type UpdateEmployeeSocialSecurityNumberError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeSocialSecurityNumberInput = {
  id: Scalars['Int']['input'];
  socialSecurityNumber: Scalars['String']['input'];
};

export type UpdateEmployeeSocialSecurityNumberPayload = {
  __typename?: 'UpdateEmployeeSocialSecurityNumberPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeSocialSecurityNumberError>>;
};

export type UpdateEmployeeStartDateError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeStartDateInput = {
  id: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type UpdateEmployeeStartDatePayload = {
  __typename?: 'UpdateEmployeeStartDatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeStartDateError>>;
};

export type UpdateEmployeeTimeSheetManagerIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeTimeSheetManagerIdInput = {
  id: Scalars['Int']['input'];
  timeSheetManagerId: Scalars['Int']['input'];
};

export type UpdateEmployeeTimeSheetManagerIdPayload = {
  __typename?: 'UpdateEmployeeTimeSheetManagerIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeTimeSheetManagerIdError>>;
};

export type UpdateEmployeeTrialMonthsError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEmployeeTrialMonthsInput = {
  id: Scalars['Int']['input'];
  trialMonths: Scalars['Int']['input'];
};

export type UpdateEmployeeTrialMonthsPayload = {
  __typename?: 'UpdateEmployeeTrialMonthsPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEmployeeTrialMonthsError>>;
};

export type UpdateEntityEventSubscriptionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateEntityEventSubscriptionInput = {
  entity: AxosEntities;
  instanceId: Scalars['Int']['input'];
  subscribe: Scalars['Boolean']['input'];
};

export type UpdateEntityEventSubscriptionPayload = {
  __typename?: 'UpdateEntityEventSubscriptionPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateEntityEventSubscriptionError>>;
};

export type UpdateIdeaBodyError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateIdeaBodyInput = {
  body: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateIdeaBodyPayload = {
  __typename?: 'UpdateIdeaBodyPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateIdeaBodyError>>;
};

export type UpdateIdeaParentIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateIdeaParentIdInput = {
  id: Scalars['Int']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateIdeaParentIdPayload = {
  __typename?: 'UpdateIdeaParentIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateIdeaParentIdError>>;
};

export type UpdateIdeaStatusTypeIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateIdeaStatusTypeIdInput = {
  id: Scalars['Int']['input'];
  statusTypeId: Scalars['Int']['input'];
};

export type UpdateIdeaStatusTypeIdPayload = {
  __typename?: 'UpdateIdeaStatusTypeIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateIdeaStatusTypeIdError>>;
};

export type UpdateIdeaTitleError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateIdeaTitleInput = {
  id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type UpdateIdeaTitlePayload = {
  __typename?: 'UpdateIdeaTitlePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateIdeaTitleError>>;
};

export type UpdateInsuranceError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateInsuranceFormInput = {
  contactId: Scalars['Int']['input'];
  coverage?: InputMaybe<Scalars['String']['input']>;
  deductibleAmount?: InputMaybe<Scalars['Decimal']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  helpline?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organisationId: Scalars['Int']['input'];
  policyEndDate?: InputMaybe<Scalars['Date']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  policyStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateInsuranceInput = {
  formInput: UpdateInsuranceFormInput;
  insuranceId: Scalars['Int']['input'];
};

export type UpdateInsurancePayload = {
  __typename?: 'UpdateInsurancePayload';
  errors?: Maybe<Array<UpdateInsuranceError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateLanguageIsActiveError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateLanguageIsActiveInput = {
  isActive: Scalars['Boolean']['input'];
  languageId: Scalars['Int']['input'];
};

export type UpdateLanguageIsActivePayload = {
  __typename?: 'UpdateLanguageIsActivePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateLanguageIsActiveError>>;
};

export type UpdateMasterModuleError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateMasterModuleInput = {
  moduleId: Scalars['Int']['input'];
  moduleInput: ModuleInput;
};

export type UpdateMasterModulePayload = {
  __typename?: 'UpdateMasterModulePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateMasterModuleError>>;
};

export type UpdateOfficeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOfficeInput = {
  input: OfficeInput;
  officeId: Scalars['Int']['input'];
};

export type UpdateOfficePayload = {
  __typename?: 'UpdateOfficePayload';
  errors?: Maybe<Array<UpdateOfficeError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateOkrObjectiveError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOkrObjectiveInput = {
  employeeId: Scalars['Int']['input'];
  input: OkrObjectiveInput;
  okrObjectiveId: Scalars['Int']['input'];
};

export type UpdateOkrObjectivePayload = {
  __typename?: 'UpdateOkrObjectivePayload';
  errors?: Maybe<Array<UpdateOkrObjectiveError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateOrganisationAccountIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationAccountIdInput = {
  accountId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationAccountIdPayload = {
  __typename?: 'UpdateOrganisationAccountIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationAccountIdError>>;
};

export type UpdateOrganisationAddressError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationAddressInput = {
  addressId: Scalars['Int']['input'];
  addressInput: AddressInput;
  organisationId: Scalars['Int']['input'];
};

export type UpdateOrganisationAddressPayload = {
  __typename?: 'UpdateOrganisationAddressPayload';
  errors?: Maybe<Array<UpdateOrganisationAddressError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateOrganisationAdministrationFeeCalculationRuleError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationAdministrationFeeCalculationRuleInput = {
  administrationFeeCalculationRule: AdministrationFeeCalculationRule;
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationAdministrationFeeCalculationRulePayload = {
  __typename?: 'UpdateOrganisationAdministrationFeeCalculationRulePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationAdministrationFeeCalculationRuleError>>;
};

export type UpdateOrganisationAdministrationFeeCalculationRuleThresholdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationAdministrationFeeCalculationRuleThresholdInput = {
  administrationFeeCalculationRuleThreshold: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationAdministrationFeeCalculationRuleThresholdPayload = {
  __typename?: 'UpdateOrganisationAdministrationFeeCalculationRuleThresholdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationAdministrationFeeCalculationRuleThresholdError>>;
};

export type UpdateOrganisationAdministrationFeeProductIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationAdministrationFeeProductIdInput = {
  administrationFeeProductId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationAdministrationFeeProductIdPayload = {
  __typename?: 'UpdateOrganisationAdministrationFeeProductIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationAdministrationFeeProductIdError>>;
};

export type UpdateOrganisationEmailError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationEmailFooterColorError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationEmailFooterColorInput = {
  emailFooterColor: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationEmailFooterColorPayload = {
  __typename?: 'UpdateOrganisationEmailFooterColorPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationEmailFooterColorError>>;
};

export type UpdateOrganisationEmailHeaderColorError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationEmailHeaderColorInput = {
  emailHeaderColor: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationEmailHeaderColorPayload = {
  __typename?: 'UpdateOrganisationEmailHeaderColorPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationEmailHeaderColorError>>;
};

export type UpdateOrganisationEmailInput = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationEmailPayload = {
  __typename?: 'UpdateOrganisationEmailPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationEmailError>>;
};

export type UpdateOrganisationExternalErpIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationExternalErpIdInput = {
  externalERPId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateOrganisationExternalErpIdPayload = {
  __typename?: 'UpdateOrganisationExternalErpIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationExternalErpIdError>>;
};

export type UpdateOrganisationLanguageIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationLanguageIdInput = {
  id: Scalars['Int']['input'];
  languageId: Scalars['Int']['input'];
};

export type UpdateOrganisationLanguageIdPayload = {
  __typename?: 'UpdateOrganisationLanguageIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationLanguageIdError>>;
};

export type UpdateOrganisationLegalNameError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationLegalNameInput = {
  id: Scalars['Int']['input'];
  legalName: Scalars['String']['input'];
};

export type UpdateOrganisationLegalNamePayload = {
  __typename?: 'UpdateOrganisationLegalNamePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationLegalNameError>>;
};

export type UpdateOrganisationLegalNoError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationLegalNoInput = {
  id: Scalars['Int']['input'];
  legalNo: Scalars['String']['input'];
};

export type UpdateOrganisationLegalNoPayload = {
  __typename?: 'UpdateOrganisationLegalNoPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationLegalNoError>>;
};

export type UpdateOrganisationLogoError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationLogoInput = {
  id: Scalars['Int']['input'];
  logo: Scalars['String']['input'];
};

export type UpdateOrganisationLogoPayload = {
  __typename?: 'UpdateOrganisationLogoPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationLogoError>>;
};

export type UpdateOrganisationManagerIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationManagerIdInput = {
  id: Scalars['Int']['input'];
  managerId: Scalars['Int']['input'];
};

export type UpdateOrganisationManagerIdPayload = {
  __typename?: 'UpdateOrganisationManagerIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationManagerIdError>>;
};

export type UpdateOrganisationNameError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationNameInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateOrganisationNamePayload = {
  __typename?: 'UpdateOrganisationNamePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationNameError>>;
};

export type UpdateOrganisationNumberingError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationNumberingInput = {
  entityId: Scalars['Int']['input'];
  numberingInput: NumberingInput;
  organisationId: Scalars['Int']['input'];
};

export type UpdateOrganisationNumberingPayload = {
  __typename?: 'UpdateOrganisationNumberingPayload';
  errors?: Maybe<Array<UpdateOrganisationNumberingError>>;
  string?: Maybe<Scalars['String']['output']>;
};

export type UpdateOrganisationPayTermIdError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationPayTermIdInput = {
  id: Scalars['Int']['input'];
  payTermId: Scalars['Int']['input'];
};

export type UpdateOrganisationPayTermIdPayload = {
  __typename?: 'UpdateOrganisationPayTermIdPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationPayTermIdError>>;
};

export type UpdateOrganisationPhoneError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationPhoneInput = {
  id: Scalars['Int']['input'];
  phone: PhoneNumberInput;
};

export type UpdateOrganisationPhonePayload = {
  __typename?: 'UpdateOrganisationPhonePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationPhoneError>>;
};

export type UpdateOrganisationPrimaryColorCodeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationPrimaryColorCodeInput = {
  id: Scalars['Int']['input'];
  primaryColorCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganisationPrimaryColorCodePayload = {
  __typename?: 'UpdateOrganisationPrimaryColorCodePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationPrimaryColorCodeError>>;
};

export type UpdateOrganisationSecondaryColorCodeError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationSecondaryColorCodeInput = {
  id: Scalars['Int']['input'];
  secondaryColorCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganisationSecondaryColorCodePayload = {
  __typename?: 'UpdateOrganisationSecondaryColorCodePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationSecondaryColorCodeError>>;
};

export type UpdateOrganisationSmsSenderNameError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationSmsSenderNameInput = {
  id: Scalars['Int']['input'];
  smsSenderName: Scalars['String']['input'];
};

export type UpdateOrganisationSmsSenderNamePayload = {
  __typename?: 'UpdateOrganisationSmsSenderNamePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationSmsSenderNameError>>;
};

export type UpdateOrganisationSymbolError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationSymbolInput = {
  id: Scalars['Int']['input'];
  symbol: Scalars['String']['input'];
};

export type UpdateOrganisationSymbolPayload = {
  __typename?: 'UpdateOrganisationSymbolPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationSymbolError>>;
};

export type UpdateOrganisationTemplateLogoError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateOrganisationTemplateLogoInput = {
  id: Scalars['Int']['input'];
  templateLogo: Scalars['String']['input'];
};

export type UpdateOrganisationTemplateLogoPayload = {
  __typename?: 'UpdateOrganisationTemplateLogoPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<UpdateOrganisationTemplateLogoError>>;
};

export type UpdatePositionError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdatePositionFormInput = {
  defaultJobDescription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdatePositionInput = {
  input: UpdatePositionFormInput;
  positionId: Scalars['Int']['input'];
};

export type UpdatePositionPayload = {
  __typename?: 'UpdatePositionPayload';
  errors?: Maybe<Array<UpdatePositionError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdatePowerGridError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdatePowerGridInput = {
  powerGridId: Scalars['Int']['input'];
  powerGridInput: PowerGridInput;
};

export type UpdatePowerGridPayload = {
  __typename?: 'UpdatePowerGridPayload';
  errors?: Maybe<Array<UpdatePowerGridError>>;
  powerGridDto?: Maybe<PowerGridDto>;
};

export type UpdateResponsibilityError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateResponsibilityInput = {
  responsibilityId: Scalars['Int']['input'];
  responsibilityInput: ResponsibilityInput;
};

export type UpdateResponsibilityPayload = {
  __typename?: 'UpdateResponsibilityPayload';
  errors?: Maybe<Array<UpdateResponsibilityError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateResponsibilityTaskError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateResponsibilityTaskInput = {
  responsibilityTaskInput: ResponsibilityTaskInput;
  taskId: Scalars['Int']['input'];
};

export type UpdateResponsibilityTaskPayload = {
  __typename?: 'UpdateResponsibilityTaskPayload';
  errors?: Maybe<Array<UpdateResponsibilityTaskError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateTeamError = EntityNotFoundError | EntityValidationError | NoRowAccessError;

export type UpdateTeamFormInput = {
  managerId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTeamInput = {
  input: UpdateTeamFormInput;
  teamId: Scalars['Int']['input'];
};

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  errors?: Maybe<Array<UpdateTeamError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type UpdateTodoInput = {
  formInput: TodoFormInput;
  todoId: Scalars['Int']['input'];
};

export type UpdateTodoPayload = {
  __typename?: 'UpdateTodoPayload';
  int?: Maybe<Scalars['Int']['output']>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export enum VerticalAlignType {
  Bottom = 'BOTTOM',
  Middle = 'MIDDLE',
  Top = 'TOP'
}

export type VerticalAlignTypeOperationFilterInput = {
  eq?: InputMaybe<VerticalAlignType>;
  in?: InputMaybe<Array<VerticalAlignType>>;
  neq?: InputMaybe<VerticalAlignType>;
  nin?: InputMaybe<Array<VerticalAlignType>>;
};

export type WorkflowTemplateFilterInput = {
  and?: InputMaybe<Array<WorkflowTemplateFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  department?: InputMaybe<OrganisationDepartmentFilterInput>;
  departmentId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  entity?: InputMaybe<EntityFilterInput>;
  entityId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  officeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowTemplateFilterInput>>;
  organisation?: InputMaybe<OrganisationFilterInput>;
  organisationId?: InputMaybe<IntOperationFilterInput>;
  organisationOffice?: InputMaybe<OrganisationOfficeFilterInput>;
  team?: InputMaybe<OrganisationTeamFilterInput>;
  teamId?: InputMaybe<IntOperationFilterInput>;
  workflowTemplateItems?: InputMaybe<ListFilterInputTypeOfWorkflowTemplateItemFilterInput>;
};

export type WorkflowTemplateItemFilterInput = {
  and?: InputMaybe<Array<WorkflowTemplateItemFilterInput>>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowTemplateItemFilterInput>>;
  parentItemId?: InputMaybe<IntOperationFilterInput>;
  parentWorkflowTemplateItem?: InputMaybe<WorkflowTemplateItemFilterInput>;
  sortIndex?: InputMaybe<IntOperationFilterInput>;
  templateId?: InputMaybe<IntOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  workflowTemplate?: InputMaybe<WorkflowTemplateFilterInput>;
  workflowTemplateItemChildren?: InputMaybe<ListFilterInputTypeOfWorkflowTemplateItemFilterInput>;
};

export type ZipCodes = {
  __typename?: 'ZipCodes';
  city: Scalars['String']['output'];
  council: Council;
  councilId: Scalars['Int']['output'];
  createdBy: Scalars['Int']['output'];
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  zipCode: Scalars['String']['output'];
};

/** A segment of a collection. */
export type ZipCodesCollectionSegment = {
  __typename?: 'ZipCodesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ZipCodes>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ZipCodesFilterInput = {
  addresses?: InputMaybe<ListFilterInputTypeOfAddressFilterInput>;
  and?: InputMaybe<Array<ZipCodesFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  council?: InputMaybe<CouncilFilterInput>;
  councilId?: InputMaybe<IntOperationFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ZipCodesFilterInput>>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type ZipCodesSortInput = {
  city?: InputMaybe<SortEnumType>;
  council?: InputMaybe<CouncilSortInput>;
  councilId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
};

export const API_TYPES = {
  AbsenceInput: 'AbsenceInput',
  AbsenceStatusInput: 'AbsenceStatusInput',
  AccessDto: 'AccessDto',
  AccessOptions: 'AccessOptions',
  Account: 'Account',
  AccountFilterInput: 'AccountFilterInput',
  AccountPrimaryContact: 'AccountPrimaryContact',
  AccountPrimaryContactFilterInput: 'AccountPrimaryContactFilterInput',
  AccountPrimaryEmployee: 'AccountPrimaryEmployee',
  AccountPrimaryEmployeeFilterInput: 'AccountPrimaryEmployeeFilterInput',
  AccountSocialHandleFilterInput: 'AccountSocialHandleFilterInput',
  AccountSortInput: 'AccountSortInput',
  AccountTypeFilterInput: 'AccountTypeFilterInput',
  AccountsCollectionSegment: 'AccountsCollectionSegment',
  ActivateEmployeeError: 'ActivateEmployeeError',
  ActivateEmployeeInput: 'ActivateEmployeeInput',
  ActivateEmployeePayload: 'ActivateEmployeePayload',
  AddDepartmentAccessToEntityRowError: 'AddDepartmentAccessToEntityRowError',
  AddDepartmentAccessToEntityRowInput: 'AddDepartmentAccessToEntityRowInput',
  AddDepartmentAccessToEntityRowPayload: 'AddDepartmentAccessToEntityRowPayload',
  AddEmployeeAccessToEntityRowError: 'AddEmployeeAccessToEntityRowError',
  AddEmployeeAccessToEntityRowInput: 'AddEmployeeAccessToEntityRowInput',
  AddEmployeeAccessToEntityRowPayload: 'AddEmployeeAccessToEntityRowPayload',
  AddEmployeeToBenefitError: 'AddEmployeeToBenefitError',
  AddEmployeeToBenefitInput: 'AddEmployeeToBenefitInput',
  AddEmployeeToBenefitPayload: 'AddEmployeeToBenefitPayload',
  AddEmployeeToInsuranceError: 'AddEmployeeToInsuranceError',
  AddEmployeeToInsuranceInput: 'AddEmployeeToInsuranceInput',
  AddEmployeeToInsurancePayload: 'AddEmployeeToInsurancePayload',
  AddEmployeeToOfficeError: 'AddEmployeeToOfficeError',
  AddEmployeeToOfficeInput: 'AddEmployeeToOfficeInput',
  AddEmployeeToOfficePayload: 'AddEmployeeToOfficePayload',
  AddEmployeeToTeamError: 'AddEmployeeToTeamError',
  AddEmployeeToTeamInput: 'AddEmployeeToTeamInput',
  AddEmployeeToTeamPayload: 'AddEmployeeToTeamPayload',
  AddOfficeAccessToEntityRowError: 'AddOfficeAccessToEntityRowError',
  AddOfficeAccessToEntityRowInput: 'AddOfficeAccessToEntityRowInput',
  AddOfficeAccessToEntityRowPayload: 'AddOfficeAccessToEntityRowPayload',
  AddOrganisationAccessToEntityRowError: 'AddOrganisationAccessToEntityRowError',
  AddOrganisationAccessToEntityRowInput: 'AddOrganisationAccessToEntityRowInput',
  AddOrganisationAccessToEntityRowPayload: 'AddOrganisationAccessToEntityRowPayload',
  AddTeamAccessToEntityRowError: 'AddTeamAccessToEntityRowError',
  AddTeamAccessToEntityRowInput: 'AddTeamAccessToEntityRowInput',
  AddTeamAccessToEntityRowPayload: 'AddTeamAccessToEntityRowPayload',
  Address: 'Address',
  AddressFilterInput: 'AddressFilterInput',
  AddressInput: 'AddressInput',
  AddressSortInput: 'AddressSortInput',
  AddressType: 'AddressType',
  AddressTypeFilterInput: 'AddressTypeFilterInput',
  AddressTypeSortInput: 'AddressTypeSortInput',
  AddressesCollectionSegment: 'AddressesCollectionSegment',
  AdministrationFeeCalcRuleDropdown: 'AdministrationFeeCalcRuleDropdown',
  AdministrationFeeCalculationRule: 'AdministrationFeeCalculationRule',
  AdministrationFeeCalculationRuleOperationFilterInput: 'AdministrationFeeCalculationRuleOperationFilterInput',
  AllergiesCollectionSegment: 'AllergiesCollectionSegment',
  AllergyInput: 'AllergyInput',
  AllergyType: 'AllergyType',
  AllergyTypeSortInput: 'AllergyTypeSortInput',
  ApplyAbsenceInput: 'ApplyAbsenceInput',
  ApplyForEmployeeAbsenceError: 'ApplyForEmployeeAbsenceError',
  ApplyForEmployeeAbsenceInput: 'ApplyForEmployeeAbsenceInput',
  ApplyForEmployeeAbsencePayload: 'ApplyForEmployeeAbsencePayload',
  ApplyPolicy: 'ApplyPolicy',
  ApproveAllEmployeeAbsenceBalancesError: 'ApproveAllEmployeeAbsenceBalancesError',
  ApproveAllEmployeeAbsenceBalancesInput: 'ApproveAllEmployeeAbsenceBalancesInput',
  ApproveAllEmployeeAbsenceBalancesPayload: 'ApproveAllEmployeeAbsenceBalancesPayload',
  AuthClientDto: 'AuthClientDto',
  AxosCapabilities: 'AxosCapabilities',
  AxosClient: 'AxosClient',
  AxosClientUser: 'AxosClientUser',
  AxosEmail: 'AxosEmail',
  AxosEmailFilterInput: 'AxosEmailFilterInput',
  AxosEmailSortInput: 'AxosEmailSortInput',
  AxosEntities: 'AxosEntities',
  AxosError: 'AxosError',
  AxosModules: 'AxosModules',
  AxosPhoneNumber: 'AxosPhoneNumber',
  AxosPhoneNumberFilterInput: 'AxosPhoneNumberFilterInput',
  AxosPhoneNumberSortInput: 'AxosPhoneNumberSortInput',
  Boolean: 'Boolean',
  BooleanOperationFilterInput: 'BooleanOperationFilterInput',
  BrregCompanyAddress: 'BrregCompanyAddress',
  BrregCompanyDto: 'BrregCompanyDto',
  Capability: 'Capability',
  CertificateInput: 'CertificateInput',
  CertificateType: 'CertificateType',
  CertificateTypeFilterInput: 'CertificateTypeFilterInput',
  CertificateTypeInput: 'CertificateTypeInput',
  CertificateTypeSortInput: 'CertificateTypeSortInput',
  CertificateTypesCollectionSegment: 'CertificateTypesCollectionSegment',
  ChecklistFilterInput: 'ChecklistFilterInput',
  ClearAccessCacheError: 'ClearAccessCacheError',
  ClearAccessCachePayload: 'ClearAccessCachePayload',
  CollectionSegmentInfo: 'CollectionSegmentInfo',
  CompetenceInput: 'CompetenceInput',
  CompetenceLevel: 'CompetenceLevel',
  CompetenceType: 'CompetenceType',
  CompetenceTypeInput: 'CompetenceTypeInput',
  Contact: 'Contact',
  ContactFilterInput: 'ContactFilterInput',
  ContactSocialHandleFilterInput: 'ContactSocialHandleFilterInput',
  ContactSortInput: 'ContactSortInput',
  ContactsCollectionSegment: 'ContactsCollectionSegment',
  Council: 'Council',
  CouncilFilterInput: 'CouncilFilterInput',
  CouncilSortInput: 'CouncilSortInput',
  CountriesCollectionSegment: 'CountriesCollectionSegment',
  Country: 'Country',
  CountryDropdownDto: 'CountryDropdownDto',
  CountryFilterInput: 'CountryFilterInput',
  CountrySortInput: 'CountrySortInput',
  County: 'County',
  CountyFilterInput: 'CountyFilterInput',
  CountySortInput: 'CountySortInput',
  CreateAddressError: 'CreateAddressError',
  CreateAddressInput: 'CreateAddressInput',
  CreateAddressPayload: 'CreateAddressPayload',
  CreateBenefitError: 'CreateBenefitError',
  CreateBenefitFormInput: 'CreateBenefitFormInput',
  CreateBenefitInput: 'CreateBenefitInput',
  CreateBenefitPayload: 'CreateBenefitPayload',
  CreateCertificateTypeError: 'CreateCertificateTypeError',
  CreateCertificateTypeInput: 'CreateCertificateTypeInput',
  CreateCertificateTypePayload: 'CreateCertificateTypePayload',
  CreateClientError: 'CreateClientError',
  CreateClientInput: 'CreateClientInput',
  CreateClientPayload: 'CreateClientPayload',
  CreateCompetenceTypeError: 'CreateCompetenceTypeError',
  CreateCompetenceTypeInput: 'CreateCompetenceTypeInput',
  CreateCompetenceTypePayload: 'CreateCompetenceTypePayload',
  CreateCustomFieldForEntityError: 'CreateCustomFieldForEntityError',
  CreateCustomFieldForEntityInput: 'CreateCustomFieldForEntityInput',
  CreateCustomFieldForEntityPayload: 'CreateCustomFieldForEntityPayload',
  CreateDepartmentError: 'CreateDepartmentError',
  CreateDepartmentInput: 'CreateDepartmentInput',
  CreateDepartmentPayload: 'CreateDepartmentPayload',
  CreateEmployeeAbsenceError: 'CreateEmployeeAbsenceError',
  CreateEmployeeAbsenceInput: 'CreateEmployeeAbsenceInput',
  CreateEmployeeAbsencePayload: 'CreateEmployeeAbsencePayload',
  CreateEmployeeAddressError: 'CreateEmployeeAddressError',
  CreateEmployeeAddressInput: 'CreateEmployeeAddressInput',
  CreateEmployeeAddressPayload: 'CreateEmployeeAddressPayload',
  CreateEmployeeAllergyError: 'CreateEmployeeAllergyError',
  CreateEmployeeAllergyInput: 'CreateEmployeeAllergyInput',
  CreateEmployeeAllergyPayload: 'CreateEmployeeAllergyPayload',
  CreateEmployeeCertificateError: 'CreateEmployeeCertificateError',
  CreateEmployeeCertificateInput: 'CreateEmployeeCertificateInput',
  CreateEmployeeCertificatePayload: 'CreateEmployeeCertificatePayload',
  CreateEmployeeCompetenceError: 'CreateEmployeeCompetenceError',
  CreateEmployeeCompetenceInput: 'CreateEmployeeCompetenceInput',
  CreateEmployeeCompetencePayload: 'CreateEmployeeCompetencePayload',
  CreateEmployeeDeviceError: 'CreateEmployeeDeviceError',
  CreateEmployeeDeviceInput: 'CreateEmployeeDeviceInput',
  CreateEmployeeDevicePayload: 'CreateEmployeeDevicePayload',
  CreateEmployeeEducationError: 'CreateEmployeeEducationError',
  CreateEmployeeEducationInput: 'CreateEmployeeEducationInput',
  CreateEmployeeEducationPayload: 'CreateEmployeeEducationPayload',
  CreateEmployeeEmploymentError: 'CreateEmployeeEmploymentError',
  CreateEmployeeEmploymentInput: 'CreateEmployeeEmploymentInput',
  CreateEmployeeEmploymentPayload: 'CreateEmployeeEmploymentPayload',
  CreateEmployeeError: 'CreateEmployeeError',
  CreateEmployeeFamilyError: 'CreateEmployeeFamilyError',
  CreateEmployeeFamilyInput: 'CreateEmployeeFamilyInput',
  CreateEmployeeFamilyPayload: 'CreateEmployeeFamilyPayload',
  CreateEmployeeInput: 'CreateEmployeeInput',
  CreateEmployeePayload: 'CreateEmployeePayload',
  CreateIdeaCommentError: 'CreateIdeaCommentError',
  CreateIdeaCommentInput: 'CreateIdeaCommentInput',
  CreateIdeaCommentPayload: 'CreateIdeaCommentPayload',
  CreateIdeaError: 'CreateIdeaError',
  CreateIdeaInput: 'CreateIdeaInput',
  CreateIdeaPayload: 'CreateIdeaPayload',
  CreateInsuranceError: 'CreateInsuranceError',
  CreateInsuranceFormInput: 'CreateInsuranceFormInput',
  CreateInsuranceInput: 'CreateInsuranceInput',
  CreateInsurancePayload: 'CreateInsurancePayload',
  CreateMasterModuleError: 'CreateMasterModuleError',
  CreateMasterModuleInput: 'CreateMasterModuleInput',
  CreateMasterModulePayload: 'CreateMasterModulePayload',
  CreateOfficeError: 'CreateOfficeError',
  CreateOfficeInput: 'CreateOfficeInput',
  CreateOfficePayload: 'CreateOfficePayload',
  CreateOkrObjectiveError: 'CreateOkrObjectiveError',
  CreateOkrObjectiveInput: 'CreateOkrObjectiveInput',
  CreateOkrObjectivePayload: 'CreateOkrObjectivePayload',
  CreateOrganisationAddressError: 'CreateOrganisationAddressError',
  CreateOrganisationAddressInput: 'CreateOrganisationAddressInput',
  CreateOrganisationAddressPayload: 'CreateOrganisationAddressPayload',
  CreateOrganisationError: 'CreateOrganisationError',
  CreateOrganisationInput: 'CreateOrganisationInput',
  CreateOrganisationPayload: 'CreateOrganisationPayload',
  CreatePositionError: 'CreatePositionError',
  CreatePositionInput: 'CreatePositionInput',
  CreatePositionPayload: 'CreatePositionPayload',
  CreatePowerGridError: 'CreatePowerGridError',
  CreatePowerGridInput: 'CreatePowerGridInput',
  CreatePowerGridPayload: 'CreatePowerGridPayload',
  CreateProductError: 'CreateProductError',
  CreateProductInput: 'CreateProductInput',
  CreateProductPayload: 'CreateProductPayload',
  CreateResponsibilityError: 'CreateResponsibilityError',
  CreateResponsibilityInput: 'CreateResponsibilityInput',
  CreateResponsibilityPayload: 'CreateResponsibilityPayload',
  CreateResponsibilityTaskError: 'CreateResponsibilityTaskError',
  CreateResponsibilityTaskInput: 'CreateResponsibilityTaskInput',
  CreateResponsibilityTaskPayload: 'CreateResponsibilityTaskPayload',
  CreateTeamError: 'CreateTeamError',
  CreateTeamInput: 'CreateTeamInput',
  CreateTeamPayload: 'CreateTeamPayload',
  CreateTodoInput: 'CreateTodoInput',
  CreateTodoPayload: 'CreateTodoPayload',
  CustomFieldBooleanFilter: 'CustomFieldBooleanFilter',
  CustomFieldBooleanFilterDto: 'CustomFieldBooleanFilterDto',
  CustomFieldBooleanFilterFilterInput: 'CustomFieldBooleanFilterFilterInput',
  CustomFieldBooleanFilterInput: 'CustomFieldBooleanFilterInput',
  CustomFieldBooleanGridFilterFilterInput: 'CustomFieldBooleanGridFilterFilterInput',
  CustomFieldBooleanGridFilterInput: 'CustomFieldBooleanGridFilterInput',
  CustomFieldDataType: 'CustomFieldDataType',
  CustomFieldDataTypeOperationFilterInput: 'CustomFieldDataTypeOperationFilterInput',
  CustomFieldDateFilter: 'CustomFieldDateFilter',
  CustomFieldDateFilterDto: 'CustomFieldDateFilterDto',
  CustomFieldDateFilterFilterInput: 'CustomFieldDateFilterFilterInput',
  CustomFieldDateFilterInput: 'CustomFieldDateFilterInput',
  CustomFieldDateGridFilterFilterInput: 'CustomFieldDateGridFilterFilterInput',
  CustomFieldDateGridFilterInput: 'CustomFieldDateGridFilterInput',
  CustomFieldFilterConfigDto: 'CustomFieldFilterConfigDto',
  CustomFieldInput: 'CustomFieldInput',
  CustomFieldNumberFilter: 'CustomFieldNumberFilter',
  CustomFieldNumberFilterDto: 'CustomFieldNumberFilterDto',
  CustomFieldNumberFilterFilterInput: 'CustomFieldNumberFilterFilterInput',
  CustomFieldNumberFilterInput: 'CustomFieldNumberFilterInput',
  CustomFieldNumberGridFilterFilterInput: 'CustomFieldNumberGridFilterFilterInput',
  CustomFieldNumberGridFilterInput: 'CustomFieldNumberGridFilterInput',
  CustomFieldSortArgumentInput: 'CustomFieldSortArgumentInput',
  CustomFieldStringFilter: 'CustomFieldStringFilter',
  CustomFieldStringFilterDto: 'CustomFieldStringFilterDto',
  CustomFieldStringFilterFilterInput: 'CustomFieldStringFilterFilterInput',
  CustomFieldStringFilterInput: 'CustomFieldStringFilterInput',
  CustomFieldStringGridFilterFilterInput: 'CustomFieldStringGridFilterFilterInput',
  CustomFieldStringGridFilterInput: 'CustomFieldStringGridFilterInput',
  DataType: 'DataType',
  DataTypeOperationFilterInput: 'DataTypeOperationFilterInput',
  Date: 'Date',
  DateFilter: 'DateFilter',
  DateFilterDto: 'DateFilterDto',
  DateFilterFilterInput: 'DateFilterFilterInput',
  DateFilterInput: 'DateFilterInput',
  DateFormat: 'DateFormat',
  DateGridFilterFilterInput: 'DateGridFilterFilterInput',
  DateGridFilterInput: 'DateGridFilterInput',
  DateOperationFilterInput: 'DateOperationFilterInput',
  DateTime: 'DateTime',
  DateTimeOperationFilterInput: 'DateTimeOperationFilterInput',
  DeactivateEmployeeError: 'DeactivateEmployeeError',
  DeactivateEmployeeInput: 'DeactivateEmployeeInput',
  DeactivateEmployeePayload: 'DeactivateEmployeePayload',
  Decimal: 'Decimal',
  DecimalOperationFilterInput: 'DecimalOperationFilterInput',
  DecisionArgumentFilterInput: 'DecisionArgumentFilterInput',
  DecisionEmployee: 'DecisionEmployee',
  DecisionEmployeeFilterInput: 'DecisionEmployeeFilterInput',
  DecisionFilterInput: 'DecisionFilterInput',
  DefaultDateGridFilterFilterInput: 'DefaultDateGridFilterFilterInput',
  DefaultNumberGridFilterFilterInput: 'DefaultNumberGridFilterFilterInput',
  DefaultSelectGridFilterFilterInput: 'DefaultSelectGridFilterFilterInput',
  DefaultStringGridFilterFilterInput: 'DefaultStringGridFilterFilterInput',
  DeleteAccessToEntityRowError: 'DeleteAccessToEntityRowError',
  DeleteAccessToEntityRowInput: 'DeleteAccessToEntityRowInput',
  DeleteAccessToEntityRowPayload: 'DeleteAccessToEntityRowPayload',
  DeleteAddressError: 'DeleteAddressError',
  DeleteAddressInput: 'DeleteAddressInput',
  DeleteAddressPayload: 'DeleteAddressPayload',
  DeleteBenefitError: 'DeleteBenefitError',
  DeleteBenefitInput: 'DeleteBenefitInput',
  DeleteBenefitPayload: 'DeleteBenefitPayload',
  DeleteCertificateTypeError: 'DeleteCertificateTypeError',
  DeleteCertificateTypeInput: 'DeleteCertificateTypeInput',
  DeleteCertificateTypePayload: 'DeleteCertificateTypePayload',
  DeleteCompetenceTypeError: 'DeleteCompetenceTypeError',
  DeleteCompetenceTypeInput: 'DeleteCompetenceTypeInput',
  DeleteCompetenceTypePayload: 'DeleteCompetenceTypePayload',
  DeleteCustomFieldForEntityError: 'DeleteCustomFieldForEntityError',
  DeleteCustomFieldForEntityInput: 'DeleteCustomFieldForEntityInput',
  DeleteCustomFieldForEntityPayload: 'DeleteCustomFieldForEntityPayload',
  DeleteDepartmentError: 'DeleteDepartmentError',
  DeleteDepartmentInput: 'DeleteDepartmentInput',
  DeleteDepartmentPayload: 'DeleteDepartmentPayload',
  DeleteEmployeeAbsenceError: 'DeleteEmployeeAbsenceError',
  DeleteEmployeeAbsenceInput: 'DeleteEmployeeAbsenceInput',
  DeleteEmployeeAbsencePayload: 'DeleteEmployeeAbsencePayload',
  DeleteEmployeeAddressError: 'DeleteEmployeeAddressError',
  DeleteEmployeeAddressInput: 'DeleteEmployeeAddressInput',
  DeleteEmployeeAddressPayload: 'DeleteEmployeeAddressPayload',
  DeleteEmployeeAllergyError: 'DeleteEmployeeAllergyError',
  DeleteEmployeeAllergyInput: 'DeleteEmployeeAllergyInput',
  DeleteEmployeeAllergyPayload: 'DeleteEmployeeAllergyPayload',
  DeleteEmployeeCertificateError: 'DeleteEmployeeCertificateError',
  DeleteEmployeeCertificateInput: 'DeleteEmployeeCertificateInput',
  DeleteEmployeeCertificatePayload: 'DeleteEmployeeCertificatePayload',
  DeleteEmployeeCompetenceError: 'DeleteEmployeeCompetenceError',
  DeleteEmployeeCompetenceInput: 'DeleteEmployeeCompetenceInput',
  DeleteEmployeeCompetencePayload: 'DeleteEmployeeCompetencePayload',
  DeleteEmployeeDeviceError: 'DeleteEmployeeDeviceError',
  DeleteEmployeeDeviceInput: 'DeleteEmployeeDeviceInput',
  DeleteEmployeeDevicePayload: 'DeleteEmployeeDevicePayload',
  DeleteEmployeeEducationError: 'DeleteEmployeeEducationError',
  DeleteEmployeeEducationInput: 'DeleteEmployeeEducationInput',
  DeleteEmployeeEducationPayload: 'DeleteEmployeeEducationPayload',
  DeleteEmployeeEmploymentError: 'DeleteEmployeeEmploymentError',
  DeleteEmployeeEmploymentInput: 'DeleteEmployeeEmploymentInput',
  DeleteEmployeeEmploymentPayload: 'DeleteEmployeeEmploymentPayload',
  DeleteEmployeeFamilyError: 'DeleteEmployeeFamilyError',
  DeleteEmployeeFamilyInput: 'DeleteEmployeeFamilyInput',
  DeleteEmployeeFamilyPayload: 'DeleteEmployeeFamilyPayload',
  DeleteInsuranceError: 'DeleteInsuranceError',
  DeleteInsuranceInput: 'DeleteInsuranceInput',
  DeleteInsurancePayload: 'DeleteInsurancePayload',
  DeleteMasterModuleError: 'DeleteMasterModuleError',
  DeleteMasterModuleInput: 'DeleteMasterModuleInput',
  DeleteMasterModulePayload: 'DeleteMasterModulePayload',
  DeleteOfficeError: 'DeleteOfficeError',
  DeleteOfficeInput: 'DeleteOfficeInput',
  DeleteOfficePayload: 'DeleteOfficePayload',
  DeleteOkrObjectiveError: 'DeleteOkrObjectiveError',
  DeleteOkrObjectiveInput: 'DeleteOkrObjectiveInput',
  DeleteOkrObjectivePayload: 'DeleteOkrObjectivePayload',
  DeleteOrganisationAddressError: 'DeleteOrganisationAddressError',
  DeleteOrganisationAddressInput: 'DeleteOrganisationAddressInput',
  DeleteOrganisationAddressPayload: 'DeleteOrganisationAddressPayload',
  DeleteOrganisationError: 'DeleteOrganisationError',
  DeleteOrganisationInput: 'DeleteOrganisationInput',
  DeleteOrganisationPayload: 'DeleteOrganisationPayload',
  DeletePositionError: 'DeletePositionError',
  DeletePositionInput: 'DeletePositionInput',
  DeletePositionPayload: 'DeletePositionPayload',
  DeletePowerGridError: 'DeletePowerGridError',
  DeletePowerGridInput: 'DeletePowerGridInput',
  DeletePowerGridPayload: 'DeletePowerGridPayload',
  DeleteResponsibilityError: 'DeleteResponsibilityError',
  DeleteResponsibilityInput: 'DeleteResponsibilityInput',
  DeleteResponsibilityPayload: 'DeleteResponsibilityPayload',
  DeleteTeamError: 'DeleteTeamError',
  DeleteTeamInput: 'DeleteTeamInput',
  DeleteTeamPayload: 'DeleteTeamPayload',
  DeleteTodoInput: 'DeleteTodoInput',
  DeleteTodoPayload: 'DeleteTodoPayload',
  DepartmentAccessDto: 'DepartmentAccessDto',
  DepartmentInput: 'DepartmentInput',
  DepartmentsForOrganisationCollectionSegment: 'DepartmentsForOrganisationCollectionSegment',
  DeviceInput: 'DeviceInput',
  DeviceType: 'DeviceType',
  DropdownDto: 'DropdownDto',
  EducationInput: 'EducationInput',
  EducationType: 'EducationType',
  Employee: 'Employee',
  EmployeeAbsence: 'EmployeeAbsence',
  EmployeeAbsenceBalanceDto: 'EmployeeAbsenceBalanceDto',
  EmployeeAbsenceBalanceInput: 'EmployeeAbsenceBalanceInput',
  EmployeeAbsenceStatistics: 'EmployeeAbsenceStatistics',
  EmployeeAbsenceStatus: 'EmployeeAbsenceStatus',
  EmployeeAbsenceStatusType: 'EmployeeAbsenceStatusType',
  EmployeeAbsenceType: 'EmployeeAbsenceType',
  EmployeeAccessDto: 'EmployeeAccessDto',
  EmployeeAddress: 'EmployeeAddress',
  EmployeeAddressFilterInput: 'EmployeeAddressFilterInput',
  EmployeeAllergy: 'EmployeeAllergy',
  EmployeeAllergySortInput: 'EmployeeAllergySortInput',
  EmployeeCertificate: 'EmployeeCertificate',
  EmployeeCompetence: 'EmployeeCompetence',
  EmployeeDevice: 'EmployeeDevice',
  EmployeeDropdownDto: 'EmployeeDropdownDto',
  EmployeeDropdownDtoSortInput: 'EmployeeDropdownDtoSortInput',
  EmployeeEducation: 'EmployeeEducation',
  EmployeeEmployment: 'EmployeeEmployment',
  EmployeeEmploymentFilterInput: 'EmployeeEmploymentFilterInput',
  EmployeeEmploymentSortInput: 'EmployeeEmploymentSortInput',
  EmployeeFamily: 'EmployeeFamily',
  EmployeeFamilyAllergy: 'EmployeeFamilyAllergy',
  EmployeeFilterInput: 'EmployeeFilterInput',
  EmployeeMeetingFilterInput: 'EmployeeMeetingFilterInput',
  EmployeeSortInput: 'EmployeeSortInput',
  EmployeesCollectionSegment: 'EmployeesCollectionSegment',
  EmploymentInput: 'EmploymentInput',
  EmploymentType: 'EmploymentType',
  EmploymentTypeFilterInput: 'EmploymentTypeFilterInput',
  EmploymentTypeSortInput: 'EmploymentTypeSortInput',
  EmploymentWageType: 'EmploymentWageType',
  EmploymentWageTypeFilterInput: 'EmploymentWageTypeFilterInput',
  EmploymentWageTypeSortInput: 'EmploymentWageTypeSortInput',
  EntitiesCollectionSegment: 'EntitiesCollectionSegment',
  Entity: 'Entity',
  EntityCustomField: 'EntityCustomField',
  EntityCustomFieldFilterInput: 'EntityCustomFieldFilterInput',
  EntityCustomFieldValueFilterInput: 'EntityCustomFieldValueFilterInput',
  EntityDefaultGridColumnFilterInput: 'EntityDefaultGridColumnFilterInput',
  EntityDefaultGridFilterInput: 'EntityDefaultGridFilterInput',
  EntityEventDto: 'EntityEventDto',
  EntityEventSubscription: 'EntityEventSubscription',
  EntityFilterInput: 'EntityFilterInput',
  EntityGridColumnConfig: 'EntityGridColumnConfig',
  EntityGridColumnConfigFilterInput: 'EntityGridColumnConfigFilterInput',
  EntityGridColumnFilterInput: 'EntityGridColumnFilterInput',
  EntityGridConfig: 'EntityGridConfig',
  EntityGridConfigFilterInput: 'EntityGridConfigFilterInput',
  EntityGridConfigSortInput: 'EntityGridConfigSortInput',
  EntityGridCustomFieldColumnFilterInput: 'EntityGridCustomFieldColumnFilterInput',
  EntityGridFilterConfig: 'EntityGridFilterConfig',
  EntityGridFilterConfigFilterInput: 'EntityGridFilterConfigFilterInput',
  EntityGridFilterInput: 'EntityGridFilterInput',
  EntityGridSubGridConfig: 'EntityGridSubGridConfig',
  EntityGridSubGridConfigFilterInput: 'EntityGridSubGridConfigFilterInput',
  EntityGridSubGridFilterInput: 'EntityGridSubGridFilterInput',
  EntityNotFoundError: 'EntityNotFoundError',
  EntityRowAccessDto: 'EntityRowAccessDto',
  EntitySortInput: 'EntitySortInput',
  EntityUrlDto: 'EntityUrlDto',
  EntityValidationError: 'EntityValidationError',
  FamilyInput: 'FamilyInput',
  FamilyRelationType: 'FamilyRelationType',
  FilterConfigDto: 'FilterConfigDto',
  FilterListInput: 'FilterListInput',
  FilterType: 'FilterType',
  FilterTypeOperationFilterInput: 'FilterTypeOperationFilterInput',
  Float: 'Float',
  FloatOperationFilterInput: 'FloatOperationFilterInput',
  Gender: 'Gender',
  GenderFilterInput: 'GenderFilterInput',
  GenderSortInput: 'GenderSortInput',
  GridColumnProperties: 'GridColumnProperties',
  GridColumnPropertiesFilterInput: 'GridColumnPropertiesFilterInput',
  GridColumnPropertiesInput: 'GridColumnPropertiesInput',
  HandInEmployeeDeviceError: 'HandInEmployeeDeviceError',
  HandInEmployeeDeviceInput: 'HandInEmployeeDeviceInput',
  HandInEmployeeDevicePayload: 'HandInEmployeeDevicePayload',
  HandOutEmployeeDeviceError: 'HandOutEmployeeDeviceError',
  HandOutEmployeeDeviceInput: 'HandOutEmployeeDeviceInput',
  HandOutEmployeeDevicePayload: 'HandOutEmployeeDevicePayload',
  HasAccessDto: 'HasAccessDto',
  HorizontalAlignType: 'HorizontalAlignType',
  HorizontalAlignTypeOperationFilterInput: 'HorizontalAlignTypeOperationFilterInput',
  Idea: 'Idea',
  IdeaComment: 'IdeaComment',
  IdeaCommentInput: 'IdeaCommentInput',
  IdeaImportanceType: 'IdeaImportanceType',
  IdeaImportanceVote: 'IdeaImportanceVote',
  IdeaInput: 'IdeaInput',
  IdeaStatusType: 'IdeaStatusType',
  ImpersonatedBy: 'ImpersonatedBy',
  IndustryFilterInput: 'IndustryFilterInput',
  IndustrySortInput: 'IndustrySortInput',
  Int: 'Int',
  IntOperationFilterInput: 'IntOperationFilterInput',
  InvoiceMethodFilterInput: 'InvoiceMethodFilterInput',
  InvoiceMethodSortInput: 'InvoiceMethodSortInput',
  JobCandidateFilterInput: 'JobCandidateFilterInput',
  JobFilterInput: 'JobFilterInput',
  KeyValuePairOfStringAndObject: 'KeyValuePairOfStringAndObject',
  Language: 'Language',
  LanguageDropdown: 'LanguageDropdown',
  LanguageDropdownSortInput: 'LanguageDropdownSortInput',
  LanguageDto: 'LanguageDto',
  LanguageFilterInput: 'LanguageFilterInput',
  LanguageSortInput: 'LanguageSortInput',
  LinkFilterInput: 'LinkFilterInput',
  ListFilterInputTypeOfAccountFilterInput: 'ListFilterInputTypeOfAccountFilterInput',
  ListFilterInputTypeOfAccountPrimaryContactFilterInput: 'ListFilterInputTypeOfAccountPrimaryContactFilterInput',
  ListFilterInputTypeOfAccountPrimaryEmployeeFilterInput: 'ListFilterInputTypeOfAccountPrimaryEmployeeFilterInput',
  ListFilterInputTypeOfAccountSocialHandleFilterInput: 'ListFilterInputTypeOfAccountSocialHandleFilterInput',
  ListFilterInputTypeOfAccountTypeFilterInput: 'ListFilterInputTypeOfAccountTypeFilterInput',
  ListFilterInputTypeOfAddressFilterInput: 'ListFilterInputTypeOfAddressFilterInput',
  ListFilterInputTypeOfContactFilterInput: 'ListFilterInputTypeOfContactFilterInput',
  ListFilterInputTypeOfContactSocialHandleFilterInput: 'ListFilterInputTypeOfContactSocialHandleFilterInput',
  ListFilterInputTypeOfCouncilFilterInput: 'ListFilterInputTypeOfCouncilFilterInput',
  ListFilterInputTypeOfCountyFilterInput: 'ListFilterInputTypeOfCountyFilterInput',
  ListFilterInputTypeOfCustomFieldBooleanGridFilterFilterInput: 'ListFilterInputTypeOfCustomFieldBooleanGridFilterFilterInput',
  ListFilterInputTypeOfCustomFieldDateGridFilterFilterInput: 'ListFilterInputTypeOfCustomFieldDateGridFilterFilterInput',
  ListFilterInputTypeOfCustomFieldNumberGridFilterFilterInput: 'ListFilterInputTypeOfCustomFieldNumberGridFilterFilterInput',
  ListFilterInputTypeOfCustomFieldStringGridFilterFilterInput: 'ListFilterInputTypeOfCustomFieldStringGridFilterFilterInput',
  ListFilterInputTypeOfDateGridFilterFilterInput: 'ListFilterInputTypeOfDateGridFilterFilterInput',
  ListFilterInputTypeOfDecisionArgumentFilterInput: 'ListFilterInputTypeOfDecisionArgumentFilterInput',
  ListFilterInputTypeOfDecisionEmployeeFilterInput: 'ListFilterInputTypeOfDecisionEmployeeFilterInput',
  ListFilterInputTypeOfDecisionFilterInput: 'ListFilterInputTypeOfDecisionFilterInput',
  ListFilterInputTypeOfDefaultDateGridFilterFilterInput: 'ListFilterInputTypeOfDefaultDateGridFilterFilterInput',
  ListFilterInputTypeOfDefaultNumberGridFilterFilterInput: 'ListFilterInputTypeOfDefaultNumberGridFilterFilterInput',
  ListFilterInputTypeOfDefaultSelectGridFilterFilterInput: 'ListFilterInputTypeOfDefaultSelectGridFilterFilterInput',
  ListFilterInputTypeOfDefaultStringGridFilterFilterInput: 'ListFilterInputTypeOfDefaultStringGridFilterFilterInput',
  ListFilterInputTypeOfEmployeeAddressFilterInput: 'ListFilterInputTypeOfEmployeeAddressFilterInput',
  ListFilterInputTypeOfEmployeeFilterInput: 'ListFilterInputTypeOfEmployeeFilterInput',
  ListFilterInputTypeOfEntityCustomFieldValueFilterInput: 'ListFilterInputTypeOfEntityCustomFieldValueFilterInput',
  ListFilterInputTypeOfEntityDefaultGridColumnFilterInput: 'ListFilterInputTypeOfEntityDefaultGridColumnFilterInput',
  ListFilterInputTypeOfEntityDefaultGridFilterInput: 'ListFilterInputTypeOfEntityDefaultGridFilterInput',
  ListFilterInputTypeOfEntityGridColumnConfigFilterInput: 'ListFilterInputTypeOfEntityGridColumnConfigFilterInput',
  ListFilterInputTypeOfEntityGridColumnFilterInput: 'ListFilterInputTypeOfEntityGridColumnFilterInput',
  ListFilterInputTypeOfEntityGridCustomFieldColumnFilterInput: 'ListFilterInputTypeOfEntityGridCustomFieldColumnFilterInput',
  ListFilterInputTypeOfEntityGridFilterInput: 'ListFilterInputTypeOfEntityGridFilterInput',
  ListFilterInputTypeOfEntityGridSubGridFilterInput: 'ListFilterInputTypeOfEntityGridSubGridFilterInput',
  ListFilterInputTypeOfJobCandidateFilterInput: 'ListFilterInputTypeOfJobCandidateFilterInput',
  ListFilterInputTypeOfJobFilterInput: 'ListFilterInputTypeOfJobFilterInput',
  ListFilterInputTypeOfLinkFilterInput: 'ListFilterInputTypeOfLinkFilterInput',
  ListFilterInputTypeOfModuleFilterInput: 'ListFilterInputTypeOfModuleFilterInput',
  ListFilterInputTypeOfNumberGridFilterFilterInput: 'ListFilterInputTypeOfNumberGridFilterFilterInput',
  ListFilterInputTypeOfOrganisationAddressFilterInput: 'ListFilterInputTypeOfOrganisationAddressFilterInput',
  ListFilterInputTypeOfOrganisationBenefitEmployeeFilterInput: 'ListFilterInputTypeOfOrganisationBenefitEmployeeFilterInput',
  ListFilterInputTypeOfOrganisationBenefitFilterInput: 'ListFilterInputTypeOfOrganisationBenefitFilterInput',
  ListFilterInputTypeOfOrganisationDepartmentFilterInput: 'ListFilterInputTypeOfOrganisationDepartmentFilterInput',
  ListFilterInputTypeOfOrganisationFilterInput: 'ListFilterInputTypeOfOrganisationFilterInput',
  ListFilterInputTypeOfOrganisationInsuranceEmployeeFilterInput: 'ListFilterInputTypeOfOrganisationInsuranceEmployeeFilterInput',
  ListFilterInputTypeOfOrganisationInsuranceFilterInput: 'ListFilterInputTypeOfOrganisationInsuranceFilterInput',
  ListFilterInputTypeOfOrganisationNumberingDefaultFilterInput: 'ListFilterInputTypeOfOrganisationNumberingDefaultFilterInput',
  ListFilterInputTypeOfOrganisationNumberingFilterInput: 'ListFilterInputTypeOfOrganisationNumberingFilterInput',
  ListFilterInputTypeOfOrganisationOfficeEmployeeFilterInput: 'ListFilterInputTypeOfOrganisationOfficeEmployeeFilterInput',
  ListFilterInputTypeOfOrganisationOfficeFilterInput: 'ListFilterInputTypeOfOrganisationOfficeFilterInput',
  ListFilterInputTypeOfOrganisationResponsibilityFilterInput: 'ListFilterInputTypeOfOrganisationResponsibilityFilterInput',
  ListFilterInputTypeOfOrganisationResponsibilityTaskFilterInput: 'ListFilterInputTypeOfOrganisationResponsibilityTaskFilterInput',
  ListFilterInputTypeOfOrganisationTeamEmployeeFilterInput: 'ListFilterInputTypeOfOrganisationTeamEmployeeFilterInput',
  ListFilterInputTypeOfOrganisationTeamFilterInput: 'ListFilterInputTypeOfOrganisationTeamFilterInput',
  ListFilterInputTypeOfPositionFilterInput: 'ListFilterInputTypeOfPositionFilterInput',
  ListFilterInputTypeOfSeedingModuleFilterInput: 'ListFilterInputTypeOfSeedingModuleFilterInput',
  ListFilterInputTypeOfSelectGridFilterFilterInput: 'ListFilterInputTypeOfSelectGridFilterFilterInput',
  ListFilterInputTypeOfStringGridFilterFilterInput: 'ListFilterInputTypeOfStringGridFilterFilterInput',
  ListFilterInputTypeOfTemplateFilterInput: 'ListFilterInputTypeOfTemplateFilterInput',
  ListFilterInputTypeOfTemplateRevisionFilterInput: 'ListFilterInputTypeOfTemplateRevisionFilterInput',
  ListFilterInputTypeOfToDoEmployeeFilterInput: 'ListFilterInputTypeOfToDoEmployeeFilterInput',
  ListFilterInputTypeOfToDoFilterInput: 'ListFilterInputTypeOfToDoFilterInput',
  ListFilterInputTypeOfToDoRecurringExceptionFilterInput: 'ListFilterInputTypeOfToDoRecurringExceptionFilterInput',
  ListFilterInputTypeOfToDoRecurringPatternFilterInput: 'ListFilterInputTypeOfToDoRecurringPatternFilterInput',
  ListFilterInputTypeOfToDoStatusFilterInput: 'ListFilterInputTypeOfToDoStatusFilterInput',
  ListFilterInputTypeOfWorkflowTemplateFilterInput: 'ListFilterInputTypeOfWorkflowTemplateFilterInput',
  ListFilterInputTypeOfWorkflowTemplateItemFilterInput: 'ListFilterInputTypeOfWorkflowTemplateItemFilterInput',
  ListFilterInputTypeOfZipCodesFilterInput: 'ListFilterInputTypeOfZipCodesFilterInput',
  MaritalStatusType: 'MaritalStatusType',
  MaritalStatusTypeFilterInput: 'MaritalStatusTypeFilterInput',
  MaritalStatusTypeSortInput: 'MaritalStatusTypeSortInput',
  MarkNotificationsAsReadError: 'MarkNotificationsAsReadError',
  MarkNotificationsAsReadPayload: 'MarkNotificationsAsReadPayload',
  MasterModulesCollectionSegment: 'MasterModulesCollectionSegment',
  MetricTypeFilterInput: 'MetricTypeFilterInput',
  MetricTypeSortInput: 'MetricTypeSortInput',
  Module: 'Module',
  ModuleFilterInput: 'ModuleFilterInput',
  ModuleInput: 'ModuleInput',
  ModuleSortInput: 'ModuleSortInput',
  ModuleWithAccessDto: 'ModuleWithAccessDto',
  ModulesCollectionSegment: 'ModulesCollectionSegment',
  Mutation: 'Mutation',
  NoRowAccessError: 'NoRowAccessError',
  NotificationDto: 'NotificationDto',
  NullableOfDateFormatOperationFilterInput: 'NullableOfDateFormatOperationFilterInput',
  NullableOfSortDirectionOperationFilterInput: 'NullableOfSortDirectionOperationFilterInput',
  NullableOfTimeFormatOperationFilterInput: 'NullableOfTimeFormatOperationFilterInput',
  NumberFilter: 'NumberFilter',
  NumberFilterDto: 'NumberFilterDto',
  NumberFilterFilterInput: 'NumberFilterFilterInput',
  NumberFilterInput: 'NumberFilterInput',
  NumberGridFilterFilterInput: 'NumberGridFilterFilterInput',
  NumberGridFilterInput: 'NumberGridFilterInput',
  NumberingDto: 'NumberingDto',
  NumberingInput: 'NumberingInput',
  OfficeAccessDto: 'OfficeAccessDto',
  OfficeInput: 'OfficeInput',
  OfficesForOrganisationCollectionSegment: 'OfficesForOrganisationCollectionSegment',
  OkrObjective: 'OkrObjective',
  OkrObjectiveInput: 'OkrObjectiveInput',
  OkrObjectiveStatus: 'OkrObjectiveStatus',
  OkrObjectiveType: 'OkrObjectiveType',
  OkrStatusType: 'OkrStatusType',
  OperatorEnum: 'OperatorEnum',
  OperatorEnumOperationFilterInput: 'OperatorEnumOperationFilterInput',
  Organisation: 'Organisation',
  OrganisationAccessDto: 'OrganisationAccessDto',
  OrganisationAddress: 'OrganisationAddress',
  OrganisationAddressFilterInput: 'OrganisationAddressFilterInput',
  OrganisationBenefit: 'OrganisationBenefit',
  OrganisationBenefitEmployee: 'OrganisationBenefitEmployee',
  OrganisationBenefitEmployeeFilterInput: 'OrganisationBenefitEmployeeFilterInput',
  OrganisationBenefitFilterInput: 'OrganisationBenefitFilterInput',
  OrganisationDepartment: 'OrganisationDepartment',
  OrganisationDepartmentFilterInput: 'OrganisationDepartmentFilterInput',
  OrganisationDepartmentSortInput: 'OrganisationDepartmentSortInput',
  OrganisationFilterInput: 'OrganisationFilterInput',
  OrganisationInsurance: 'OrganisationInsurance',
  OrganisationInsuranceEmployee: 'OrganisationInsuranceEmployee',
  OrganisationInsuranceEmployeeFilterInput: 'OrganisationInsuranceEmployeeFilterInput',
  OrganisationInsuranceFilterInput: 'OrganisationInsuranceFilterInput',
  OrganisationNumberingDefault: 'OrganisationNumberingDefault',
  OrganisationNumberingDefaultFilterInput: 'OrganisationNumberingDefaultFilterInput',
  OrganisationNumberingFilterInput: 'OrganisationNumberingFilterInput',
  OrganisationOffice: 'OrganisationOffice',
  OrganisationOfficeEmployee: 'OrganisationOfficeEmployee',
  OrganisationOfficeEmployeeFilterInput: 'OrganisationOfficeEmployeeFilterInput',
  OrganisationOfficeFilterInput: 'OrganisationOfficeFilterInput',
  OrganisationOfficeSortInput: 'OrganisationOfficeSortInput',
  OrganisationResponsibility: 'OrganisationResponsibility',
  OrganisationResponsibilityFilterInput: 'OrganisationResponsibilityFilterInput',
  OrganisationResponsibilitySortInput: 'OrganisationResponsibilitySortInput',
  OrganisationResponsibilityTask: 'OrganisationResponsibilityTask',
  OrganisationResponsibilityTaskFilterInput: 'OrganisationResponsibilityTaskFilterInput',
  OrganisationSortInput: 'OrganisationSortInput',
  OrganisationTeam: 'OrganisationTeam',
  OrganisationTeamEmployee: 'OrganisationTeamEmployee',
  OrganisationTeamEmployeeFilterInput: 'OrganisationTeamEmployeeFilterInput',
  OrganisationTeamFilterInput: 'OrganisationTeamFilterInput',
  OrganisationTeamSortInput: 'OrganisationTeamSortInput',
  OrganisationsCollectionSegment: 'OrganisationsCollectionSegment',
  PaymentTermFilterInput: 'PaymentTermFilterInput',
  PaymentTermSortInput: 'PaymentTermSortInput',
  PhoneNumberInput: 'PhoneNumberInput',
  Position: 'Position',
  PositionFilterInput: 'PositionFilterInput',
  PositionSortInput: 'PositionSortInput',
  PowerGridColumnDto: 'PowerGridColumnDto',
  PowerGridColumnInput: 'PowerGridColumnInput',
  PowerGridCustomFieldColumnDto: 'PowerGridCustomFieldColumnDto',
  PowerGridCustomFieldColumnInput: 'PowerGridCustomFieldColumnInput',
  PowerGridDto: 'PowerGridDto',
  PowerGridInput: 'PowerGridInput',
  PowerGridListDto: 'PowerGridListDto',
  PowerGridSubGridDto: 'PowerGridSubGridDto',
  PowerGridSubGridInput: 'PowerGridSubGridInput',
  Product: 'Product',
  ProductCategory: 'ProductCategory',
  ProductCategoryFilterInput: 'ProductCategoryFilterInput',
  ProductCategorySortInput: 'ProductCategorySortInput',
  ProductFilterInput: 'ProductFilterInput',
  ProductInput: 'ProductInput',
  ProductSortInput: 'ProductSortInput',
  ProductsCollectionSegment: 'ProductsCollectionSegment',
  ProfileDto: 'ProfileDto',
  Query: 'Query',
  ReadEntityEventInput: 'ReadEntityEventInput',
  ReadEntityEventPayload: 'ReadEntityEventPayload',
  RemoveEmployeeFromBenefitError: 'RemoveEmployeeFromBenefitError',
  RemoveEmployeeFromBenefitInput: 'RemoveEmployeeFromBenefitInput',
  RemoveEmployeeFromBenefitPayload: 'RemoveEmployeeFromBenefitPayload',
  RemoveEmployeeFromInsuranceError: 'RemoveEmployeeFromInsuranceError',
  RemoveEmployeeFromInsuranceInput: 'RemoveEmployeeFromInsuranceInput',
  RemoveEmployeeFromInsurancePayload: 'RemoveEmployeeFromInsurancePayload',
  RemoveEmployeeFromOfficeError: 'RemoveEmployeeFromOfficeError',
  RemoveEmployeeFromOfficeInput: 'RemoveEmployeeFromOfficeInput',
  RemoveEmployeeFromOfficePayload: 'RemoveEmployeeFromOfficePayload',
  RemoveEmployeeFromTeamError: 'RemoveEmployeeFromTeamError',
  RemoveEmployeeFromTeamInput: 'RemoveEmployeeFromTeamInput',
  RemoveEmployeeFromTeamPayload: 'RemoveEmployeeFromTeamPayload',
  ResourceContext: 'ResourceContext',
  ResourceDto: 'ResourceDto',
  ResourceItemDto: 'ResourceItemDto',
  ResponsibilitiesCollectionSegment: 'ResponsibilitiesCollectionSegment',
  ResponsibilityInput: 'ResponsibilityInput',
  ResponsibilityTaskInput: 'ResponsibilityTaskInput',
  SeedCountryDataToClientDbError: 'SeedCountryDataToClientDbError',
  SeedCountryDataToClientDbPayload: 'SeedCountryDataToClientDbPayload',
  SeedNewClientError: 'SeedNewClientError',
  SeedNewClientPayload: 'SeedNewClientPayload',
  SeedNorwayGeoDataToClientDbError: 'SeedNorwayGeoDataToClientDbError',
  SeedNorwayGeoDataToClientDbPayload: 'SeedNorwayGeoDataToClientDbPayload',
  SeedOneTimeSeedToClientError: 'SeedOneTimeSeedToClientError',
  SeedOneTimeSeedToClientPayload: 'SeedOneTimeSeedToClientPayload',
  SeedingEntity: 'SeedingEntity',
  SeedingEntityFilterInput: 'SeedingEntityFilterInput',
  SeedingEntitySortInput: 'SeedingEntitySortInput',
  SeedingModule: 'SeedingModule',
  SeedingModuleFilterInput: 'SeedingModuleFilterInput',
  SeedingModuleSortInput: 'SeedingModuleSortInput',
  SelectFilter: 'SelectFilter',
  SelectFilterDto: 'SelectFilterDto',
  SelectFilterFilterInput: 'SelectFilterFilterInput',
  SelectFilterInput: 'SelectFilterInput',
  SelectGridFilterFilterInput: 'SelectGridFilterFilterInput',
  SelectGridFilterInput: 'SelectGridFilterInput',
  SetCurrentEmploymentError: 'SetCurrentEmploymentError',
  SetCurrentEmploymentInput: 'SetCurrentEmploymentInput',
  SetCurrentEmploymentPayload: 'SetCurrentEmploymentPayload',
  SetGlobalAccessToEntityRowError: 'SetGlobalAccessToEntityRowError',
  SetGlobalAccessToEntityRowInput: 'SetGlobalAccessToEntityRowInput',
  SetGlobalAccessToEntityRowPayload: 'SetGlobalAccessToEntityRowPayload',
  SizeFeet: 'SizeFeet',
  SizeFeetFilterInput: 'SizeFeetFilterInput',
  SizeFeetSortInput: 'SizeFeetSortInput',
  SizeLegs: 'SizeLegs',
  SizeLegsFilterInput: 'SizeLegsFilterInput',
  SizeLegsSortInput: 'SizeLegsSortInput',
  SizeTorso: 'SizeTorso',
  SizeTorsoFilterInput: 'SizeTorsoFilterInput',
  SizeTorsoSortInput: 'SizeTorsoSortInput',
  SizeWaist: 'SizeWaist',
  SizeWaistFilterInput: 'SizeWaistFilterInput',
  SizeWaistSortInput: 'SizeWaistSortInput',
  SocialHandleTypeFilterInput: 'SocialHandleTypeFilterInput',
  SortDirection: 'SortDirection',
  SortEnumType: 'SortEnumType',
  String: 'String',
  StringFilter: 'StringFilter',
  StringFilterDto: 'StringFilterDto',
  StringFilterFilterInput: 'StringFilterFilterInput',
  StringFilterInput: 'StringFilterInput',
  StringGridFilterFilterInput: 'StringGridFilterFilterInput',
  StringGridFilterInput: 'StringGridFilterInput',
  StringOperationFilterInput: 'StringOperationFilterInput',
  TeamAccessDto: 'TeamAccessDto',
  TeamInput: 'TeamInput',
  TeamsForOrganisationCollectionSegment: 'TeamsForOrganisationCollectionSegment',
  Template: 'Template',
  TemplateFilterInput: 'TemplateFilterInput',
  TemplateRevision: 'TemplateRevision',
  TemplateRevisionFilterInput: 'TemplateRevisionFilterInput',
  TemplateSortInput: 'TemplateSortInput',
  TemplatesCollectionSegment: 'TemplatesCollectionSegment',
  TimeFormat: 'TimeFormat',
  TimeSpan: 'TimeSpan',
  TimeSpanOperationFilterInput: 'TimeSpanOperationFilterInput',
  ToDoEmployee: 'ToDoEmployee',
  ToDoEmployeeFilterInput: 'ToDoEmployeeFilterInput',
  ToDoFilterInput: 'ToDoFilterInput',
  ToDoRecurringExceptionFilterInput: 'ToDoRecurringExceptionFilterInput',
  ToDoRecurringPatternFilterInput: 'ToDoRecurringPatternFilterInput',
  ToDoRecurringTypeFilterInput: 'ToDoRecurringTypeFilterInput',
  ToDoStatusFilterInput: 'ToDoStatusFilterInput',
  ToDoStatusTypeFilterInput: 'ToDoStatusTypeFilterInput',
  TodoFormInput: 'TodoFormInput',
  ToggleModuleEnabledError: 'ToggleModuleEnabledError',
  ToggleModuleEnabledInput: 'ToggleModuleEnabledInput',
  ToggleModuleEnabledPayload: 'ToggleModuleEnabledPayload',
  UUID: 'UUID',
  UpdateAccessToEntityRowError: 'UpdateAccessToEntityRowError',
  UpdateAccessToEntityRowInput: 'UpdateAccessToEntityRowInput',
  UpdateAccessToEntityRowPayload: 'UpdateAccessToEntityRowPayload',
  UpdateAddressError: 'UpdateAddressError',
  UpdateAddressInput: 'UpdateAddressInput',
  UpdateAddressPayload: 'UpdateAddressPayload',
  UpdateBenefitError: 'UpdateBenefitError',
  UpdateBenefitFormInput: 'UpdateBenefitFormInput',
  UpdateBenefitInput: 'UpdateBenefitInput',
  UpdateBenefitPayload: 'UpdateBenefitPayload',
  UpdateCertificateTypeError: 'UpdateCertificateTypeError',
  UpdateCertificateTypeInput: 'UpdateCertificateTypeInput',
  UpdateCertificateTypePayload: 'UpdateCertificateTypePayload',
  UpdateCompetenceTypeError: 'UpdateCompetenceTypeError',
  UpdateCompetenceTypeInput: 'UpdateCompetenceTypeInput',
  UpdateCompetenceTypePayload: 'UpdateCompetenceTypePayload',
  UpdateCustomFieldForEntityError: 'UpdateCustomFieldForEntityError',
  UpdateCustomFieldForEntityInput: 'UpdateCustomFieldForEntityInput',
  UpdateCustomFieldForEntityPayload: 'UpdateCustomFieldForEntityPayload',
  UpdateDepartmentError: 'UpdateDepartmentError',
  UpdateDepartmentInput: 'UpdateDepartmentInput',
  UpdateDepartmentPayload: 'UpdateDepartmentPayload',
  UpdateEmployeeAboutError: 'UpdateEmployeeAboutError',
  UpdateEmployeeAboutInput: 'UpdateEmployeeAboutInput',
  UpdateEmployeeAboutPayload: 'UpdateEmployeeAboutPayload',
  UpdateEmployeeAbsenceBalanceError: 'UpdateEmployeeAbsenceBalanceError',
  UpdateEmployeeAbsenceBalanceInput: 'UpdateEmployeeAbsenceBalanceInput',
  UpdateEmployeeAbsenceBalancePayload: 'UpdateEmployeeAbsenceBalancePayload',
  UpdateEmployeeAbsenceDescriptionError: 'UpdateEmployeeAbsenceDescriptionError',
  UpdateEmployeeAbsenceDescriptionInput: 'UpdateEmployeeAbsenceDescriptionInput',
  UpdateEmployeeAbsenceDescriptionPayload: 'UpdateEmployeeAbsenceDescriptionPayload',
  UpdateEmployeeAbsenceEndDateError: 'UpdateEmployeeAbsenceEndDateError',
  UpdateEmployeeAbsenceEndDateInput: 'UpdateEmployeeAbsenceEndDateInput',
  UpdateEmployeeAbsenceEndDatePayload: 'UpdateEmployeeAbsenceEndDatePayload',
  UpdateEmployeeAbsenceError: 'UpdateEmployeeAbsenceError',
  UpdateEmployeeAbsenceInput: 'UpdateEmployeeAbsenceInput',
  UpdateEmployeeAbsencePayload: 'UpdateEmployeeAbsencePayload',
  UpdateEmployeeAbsenceStartDateError: 'UpdateEmployeeAbsenceStartDateError',
  UpdateEmployeeAbsenceStartDateInput: 'UpdateEmployeeAbsenceStartDateInput',
  UpdateEmployeeAbsenceStartDatePayload: 'UpdateEmployeeAbsenceStartDatePayload',
  UpdateEmployeeAbsenceStatusError: 'UpdateEmployeeAbsenceStatusError',
  UpdateEmployeeAbsenceStatusInput: 'UpdateEmployeeAbsenceStatusInput',
  UpdateEmployeeAbsenceStatusPayload: 'UpdateEmployeeAbsenceStatusPayload',
  UpdateEmployeeAbsenceTypeIdError: 'UpdateEmployeeAbsenceTypeIdError',
  UpdateEmployeeAbsenceTypeIdInput: 'UpdateEmployeeAbsenceTypeIdInput',
  UpdateEmployeeAbsenceTypeIdPayload: 'UpdateEmployeeAbsenceTypeIdPayload',
  UpdateEmployeeAddressError: 'UpdateEmployeeAddressError',
  UpdateEmployeeAddressInput: 'UpdateEmployeeAddressInput',
  UpdateEmployeeAddressPayload: 'UpdateEmployeeAddressPayload',
  UpdateEmployeeAdminError: 'UpdateEmployeeAdminError',
  UpdateEmployeeAdminInput: 'UpdateEmployeeAdminInput',
  UpdateEmployeeAdminPayload: 'UpdateEmployeeAdminPayload',
  UpdateEmployeeAllergyError: 'UpdateEmployeeAllergyError',
  UpdateEmployeeAllergyInput: 'UpdateEmployeeAllergyInput',
  UpdateEmployeeAllergyPayload: 'UpdateEmployeeAllergyPayload',
  UpdateEmployeeBankAccountNumberError: 'UpdateEmployeeBankAccountNumberError',
  UpdateEmployeeBankAccountNumberInput: 'UpdateEmployeeBankAccountNumberInput',
  UpdateEmployeeBankAccountNumberPayload: 'UpdateEmployeeBankAccountNumberPayload',
  UpdateEmployeeBirthDateError: 'UpdateEmployeeBirthDateError',
  UpdateEmployeeBirthDateInput: 'UpdateEmployeeBirthDateInput',
  UpdateEmployeeBirthDatePayload: 'UpdateEmployeeBirthDatePayload',
  UpdateEmployeeCellularError: 'UpdateEmployeeCellularError',
  UpdateEmployeeCellularInput: 'UpdateEmployeeCellularInput',
  UpdateEmployeeCellularPayload: 'UpdateEmployeeCellularPayload',
  UpdateEmployeeCertificateError: 'UpdateEmployeeCertificateError',
  UpdateEmployeeCertificateInput: 'UpdateEmployeeCertificateInput',
  UpdateEmployeeCertificatePayload: 'UpdateEmployeeCertificatePayload',
  UpdateEmployeeCompetenceError: 'UpdateEmployeeCompetenceError',
  UpdateEmployeeCompetenceInput: 'UpdateEmployeeCompetenceInput',
  UpdateEmployeeCompetencePayload: 'UpdateEmployeeCompetencePayload',
  UpdateEmployeeDefaultAddressError: 'UpdateEmployeeDefaultAddressError',
  UpdateEmployeeDefaultAddressInput: 'UpdateEmployeeDefaultAddressInput',
  UpdateEmployeeDefaultAddressPayload: 'UpdateEmployeeDefaultAddressPayload',
  UpdateEmployeeDefaultProductError: 'UpdateEmployeeDefaultProductError',
  UpdateEmployeeDefaultProductInput: 'UpdateEmployeeDefaultProductInput',
  UpdateEmployeeDefaultProductPayload: 'UpdateEmployeeDefaultProductPayload',
  UpdateEmployeeDeviceError: 'UpdateEmployeeDeviceError',
  UpdateEmployeeDeviceInput: 'UpdateEmployeeDeviceInput',
  UpdateEmployeeDevicePayload: 'UpdateEmployeeDevicePayload',
  UpdateEmployeeEducationError: 'UpdateEmployeeEducationError',
  UpdateEmployeeEducationInput: 'UpdateEmployeeEducationInput',
  UpdateEmployeeEducationPayload: 'UpdateEmployeeEducationPayload',
  UpdateEmployeeEmailError: 'UpdateEmployeeEmailError',
  UpdateEmployeeEmailInput: 'UpdateEmployeeEmailInput',
  UpdateEmployeeEmailPayload: 'UpdateEmployeeEmailPayload',
  UpdateEmployeeEmploymentDescriptionError: 'UpdateEmployeeEmploymentDescriptionError',
  UpdateEmployeeEmploymentDescriptionInput: 'UpdateEmployeeEmploymentDescriptionInput',
  UpdateEmployeeEmploymentDescriptionPayload: 'UpdateEmployeeEmploymentDescriptionPayload',
  UpdateEmployeeEmploymentEmploymentRatePercentError: 'UpdateEmployeeEmploymentEmploymentRatePercentError',
  UpdateEmployeeEmploymentEmploymentRatePercentInput: 'UpdateEmployeeEmploymentEmploymentRatePercentInput',
  UpdateEmployeeEmploymentEmploymentRatePercentPayload: 'UpdateEmployeeEmploymentEmploymentRatePercentPayload',
  UpdateEmployeeEmploymentEmploymentTypeIdError: 'UpdateEmployeeEmploymentEmploymentTypeIdError',
  UpdateEmployeeEmploymentEmploymentTypeIdInput: 'UpdateEmployeeEmploymentEmploymentTypeIdInput',
  UpdateEmployeeEmploymentEmploymentTypeIdPayload: 'UpdateEmployeeEmploymentEmploymentTypeIdPayload',
  UpdateEmployeeEmploymentEndDateError: 'UpdateEmployeeEmploymentEndDateError',
  UpdateEmployeeEmploymentEndDateInput: 'UpdateEmployeeEmploymentEndDateInput',
  UpdateEmployeeEmploymentEndDatePayload: 'UpdateEmployeeEmploymentEndDatePayload',
  UpdateEmployeeEmploymentError: 'UpdateEmployeeEmploymentError',
  UpdateEmployeeEmploymentInput: 'UpdateEmployeeEmploymentInput',
  UpdateEmployeeEmploymentPayload: 'UpdateEmployeeEmploymentPayload',
  UpdateEmployeeEmploymentPositionIdError: 'UpdateEmployeeEmploymentPositionIdError',
  UpdateEmployeeEmploymentPositionIdInput: 'UpdateEmployeeEmploymentPositionIdInput',
  UpdateEmployeeEmploymentPositionIdPayload: 'UpdateEmployeeEmploymentPositionIdPayload',
  UpdateEmployeeEmploymentStartDateError: 'UpdateEmployeeEmploymentStartDateError',
  UpdateEmployeeEmploymentStartDateInput: 'UpdateEmployeeEmploymentStartDateInput',
  UpdateEmployeeEmploymentStartDatePayload: 'UpdateEmployeeEmploymentStartDatePayload',
  UpdateEmployeeEmploymentWageTypeIdError: 'UpdateEmployeeEmploymentWageTypeIdError',
  UpdateEmployeeEmploymentWageTypeIdInput: 'UpdateEmployeeEmploymentWageTypeIdInput',
  UpdateEmployeeEmploymentWageTypeIdPayload: 'UpdateEmployeeEmploymentWageTypeIdPayload',
  UpdateEmployeeEmploymentWageValueError: 'UpdateEmployeeEmploymentWageValueError',
  UpdateEmployeeEmploymentWageValueInput: 'UpdateEmployeeEmploymentWageValueInput',
  UpdateEmployeeEmploymentWageValuePayload: 'UpdateEmployeeEmploymentWageValuePayload',
  UpdateEmployeeExpenseManagerIdError: 'UpdateEmployeeExpenseManagerIdError',
  UpdateEmployeeExpenseManagerIdInput: 'UpdateEmployeeExpenseManagerIdInput',
  UpdateEmployeeExpenseManagerIdPayload: 'UpdateEmployeeExpenseManagerIdPayload',
  UpdateEmployeeExternalIdError: 'UpdateEmployeeExternalIdError',
  UpdateEmployeeExternalIdInput: 'UpdateEmployeeExternalIdInput',
  UpdateEmployeeExternalIdPayload: 'UpdateEmployeeExternalIdPayload',
  UpdateEmployeeExternalSystemIdError: 'UpdateEmployeeExternalSystemIdError',
  UpdateEmployeeExternalSystemIdInput: 'UpdateEmployeeExternalSystemIdInput',
  UpdateEmployeeExternalSystemIdPayload: 'UpdateEmployeeExternalSystemIdPayload',
  UpdateEmployeeFamilyError: 'UpdateEmployeeFamilyError',
  UpdateEmployeeFamilyInput: 'UpdateEmployeeFamilyInput',
  UpdateEmployeeFamilyPayload: 'UpdateEmployeeFamilyPayload',
  UpdateEmployeeFirstNameError: 'UpdateEmployeeFirstNameError',
  UpdateEmployeeFirstNameInput: 'UpdateEmployeeFirstNameInput',
  UpdateEmployeeFirstNamePayload: 'UpdateEmployeeFirstNamePayload',
  UpdateEmployeeGenderIdError: 'UpdateEmployeeGenderIdError',
  UpdateEmployeeGenderIdInput: 'UpdateEmployeeGenderIdInput',
  UpdateEmployeeGenderIdPayload: 'UpdateEmployeeGenderIdPayload',
  UpdateEmployeeInitialsError: 'UpdateEmployeeInitialsError',
  UpdateEmployeeInitialsInput: 'UpdateEmployeeInitialsInput',
  UpdateEmployeeInitialsPayload: 'UpdateEmployeeInitialsPayload',
  UpdateEmployeeJobDescriptionError: 'UpdateEmployeeJobDescriptionError',
  UpdateEmployeeJobDescriptionInput: 'UpdateEmployeeJobDescriptionInput',
  UpdateEmployeeJobDescriptionPayload: 'UpdateEmployeeJobDescriptionPayload',
  UpdateEmployeeKmHomeWorkError: 'UpdateEmployeeKmHomeWorkError',
  UpdateEmployeeKmHomeWorkInput: 'UpdateEmployeeKmHomeWorkInput',
  UpdateEmployeeKmHomeWorkPayload: 'UpdateEmployeeKmHomeWorkPayload',
  UpdateEmployeeLanguageIdError: 'UpdateEmployeeLanguageIdError',
  UpdateEmployeeLanguageIdInput: 'UpdateEmployeeLanguageIdInput',
  UpdateEmployeeLanguageIdPayload: 'UpdateEmployeeLanguageIdPayload',
  UpdateEmployeeLastNameError: 'UpdateEmployeeLastNameError',
  UpdateEmployeeLastNameInput: 'UpdateEmployeeLastNameInput',
  UpdateEmployeeLastNamePayload: 'UpdateEmployeeLastNamePayload',
  UpdateEmployeeManagerIdError: 'UpdateEmployeeManagerIdError',
  UpdateEmployeeManagerIdInput: 'UpdateEmployeeManagerIdInput',
  UpdateEmployeeManagerIdPayload: 'UpdateEmployeeManagerIdPayload',
  UpdateEmployeeMaritalStatusTypeIdError: 'UpdateEmployeeMaritalStatusTypeIdError',
  UpdateEmployeeMaritalStatusTypeIdInput: 'UpdateEmployeeMaritalStatusTypeIdInput',
  UpdateEmployeeMaritalStatusTypeIdPayload: 'UpdateEmployeeMaritalStatusTypeIdPayload',
  UpdateEmployeeNationalityIdError: 'UpdateEmployeeNationalityIdError',
  UpdateEmployeeNationalityIdInput: 'UpdateEmployeeNationalityIdInput',
  UpdateEmployeeNationalityIdPayload: 'UpdateEmployeeNationalityIdPayload',
  UpdateEmployeeOrganisationDepartmentIdError: 'UpdateEmployeeOrganisationDepartmentIdError',
  UpdateEmployeeOrganisationDepartmentIdInput: 'UpdateEmployeeOrganisationDepartmentIdInput',
  UpdateEmployeeOrganisationDepartmentIdPayload: 'UpdateEmployeeOrganisationDepartmentIdPayload',
  UpdateEmployeeOrganisationIdError: 'UpdateEmployeeOrganisationIdError',
  UpdateEmployeeOrganisationIdInput: 'UpdateEmployeeOrganisationIdInput',
  UpdateEmployeeOrganisationIdPayload: 'UpdateEmployeeOrganisationIdPayload',
  UpdateEmployeePhoneError: 'UpdateEmployeePhoneError',
  UpdateEmployeePhoneInput: 'UpdateEmployeePhoneInput',
  UpdateEmployeePhonePayload: 'UpdateEmployeePhonePayload',
  UpdateEmployeeProfilePictureError: 'UpdateEmployeeProfilePictureError',
  UpdateEmployeeProfilePictureInput: 'UpdateEmployeeProfilePictureInput',
  UpdateEmployeeProfilePicturePayload: 'UpdateEmployeeProfilePicturePayload',
  UpdateEmployeeSizeFeetIdError: 'UpdateEmployeeSizeFeetIdError',
  UpdateEmployeeSizeFeetIdInput: 'UpdateEmployeeSizeFeetIdInput',
  UpdateEmployeeSizeFeetIdPayload: 'UpdateEmployeeSizeFeetIdPayload',
  UpdateEmployeeSizeLegsIdError: 'UpdateEmployeeSizeLegsIdError',
  UpdateEmployeeSizeLegsIdInput: 'UpdateEmployeeSizeLegsIdInput',
  UpdateEmployeeSizeLegsIdPayload: 'UpdateEmployeeSizeLegsIdPayload',
  UpdateEmployeeSizeTorsoIdError: 'UpdateEmployeeSizeTorsoIdError',
  UpdateEmployeeSizeTorsoIdInput: 'UpdateEmployeeSizeTorsoIdInput',
  UpdateEmployeeSizeTorsoIdPayload: 'UpdateEmployeeSizeTorsoIdPayload',
  UpdateEmployeeSizeWaistIdError: 'UpdateEmployeeSizeWaistIdError',
  UpdateEmployeeSizeWaistIdInput: 'UpdateEmployeeSizeWaistIdInput',
  UpdateEmployeeSizeWaistIdPayload: 'UpdateEmployeeSizeWaistIdPayload',
  UpdateEmployeeSocialSecurityNumberError: 'UpdateEmployeeSocialSecurityNumberError',
  UpdateEmployeeSocialSecurityNumberInput: 'UpdateEmployeeSocialSecurityNumberInput',
  UpdateEmployeeSocialSecurityNumberPayload: 'UpdateEmployeeSocialSecurityNumberPayload',
  UpdateEmployeeStartDateError: 'UpdateEmployeeStartDateError',
  UpdateEmployeeStartDateInput: 'UpdateEmployeeStartDateInput',
  UpdateEmployeeStartDatePayload: 'UpdateEmployeeStartDatePayload',
  UpdateEmployeeTimeSheetManagerIdError: 'UpdateEmployeeTimeSheetManagerIdError',
  UpdateEmployeeTimeSheetManagerIdInput: 'UpdateEmployeeTimeSheetManagerIdInput',
  UpdateEmployeeTimeSheetManagerIdPayload: 'UpdateEmployeeTimeSheetManagerIdPayload',
  UpdateEmployeeTrialMonthsError: 'UpdateEmployeeTrialMonthsError',
  UpdateEmployeeTrialMonthsInput: 'UpdateEmployeeTrialMonthsInput',
  UpdateEmployeeTrialMonthsPayload: 'UpdateEmployeeTrialMonthsPayload',
  UpdateEntityEventSubscriptionError: 'UpdateEntityEventSubscriptionError',
  UpdateEntityEventSubscriptionInput: 'UpdateEntityEventSubscriptionInput',
  UpdateEntityEventSubscriptionPayload: 'UpdateEntityEventSubscriptionPayload',
  UpdateIdeaBodyError: 'UpdateIdeaBodyError',
  UpdateIdeaBodyInput: 'UpdateIdeaBodyInput',
  UpdateIdeaBodyPayload: 'UpdateIdeaBodyPayload',
  UpdateIdeaParentIdError: 'UpdateIdeaParentIdError',
  UpdateIdeaParentIdInput: 'UpdateIdeaParentIdInput',
  UpdateIdeaParentIdPayload: 'UpdateIdeaParentIdPayload',
  UpdateIdeaStatusTypeIdError: 'UpdateIdeaStatusTypeIdError',
  UpdateIdeaStatusTypeIdInput: 'UpdateIdeaStatusTypeIdInput',
  UpdateIdeaStatusTypeIdPayload: 'UpdateIdeaStatusTypeIdPayload',
  UpdateIdeaTitleError: 'UpdateIdeaTitleError',
  UpdateIdeaTitleInput: 'UpdateIdeaTitleInput',
  UpdateIdeaTitlePayload: 'UpdateIdeaTitlePayload',
  UpdateInsuranceError: 'UpdateInsuranceError',
  UpdateInsuranceFormInput: 'UpdateInsuranceFormInput',
  UpdateInsuranceInput: 'UpdateInsuranceInput',
  UpdateInsurancePayload: 'UpdateInsurancePayload',
  UpdateLanguageIsActiveError: 'UpdateLanguageIsActiveError',
  UpdateLanguageIsActiveInput: 'UpdateLanguageIsActiveInput',
  UpdateLanguageIsActivePayload: 'UpdateLanguageIsActivePayload',
  UpdateMasterModuleError: 'UpdateMasterModuleError',
  UpdateMasterModuleInput: 'UpdateMasterModuleInput',
  UpdateMasterModulePayload: 'UpdateMasterModulePayload',
  UpdateOfficeError: 'UpdateOfficeError',
  UpdateOfficeInput: 'UpdateOfficeInput',
  UpdateOfficePayload: 'UpdateOfficePayload',
  UpdateOkrObjectiveError: 'UpdateOkrObjectiveError',
  UpdateOkrObjectiveInput: 'UpdateOkrObjectiveInput',
  UpdateOkrObjectivePayload: 'UpdateOkrObjectivePayload',
  UpdateOrganisationAccountIdError: 'UpdateOrganisationAccountIdError',
  UpdateOrganisationAccountIdInput: 'UpdateOrganisationAccountIdInput',
  UpdateOrganisationAccountIdPayload: 'UpdateOrganisationAccountIdPayload',
  UpdateOrganisationAddressError: 'UpdateOrganisationAddressError',
  UpdateOrganisationAddressInput: 'UpdateOrganisationAddressInput',
  UpdateOrganisationAddressPayload: 'UpdateOrganisationAddressPayload',
  UpdateOrganisationAdministrationFeeCalculationRuleError: 'UpdateOrganisationAdministrationFeeCalculationRuleError',
  UpdateOrganisationAdministrationFeeCalculationRuleInput: 'UpdateOrganisationAdministrationFeeCalculationRuleInput',
  UpdateOrganisationAdministrationFeeCalculationRulePayload: 'UpdateOrganisationAdministrationFeeCalculationRulePayload',
  UpdateOrganisationAdministrationFeeCalculationRuleThresholdError: 'UpdateOrganisationAdministrationFeeCalculationRuleThresholdError',
  UpdateOrganisationAdministrationFeeCalculationRuleThresholdInput: 'UpdateOrganisationAdministrationFeeCalculationRuleThresholdInput',
  UpdateOrganisationAdministrationFeeCalculationRuleThresholdPayload: 'UpdateOrganisationAdministrationFeeCalculationRuleThresholdPayload',
  UpdateOrganisationAdministrationFeeProductIdError: 'UpdateOrganisationAdministrationFeeProductIdError',
  UpdateOrganisationAdministrationFeeProductIdInput: 'UpdateOrganisationAdministrationFeeProductIdInput',
  UpdateOrganisationAdministrationFeeProductIdPayload: 'UpdateOrganisationAdministrationFeeProductIdPayload',
  UpdateOrganisationEmailError: 'UpdateOrganisationEmailError',
  UpdateOrganisationEmailFooterColorError: 'UpdateOrganisationEmailFooterColorError',
  UpdateOrganisationEmailFooterColorInput: 'UpdateOrganisationEmailFooterColorInput',
  UpdateOrganisationEmailFooterColorPayload: 'UpdateOrganisationEmailFooterColorPayload',
  UpdateOrganisationEmailHeaderColorError: 'UpdateOrganisationEmailHeaderColorError',
  UpdateOrganisationEmailHeaderColorInput: 'UpdateOrganisationEmailHeaderColorInput',
  UpdateOrganisationEmailHeaderColorPayload: 'UpdateOrganisationEmailHeaderColorPayload',
  UpdateOrganisationEmailInput: 'UpdateOrganisationEmailInput',
  UpdateOrganisationEmailPayload: 'UpdateOrganisationEmailPayload',
  UpdateOrganisationExternalErpIdError: 'UpdateOrganisationExternalErpIdError',
  UpdateOrganisationExternalErpIdInput: 'UpdateOrganisationExternalErpIdInput',
  UpdateOrganisationExternalErpIdPayload: 'UpdateOrganisationExternalErpIdPayload',
  UpdateOrganisationLanguageIdError: 'UpdateOrganisationLanguageIdError',
  UpdateOrganisationLanguageIdInput: 'UpdateOrganisationLanguageIdInput',
  UpdateOrganisationLanguageIdPayload: 'UpdateOrganisationLanguageIdPayload',
  UpdateOrganisationLegalNameError: 'UpdateOrganisationLegalNameError',
  UpdateOrganisationLegalNameInput: 'UpdateOrganisationLegalNameInput',
  UpdateOrganisationLegalNamePayload: 'UpdateOrganisationLegalNamePayload',
  UpdateOrganisationLegalNoError: 'UpdateOrganisationLegalNoError',
  UpdateOrganisationLegalNoInput: 'UpdateOrganisationLegalNoInput',
  UpdateOrganisationLegalNoPayload: 'UpdateOrganisationLegalNoPayload',
  UpdateOrganisationLogoError: 'UpdateOrganisationLogoError',
  UpdateOrganisationLogoInput: 'UpdateOrganisationLogoInput',
  UpdateOrganisationLogoPayload: 'UpdateOrganisationLogoPayload',
  UpdateOrganisationManagerIdError: 'UpdateOrganisationManagerIdError',
  UpdateOrganisationManagerIdInput: 'UpdateOrganisationManagerIdInput',
  UpdateOrganisationManagerIdPayload: 'UpdateOrganisationManagerIdPayload',
  UpdateOrganisationNameError: 'UpdateOrganisationNameError',
  UpdateOrganisationNameInput: 'UpdateOrganisationNameInput',
  UpdateOrganisationNamePayload: 'UpdateOrganisationNamePayload',
  UpdateOrganisationNumberingError: 'UpdateOrganisationNumberingError',
  UpdateOrganisationNumberingInput: 'UpdateOrganisationNumberingInput',
  UpdateOrganisationNumberingPayload: 'UpdateOrganisationNumberingPayload',
  UpdateOrganisationPayTermIdError: 'UpdateOrganisationPayTermIdError',
  UpdateOrganisationPayTermIdInput: 'UpdateOrganisationPayTermIdInput',
  UpdateOrganisationPayTermIdPayload: 'UpdateOrganisationPayTermIdPayload',
  UpdateOrganisationPhoneError: 'UpdateOrganisationPhoneError',
  UpdateOrganisationPhoneInput: 'UpdateOrganisationPhoneInput',
  UpdateOrganisationPhonePayload: 'UpdateOrganisationPhonePayload',
  UpdateOrganisationPrimaryColorCodeError: 'UpdateOrganisationPrimaryColorCodeError',
  UpdateOrganisationPrimaryColorCodeInput: 'UpdateOrganisationPrimaryColorCodeInput',
  UpdateOrganisationPrimaryColorCodePayload: 'UpdateOrganisationPrimaryColorCodePayload',
  UpdateOrganisationSecondaryColorCodeError: 'UpdateOrganisationSecondaryColorCodeError',
  UpdateOrganisationSecondaryColorCodeInput: 'UpdateOrganisationSecondaryColorCodeInput',
  UpdateOrganisationSecondaryColorCodePayload: 'UpdateOrganisationSecondaryColorCodePayload',
  UpdateOrganisationSmsSenderNameError: 'UpdateOrganisationSmsSenderNameError',
  UpdateOrganisationSmsSenderNameInput: 'UpdateOrganisationSmsSenderNameInput',
  UpdateOrganisationSmsSenderNamePayload: 'UpdateOrganisationSmsSenderNamePayload',
  UpdateOrganisationSymbolError: 'UpdateOrganisationSymbolError',
  UpdateOrganisationSymbolInput: 'UpdateOrganisationSymbolInput',
  UpdateOrganisationSymbolPayload: 'UpdateOrganisationSymbolPayload',
  UpdateOrganisationTemplateLogoError: 'UpdateOrganisationTemplateLogoError',
  UpdateOrganisationTemplateLogoInput: 'UpdateOrganisationTemplateLogoInput',
  UpdateOrganisationTemplateLogoPayload: 'UpdateOrganisationTemplateLogoPayload',
  UpdatePositionError: 'UpdatePositionError',
  UpdatePositionFormInput: 'UpdatePositionFormInput',
  UpdatePositionInput: 'UpdatePositionInput',
  UpdatePositionPayload: 'UpdatePositionPayload',
  UpdatePowerGridError: 'UpdatePowerGridError',
  UpdatePowerGridInput: 'UpdatePowerGridInput',
  UpdatePowerGridPayload: 'UpdatePowerGridPayload',
  UpdateResponsibilityError: 'UpdateResponsibilityError',
  UpdateResponsibilityInput: 'UpdateResponsibilityInput',
  UpdateResponsibilityPayload: 'UpdateResponsibilityPayload',
  UpdateResponsibilityTaskError: 'UpdateResponsibilityTaskError',
  UpdateResponsibilityTaskInput: 'UpdateResponsibilityTaskInput',
  UpdateResponsibilityTaskPayload: 'UpdateResponsibilityTaskPayload',
  UpdateTeamError: 'UpdateTeamError',
  UpdateTeamFormInput: 'UpdateTeamFormInput',
  UpdateTeamInput: 'UpdateTeamInput',
  UpdateTeamPayload: 'UpdateTeamPayload',
  UpdateTodoInput: 'UpdateTodoInput',
  UpdateTodoPayload: 'UpdateTodoPayload',
  Upload: 'Upload',
  UuidOperationFilterInput: 'UuidOperationFilterInput',
  VerticalAlignType: 'VerticalAlignType',
  VerticalAlignTypeOperationFilterInput: 'VerticalAlignTypeOperationFilterInput',
  WorkflowTemplateFilterInput: 'WorkflowTemplateFilterInput',
  WorkflowTemplateItemFilterInput: 'WorkflowTemplateItemFilterInput',
  ZipCodes: 'ZipCodes',
  ZipCodesCollectionSegment: 'ZipCodesCollectionSegment',
  ZipCodesFilterInput: 'ZipCodesFilterInput',
  ZipCodesSortInput: 'ZipCodesSortInput'
} as const